<div class="menu-container">
    <div class="menu-toggle" (click)="toggleMenu()">
        <span class="field2-section d-block mb-2">Informations personnelles</span>
        <span class="arrow" [class.up]="isMenuOpen">&#9660;</span>
    </div>
    <ul class="aboveList" [ngClass]="{'d-none':isMenuOpen}">
        <li>
            <label>Nom :</label>
            <p>{{firstname + ' ' + surname}}</p>
        </li>
    </ul>
    <ul class="menu" [class.active]="isMenuOpen">
        <li>
            <label>Nom :</label>
            <p>{{firstname + ' ' + surname}}</p>
        </li>
        <li>
            <label>Date de naissance :</label>
            <p>{{birthDate | date:'dd MMM y'}}</p>
        </li>
        <li>
            <label>Genre :</label>
            <p>{{genre | lowercase}}</p>
        </li>
        <li>
            <label>Numéro national :</label>
            <p>{{nationalNumber}}</p>
        </li>
        <li>
            <label>Tel. :</label>
            <p>{{phoneNumber}}</p>
        </li>
        <li>
            <label>Mail :</label>
            <p>{{email}}</p>
        </li>
        <li>
            <label>Adresse :</label>
            <p>{{adress}}</p>
        </li>
    </ul>
</div>
