import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nationalNumber'
})
export class NationalNumberPipe implements PipeTransform {

  //   04111522112
  // ->04.11.15-221.12
  transform(value: string): string {
    if (!value) {
      return value;
    }

    // Supprimer les espaces éventuels
    value = value.replace(/[.\-\s]/g, '');

    // Extraction des composants du numéro national
    const birthDatePart = value.substring(0, 6);
    const yearPart = birthDatePart.substring(0, 2);
    const monthPart = birthDatePart.substring(2, 4);
    const dayPart = birthDatePart.substring(4, 6);
    const serialNumber = value.substring(6, 9);
    const controlNumber = value.substring(9, 11);

    // Assemblage du numéro formaté
    let formattedValue = '';

    if (value.length >= 2) {
      formattedValue += `${yearPart}.`;
    }

    if (value.length >= 4) {
      formattedValue += `${monthPart}.`;
    }

    if (value.length >= 6) {
      formattedValue += `${dayPart}-`;
    }

    if (value.length >= 9) {
      formattedValue += `${serialNumber}.${controlNumber}`;
    }

    return formattedValue;
  }


}
