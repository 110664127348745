<mat-card [formGroup]="mainForm" class="main-form">
  <mat-card-title>Connexion</mat-card-title>
  <p *ngIf="resetPassword" class="text-center ds-message-error">
    Votre nouveau mot de passe a été envoyé par mail. Consultez votre messagerie pour le récupérer.
  </p>
  <mat-card class="form-card" [formGroup]="loginForm">
    <mat-card-subtitle>Email*</mat-card-subtitle>
      <mat-form-field>
        <mat-label><i class="fa-regular fa-envelope"></i></mat-label>
        <input type="email" matInput formControlName="email" autocomplete="off">
      </mat-form-field>
  </mat-card>
  <div *ngIf="errorMessage === 'Utilisateur non trouvé'" class="error-message">
    {{ errorMessage }}
  </div>
  <mat-card class="form-card" [formGroup]="loginForm">
    <mat-card-subtitle>Mot de passe*</mat-card-subtitle>
    <mat-form-field>
      <mat-label><i class="fa-solid fa-lock"></i></mat-label>
      <input type="password" matInput formControlName="password" autocomplete="off">
    </mat-form-field>
  </mat-card>
  <div *ngIf="errorMessage === 'Mot de passe incorrect'" class="error-message">
    {{ errorMessage }}
  </div>
  <div class="d-flex justify-content-center align-items-center flex-column">
    <a [routerLink]="'/reset-password'" class="custom-link" >Mot de passe oublié ?</a>
    <mat-card-actions>
      <button mat-flat-button (click)="onElemClick.emit(mainForm.value)">Se Connecter</button>
    </mat-card-actions>
    <p>Vous n'avez pas de compte DS ?</p>
    <a class="custom-link" [routerLink]="''" (click)="goToInscription()" >Créer un compte DS</a>
  </div>
</mat-card>
