<section class="shadow-sm p-2 pb-4 mb-3 bg-white rounded mx-3">
  <span class="field2-section d-block mb-3">Lieux disponibles</span>
  <div class="text-center">
    <!-- MESSAGE DE STATUS LIEUX-->
    <span class="ds-message" [ngClass]="status.classes">
        <div *ngIf="status.isLoading" class="spinner-border spinner-border-sm" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      {{status.message}}</span>
    <!--      <span *ngIf="placeLoading" class="d-block text-center" style="font-size: 1em"><span>Chargement...</span></span>-->
    <!--      <span *ngIf="Places.size === 0 && !placeLoading" class="d-block text-center" style="font-size: 1em"><span>Aucune semaine séléctionnée</span></span>-->

    <app-multi-select
      [Id]="initData.id"
      [Visibility]="status.isValid && values.size!==0"
      [Values]="Array.from(values.keys())"
      [Enables]="Array.from(values.values())"
      (change)="onMultiselectClick.emit($event.target)">
    </app-multi-select>
  </div>
</section>
