import {Week} from "../../../models/Week";
import {StagesComponent} from "./stages.component";

// import variables globaux par défaut
import * as G from './StagesGlobalValues';
import {AgeRange} from "../../common/age-range/age-range";
import {StagesModel} from "./StagesModel";

export class StagesFiltersLocalStorage {
  // Variables de Storage
  static LOCAL_PERIODES:string = 'stages.periodes';
  static LOCAL_PLACES:string = 'stages.places';
  static LOCAL_WEEKS:string = 'stages.weeks';
  static LOCAL_AGE_RANGE:string = 'stages.age-range';
  static LOCAL_AUTO_FILTER:string = 'stages.auto-filter';

  static loadFromLocal(): [Map<string, boolean>|null, {min: number, max: number}|null, Map<Week, boolean>|null, Map<string, boolean>|null, boolean|null] {
    // get from local
    let SelectedPeriodes = localStorage.getItem(this.LOCAL_PERIODES);
    let SelectedAgeRange = localStorage.getItem(this.LOCAL_AGE_RANGE);
    let SelectedWeeks = localStorage.getItem(this.LOCAL_WEEKS);
    let SelectedPlaces = localStorage.getItem(this.LOCAL_PLACES);
    let SelectedAutoFilter = localStorage.getItem(this.LOCAL_AUTO_FILTER);

    // condition NULL + CAST
    let periodes = SelectedPeriodes!==null?new Map<string, boolean>(JSON.parse(SelectedPeriodes)):null;
    let age = SelectedAgeRange!==null?JSON.parse(SelectedAgeRange):null;
    let weeks = SelectedWeeks!==null?new Map<Week, boolean>(JSON.parse(SelectedWeeks)):null;
    let places = SelectedPlaces?new Map<string, boolean>(JSON.parse(SelectedPlaces)):null;
    let autoFilter = SelectedAutoFilter!==null?JSON.parse(SelectedAutoFilter):null;

    return [periodes, age, weeks, places, autoFilter];
  }

  static saveToLocal({periodes, ageRange, weeks, places, autoFilter}: {periodes?: Map<string, boolean>, ageRange?: any, weeks?: Map<Week, boolean>, places?: Map<string, boolean>, autoFilter?: boolean}) {
    // la sauvegarde est effectué si les arguments ne sont pas nuls
    if(periodes)                this.savePeriodesToLocal(periodes);
    if(ageRange)                this.saveAgeToLocal(ageRange);
    if(weeks)                   this.saveWeeksToLocal(weeks);
    if(places)                  this.savePlacesToLocal(places);
    if(autoFilter!==undefined)  this.saveAutoFilterToLocal(autoFilter);
  }

  private static savePeriodesToLocal(periodes: Map<string, boolean>) {
    localStorage.setItem(this.LOCAL_PERIODES, JSON.stringify(Array.from(periodes.entries())));
  }

  private static saveWeeksToLocal(weeks: Map<Week, boolean>) {
    localStorage.setItem(this.LOCAL_WEEKS, JSON.stringify(Array.from(weeks.entries())));
  }

  private static saveAgeToLocal(ageRange: {min: number, max: number}) {
    localStorage.setItem(this.LOCAL_AGE_RANGE, JSON.stringify(ageRange));
  }

  private static savePlacesToLocal(places: Map<string, boolean>) {
    // localStorage.setItem(this.LOCAL_WEEKPLACES, JSON.stringify(Array.from(weekPlaces.entries())));
    localStorage.setItem(this.LOCAL_PLACES, JSON.stringify(Array.from(places.entries())));
  }
  private static saveAutoFilterToLocal(autoFilter: boolean) {
    localStorage.setItem(this.LOCAL_AUTO_FILTER, JSON.stringify(autoFilter));
  }

  // METHODE D'EXTENTION
  /**
   * Charge les filtres précédents enregistrés en local, si non une valeur par défaut est définit. Les données chargées
   * sont affectés aux attributs de cette classe, ce qui a un effet dynamique sur le visuel, là où les attributs sont
   * utilisés dans la vue.
   * @private
   */
  static async loadLocalFilters(component: StagesComponent,Model: StagesModel) {
    let [
      periodes,
      ageRange,
      weeks,
      _,
      autoFilter
    ] = StagesFiltersLocalStorage.loadFromLocal();

    // load age
    Model.ageRange = new AgeRange(ageRange?.min??G.AGE_MIN, ageRange?.max??G.AGE_MAX, G.AGE_MIN, G.AGE_MAX);
    Model.isAutoApplyFilters = autoFilter??false;

    // periodes
    let selectedPeriode = false
    Model.periods.forEach((v, periode) => {
      selectedPeriode = selectedPeriode || periodes?.get(periode) || false;
      Model.periods.set(periode, periodes?.get(periode)??false);
    })
    Model.selectTheOnlyPeriodIfTrue();

    // semaines
    let selectedWeeks = [...Model.periods.keys()].filter(p => Model.periods.get(p))
    await StagesFiltersLocalStorage.loadLocalWeeks(component, weeks, selectedWeeks);
  }

  private static async loadLocalWeeks(component: StagesComponent, weeks: Map<Week, boolean> | null, periods: string[]) {
    await component.loadWeeks(periods)
    let hasSelectedWeeks = false
    component.Model.weeks.forEach((v, week) => {
      // affecte les données de la mémoire locale
      [...weeks?.keys()||[]].some((w) => {
        if (w.name === week.name) component.Model.weeks.set(week, weeks?.get(w)||false)
      })
      hasSelectedWeeks = hasSelectedWeeks || component.Model.weeks.get(week) || false;
    })
  }

  static resetLocal() {
    localStorage.removeItem(this.LOCAL_PERIODES);
    localStorage.removeItem(this.LOCAL_PLACES);
    localStorage.removeItem(this.LOCAL_WEEKS);
    localStorage.removeItem(this.LOCAL_AGE_RANGE);
    localStorage.removeItem(this.LOCAL_AUTO_FILTER);
  }
}
