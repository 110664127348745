export class MemberContact {

  numero_personne: number;
  rue: string;
  numero: string;
  code_postal: string;
  localite: string;
  telephone: string;
  fax: string;
  newsletter: boolean;

  constructor(numero_personne = 0, rue = '', numero = '', code_postal = '', localite = '', telephone = '', fax = '', newsletter = false) {
    this.numero_personne = numero_personne;
    this.rue = rue;
    this.numero = numero;
    this.code_postal = code_postal;
    this.localite = localite;
    this.telephone = telephone;
    this.fax = fax;
    this.newsletter = newsletter;
  }

}
