import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-contact2',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {
  newsletterForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private http: HttpClient) {
    this.newsletterForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  onSubmit() {
    if (this.newsletterForm.valid) {
      // Construct the URL with the form data
      const baseUrl = 'https://ymlp.com/subscribe.php';
      const listId = 'gmwwusugmgj';
      const queryParams = `?id=${listId}`;
      const formData = this.newsletterForm.value;
      const redirectUrl = `${baseUrl}${queryParams}`;

      // Redirect the user to the constructed URL
      window.location.href = redirectUrl;
    }
  }

}
