import {Component, OnInit, SecurityContext} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {MedicalInformation} from "../../../../../models/MedicalInformation";
import {Member} from "../../../../../models/Member";
import {DataService} from "../../../../../service/data.service";
import {RadioElement} from "../../../../../models/RadioElement";
import {Router} from "@angular/router";
import {InscriptionStage} from "../../../../../models/inscriptionStage";
import he from "he";
import {error} from "@angular/compiler-cli/src/transformers/util";
import {FooterService} from "../../../../common/footer/footer.service";


@Component({
  selector: 'app-preference',
  templateUrl: './preference.component.html',
  styleUrls: ['./preference.component.css']
})
export class PreferenceComponent implements OnInit {

  /*
  * Je reçois comme information de la page précédente :
  * - L'ID du stage
  * - L'ID de la personne (à inscrire)
  *
  * J'ai besoin :
  * - des périodes de la journée possible pour le stage
  * - Informations concernant la personne à inscrire
  *     (
  * */

  /* Info qui seront passée concernant la page 1 du formulaire */
  protected connectedAccount = JSON.parse(localStorage.getItem('selectedCompte') || '{}');

  protected selectedMember = JSON.parse(sessionStorage.getItem('selectedMember') || '{}');
  protected inscritID: number = this.selectedMember.numero_personne;

  protected selectedStage = JSON.parse(sessionStorage.getItem('selectedStage') || '{}');
  protected stageID: number = this.selectedStage.stageID;

  protected allInfos : InscriptionStage = JSON.parse(sessionStorage.getItem('inscriptionStage') || '{}');
  protected haveMedicalInfos: boolean = this.allInfos.info_medical == undefined;
  /* Données */
  protected fCopain: string = this.allInfos.copain;
  protected fCommentaire: string = this.allInfos.commentaire_copain;
  protected facturation: string = this.allInfos.moyen_payement != null ? this.allInfos.moyen_payement : "Pas de facture";
  protected commentaire: string = this.allInfos.commentaire_global;

  protected personnalInfos!: Member; // Information personnel (non-modifiable)
  protected haveDayOption: boolean = false;
  protected medicalInfo: MedicalInformation = this.allInfos.info_medical ?? new MedicalInformation('', '', -1, '', '', ''); // Information médicale pouvant être modifiée ici (Comprises dans l'objet Member)

  /* Constantes donnant les possibilités de réponses */
  protected typeStage: RadioElement[] = new Array(0); // Tableau des options présentent dans la sélection 'type stage'
  protected payementTypes: Map<number, string> = new Map();
  protected stageName!: string;


  error: boolean = false;
  showPopUp: boolean = false;
  protected message: Map<string, string> = new Map();

  constructor(private sanitizer: DomSanitizer, private dataService: DataService, private router:Router, private footerService: FooterService) {
    if (this.selectedMember == null || this.selectedStage == null || this.connectedAccount == null) {
      this.router.navigateByUrl("/");
    }
  }

  async ngOnInit(): Promise<void> {
    this.footerService.setFooterVisibility(true);
    this.stageName = he.decode(this.selectedStage.name);
    this.payementTypes = this.getPayementTypes();
    this.personnalInfos = this.getPersonnalInfo();
    this.haveDayOption = true;
    this.typeStage = await this.getDayOptions();
    this.updateChosenDayPeriod( this.allInfos.periode_journee === null || this.allInfos.periode_journee === undefined ? 'JC' : this.allInfos.periode_journee);
    this.typeStage.forEach(value => {
      console.log("preference component ngoninit " + value.value + " - " + this.allInfos.periode_journee)
      if (this.allInfos.periode_journee === value.value) {
        value.isChecked = true;
      }
      // if (this.selectedStage.halfDayPrice == null && (value.value === "AM" || value.value === "PM")) {
      //   value.canBeChecked.false
      // }
    });
    // s'il n'y a qu'une seule période, elle est sélectionnée
    if(this.typeStage.length === 1) {
      const re = this.typeStage[0];
      re.nom = re.nom + " (seule période disponible pour le moment)";
      re.isChecked = true;
    }
  }

  protected togglePopup() {
    if (!this.showPopUp)
    this.showPopUp = !this.showPopUp;
    // console.log('Close popup : ' + this.error);
}

  async getDayOptions(): Promise<RadioElement[]> {
    let periodOfDay: RadioElement[] = [
      new RadioElement("Journée complète", "JC", false, true),
      new RadioElement("Avant-midi", "AM", false, true),
      new RadioElement("Après-midi", "PM", false, true)
    ];
    if (this.selectedStage.halfDayPrice == null) {
      periodOfDay.splice(1, 2);
    }
    // console.log('Before promess');
    return new Promise<RadioElement[]>((resolve, reject) => {
      this.dataService.getPeriodOfDay(this.stageID).subscribe({
        next: (element: any) => {
          if (element) {
            // Define an array of keys for each option
            const optionKeys = ["JC", "AM", "PM"];

            // Iterate through the options and modify the corresponding RadioElement
            for (let i = 0; i < optionKeys.length; i++) {
              const key = optionKeys[i];
              const index = i;

              if (element[key] === null) {
                // Remove the option if it's null
                periodOfDay.splice(index, 1);
              } else if (element[key] === 1) {
                // Modify the option if it's 1
                periodOfDay[index].canBeChecked = false;
              }
            }
            if (periodOfDay.length == 1) {
              periodOfDay[0].isChecked = true;
            }
          }
          resolve(periodOfDay);
        },
        error: (error) => {
          reject(error);
        }
      });
    });
  }

  private getPayementTypes() {
    return new Map<number, string>([ // Si vous ajoutez un élément, n'oubliez pas d'allez modif la taille de .biggerMenu dans app.facturation.component.css
      [1, "BNP PARIBAS FORTIS"],
      [2, "CPAS"],
      [3, "SAJ"],
      [4, "SPJ"],
      [5, "FWB"],
      [6, "TEC"],
      [7, "AUTRE"]
    ]);
  }
  protected getSelectedPayementTypes() {
    let correspondingKey = -1;
    this.payementTypes.forEach((value, key) => {
      if (value === this.facturation) {
        correspondingKey = key;
      }
    });
    // console.log('Preference - getSlectedPayementTypes - SelectedValue : ' + correspondingKey);
    return correspondingKey;
  }


    private getPersonnalInfo() {
      return new Member(
          this.selectedMember.numero_personne,
          this.selectedMember.nom,
          this.selectedMember.prenom,
          this.selectedMember.birthDate,
          this.selectedMember.genre,
          this.selectedMember.phoneNumber,
          this.selectedMember.commentaire,
          this.selectedMember.remarques,
          this.selectedMember.profession_parents,
          this.selectedMember.fonction,
          this.selectedMember.sport_pratique,
          this.selectedMember.ecole,
          this.selectedMember.numero_compte,
          this.selectedMember.numero_national,
          this.selectedMember.email
      )
    }

  /*All Emiter events handlers*/
  updateChosenDayPeriod(key: string) {
    // Update the resultData based on the emitted data
    // console.log('List Call')
    console.log('preference.component.ts - updateChosenDayPeriod - key : ' + key);
    this.allInfos.periode_journee = key;
  }

  updateFacturationOption(eventData: string) {
    // console.log("La data reçue : " + eventData);
    this.facturation = eventData;
  }

  updateMedicalInfos(medicalInfo: MedicalInformation) {
    this.medicalInfo = medicalInfo;
    this.allInfos.info_medical = medicalInfo;
    // console.log("(preference) - updateMedicalInofs(medicalInfo: MedicalInformation) | medicalInfo : " + this.medicalInfo);
    // console.log("(preference) - updateMedicalInofs(medicalInfo: MedicalInformation) | this.allInfos.info_medical : " + this.allInfos.info_medical);
    // console.log(this.allInfos.info_medical);
  }
  private isThereErrors(): boolean {
    let bool1 = this.checkMoyenPayement();
    let bool2 = this.checkPeriodeOfDay();
    let bool3= this.checkNomMedecin();
    let bool4 = this.checkTelMedecin();
    let bool5 = this.checkTetanos();

    return bool1 || bool2 || bool3 || bool4 || bool5;
  }

  private checkPeriodeOfDay() {
    if (this.allInfos.periode_journee != "AM" && this.allInfos.periode_journee != "PM" && this.allInfos.periode_journee != "JC") {
      console.log('(stage.preference) checkPeriodOfDay - error : missing period of Day' + " - " + this.allInfos.periode_journee);

      this.message.set('periodeJournee', "Vous n'avez pas indiqué sur quelle période de la journée vous souhaitez vous inscrire.");
      return true;
    } else {
      this.message.delete('perdiodeJournee');
      return false;
    }
  }

  private checkMoyenPayement() {
    if (this.allInfos.moyen_payement == undefined) {
      console.log('(stage.preference) checkMoyenPayement - error : missing payement method');

      this.message.set('moyenPayement', "Vous n'avez pas indiqué de moyen de facturation.");
      return  true;
    } else {
      this.message.delete('moyenPayement');
      return false
    }
  }

  private checkNomMedecin() {
    // console.log('(stage.preference) checkNomMedecin - nom médecin : ' + this.allInfos.info_medical?.medecin);
    if (this.allInfos.info_medical?.medecin == undefined || this.allInfos.info_medical?.medecin == "") {
      console.log('(stage.preference) checkNomMedecin - error : missing \'Nom du medicin\'');

      this.message.set('nomMedecin', "Vous n'avez pas indiqué le nom de votre médecin.");
      return  true;
    } else {
      this.message.delete('nomMedecin');
      return false
    }
  }
  private checkTelMedecin() {
    // console.log('(stage.preference) checkTelMedecin - gsm : ' + this.allInfos.info_medical?.gsm);
    if (this.allInfos.info_medical?.gsm == undefined || this.allInfos.info_medical?.gsm == "") {
      console.log('(stage.preference) checkTelMedecin - error : missing \'Telephone du medicin\'');

      this.message.set('telMedecin', "Vous n'avez pas indiqué le numéro de téléphone de votre médecin.");
      return  true;
    } else {
      this.message.delete('telMedecin');
      return false
    }
  }

  private checkTetanos() {
    // console.log('(stage.preference) checkTelMedecin - gsm : ' + this.allInfos.info_medical?.gsm);
    let cetteAnnee = new Date().getFullYear();
    if (this.allInfos.info_medical?.annee_tetanos != undefined) {
      if (this.allInfos.info_medical?.annee_tetanos > cetteAnnee) {
        console.log('(stage.preference) checkTelMedecin - error :  \'Annee Tetanos\' trop grande');

        this.message.set('Tetanos', "L'année du rappel Tétanos que vous avez indiquer n'a pas encore eu lieu.");
        return  true;
      } else if (this.allInfos.info_medical?.annee_tetanos < cetteAnnee - 150) {
        console.log('(stage.preference) checkTelMedecin - error :  \'Annee Tetanos\' trop petite');

        this.message.set('Tetanos', "L'année du rappel Tétanos que vous avez indiquer est trop ancienne.");
        return  true;
      } else {
        this.message.delete('Tetanos');
        return false
      }
    } else {
      this.message.delete('Tetanos');
      return false
    }
  }

  handleForm() {
    /* Récupération du moment de la journée sélectionné */
    // Pas besoin ici car fait en direct
    /* Récupération des infos médicals */
    this.updateMedicalInfos(this.medicalInfo);
    /* Récupération de l'ami */
    this.allInfos.copain = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(this.fCopain)) ?? '';
    /* Récupération du commentaire pour l'ami*/
    this.allInfos.commentaire_copain = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(this.fCommentaire)) ?? '';
    /* Récupération du commentaire à l'inscription */
    this.allInfos.commentaire_global = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(this.commentaire)) ?? '';
    /* Récupération du moyen de payement */
    this.allInfos.moyen_payement = this.facturation;

    // console.log(this.allInfos);

    let error = this.isThereErrors();
    if (!error) {
      // console.log(this.allInfos);
      sessionStorage.setItem('inscriptionStage', JSON.stringify(this.allInfos));
      this.router.navigateByUrl('inscription.stages.recapitulatif')
    } else {
      this.togglePopup();
    }
    // console.log('(stage.preference) - Handle Form : error : ' + error);
    // console.log('(stage.preference) - Handle Form : ' + this.error);

  }
}
