import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {StagesComponent} from "./components/pages/stages/stages.component";
import {CoursComponent} from "./components/pages/cours/cours.component";
import {NotificationsComponent} from "./components/pages/notifications/notifications/notifications.component";
import {ContactComponent} from "./components/pages/contact/contact.component";
import {HomeComponent} from "./components/pages/home/home.component";
import {StageDetailsComponent} from "./components/pages/stage-details/stage-details.component";
import {CoursDetailsComponent} from "./components/pages/cours-details/cours-details.component";
import {PreferenceComponent} from "./components/pages/inscription/stages/preference/preference.component";
import {
  SelectionMembreStageComponent
} from "./components/pages/inscription/stages/selection-membre-stage/selection-membre-stage.component";
import {RecapitulatifComponent} from "./components/pages/inscription/stages/recapitulatif/recapitulatif.component";
import {
  InscriptionConfirmeeComponent
} from "./components/pages/inscription/stages/inscription-confirmee/inscription-confirmee.component";
import {RechercheMembreComponent} from "./components/pages/membre/recherche-membre/recherche-membre.component";
import {ConnexionComponent} from "./components/pages/connexion/connexion.component";
import {CreeMembreComponent} from "./components/pages/membre/cree-membre/cree-membre.component";
import {
  ConfirmationMembreComponent
} from "./components/pages/membre/confirmation-membre/confirmation-membre.component";
import {GestionCompteComponent} from "./components/pages/gestion-compte/gestion-compte.component";
import {ModificationCompteComponent} from "./components/pages/modification-compte/modification-compte.component";
import {
  SelectionMembreCoursComponent
} from "./components/pages/inscription/cours/selection-membre-cours/selection-membre-cours.component";
import {
  PreferenceCoursComponent
} from "./components/pages/inscription/cours/preference-cours/preference-cours.component";
import {
  RecapitulatifCoursComponent
} from "./components/pages/inscription/cours/recapitulatif-cours/recapitulatif-cours.component";
import {
  InscriptionConfirmeeCoursComponent
} from "./components/pages/inscription/cours/inscription-confirmee-cours/inscription-confirmee-cours.component";
import {ModificationMembreComponent} from "./components/pages/membre/modification-membre/modification-membre.component";
import {CreationCompteComponent} from "./components/pages/creation-compte/creation-compte.component";
import {ResetPasswordComponent} from "./components/pages/reset-password/reset-password.component";
import {AttestationTelechargementComponent} from "./components/pages/attestation-telechargement/attestation-telechargement.component";
import {GestionMembreComponent} from "./components/pages/membre/gestion-membre/gestion-membre.component";
import {ActivationCompteComponent} from "./components/pages/activation-compte/activation-compte.component";
import {AuthGuardService} from "./service/auth-guard.service";



const routes: Routes = [
    // Pages hors connexion
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'stages', component: StagesComponent },
  { path: 'stages/:id', component: StageDetailsComponent },
  { path: 'courses/:id', component: CoursDetailsComponent },
  { path: 'cours', component: CoursComponent },
  { path: 'connexion', component: ConnexionComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'contact2', component: ContactComponent },
  { path: 'creation-compte', component: CreationCompteComponent },
  // Pages réservées aux utilisateurs connectés
  { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuardService]},
  { path: 'recherche-membre', component: RechercheMembreComponent, canActivate: [AuthGuardService]},
  { path: 'confirmation-membre', component: ConfirmationMembreComponent, canActivate: [AuthGuardService]},
  { path: 'inscription.cree-membre', component: CreeMembreComponent, canActivate: [AuthGuardService]},
  { path: 'inscription.stages.selection-membre-stage', component: SelectionMembreStageComponent, canActivate: [AuthGuardService]},
  { path: 'inscription.stages.preference', component: PreferenceComponent, canActivate: [AuthGuardService]},
  { path: 'inscription.stages.recapitulatif', component: RecapitulatifComponent, canActivate: [AuthGuardService]},
  { path: 'inscription.stages.inscription-confirmee', component: InscriptionConfirmeeComponent, canActivate: [AuthGuardService]},
  { path: 'inscription.cours.selection-membre-cours', component: SelectionMembreCoursComponent, canActivate: [AuthGuardService]},
  { path: 'inscription.cours.preference', component: PreferenceCoursComponent, canActivate: [AuthGuardService]},
  { path: 'inscription.cours.recapitulatif', component: RecapitulatifCoursComponent, canActivate: [AuthGuardService]},
  { path: 'inscription.cours.inscription-confirmee', component: InscriptionConfirmeeCoursComponent, canActivate: [AuthGuardService]},
  { path: 'gestion-compte', component: GestionCompteComponent, canActivate: [AuthGuardService]},
  { path: 'modification-compte', component: ModificationCompteComponent, canActivate: [AuthGuardService]},
  { path: 'modification-membre', component: ModificationMembreComponent, canActivate: [AuthGuardService]},
  { path: 'creation-membre', component: CreeMembreComponent, canActivate: [AuthGuardService]},
  { path: 'reset-password', component: ResetPasswordComponent, canActivate: [AuthGuardService]},
  { path: 'gestion-membre', component: GestionMembreComponent, canActivate: [AuthGuardService]},
  { path: 'activation-compte', component: ActivationCompteComponent},
  { path: 'attestation-telechargement', component: AttestationTelechargementComponent, canActivate: [AuthGuardService]},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes/*,  { useHash: true }*/),
    // CommonModule,
    // BrowserModule
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule { }
