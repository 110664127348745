import {getStatusMessage} from "../../common/methods/statusMessages";
import {FormLoadingStatus, LoadingStatus} from "../../../models/FormLoadingStatus";
import {CoursModel} from "./CoursModel";
import {CoursLoadingStatus} from "./CoursLoadingStatus";
import {ICoursStatusProvider} from "./interfaces";

const {LOADING, LOADED, TIME_OUT, NET_ERROR, ERROR} = LoadingStatus;

export class CoursStatusProvider implements ICoursStatusProvider {
  model: CoursModel;
  loadingStatus: CoursLoadingStatus;

  constructor({model, loadingStatus}: {model: CoursModel, loadingStatus: CoursLoadingStatus}) {
    this.model = model;
    this.loadingStatus = loadingStatus;
  }

  public get places(): FormLoadingStatus {
    // BOOLEAN STATUS
    let noSelectedWeeks = this.model.selectedType === '';
    let isLoading = this.loadingStatus.places === LOADING;
    let isTimeOut = this.loadingStatus.places === TIME_OUT;
    let isNetError = this.loadingStatus.places === NET_ERROR;
    let isRequestError = this.loadingStatus.places === ERROR;
    let isEmptyList = this.loadingStatus.places === LOADED && this.model.places.size == 0;

    // RETURN VARIABLES
    let message = '';
    let classes: string[] = []; // c'est un moyen d'ajouter des styles css supplémentaires

    // CONDITIONS
    if (noSelectedWeeks && !isLoading) {
      message = 'Aucun type de cours séléctionné';
    } else {
      message = getStatusMessage(isLoading, message, isTimeOut, classes, isNetError, isRequestError, isEmptyList);
    }

    return new FormLoadingStatus(message, classes, message === '', isLoading);
  }

  public get cours(): FormLoadingStatus {
    // BOOLEAN STATUS
    let noSelectedFilters = !this.model.isValidFilter();
    let isLoading = this.loadingStatus.courses ===LOADING;
    let isTimeOut = this.loadingStatus.courses===TIME_OUT;
    let isNetError = this.loadingStatus.courses===NET_ERROR;
    let isRequestError = this.loadingStatus.courses===ERROR;
    let hasNotSearched = !this.model.stagesCalledByButton && !this.model.isAutoApplyFilters;
    let isEmptyList = this.loadingStatus.courses===LOADED && this.model.filteredCourses.length==0;
    // RETURN VARIABLES
    let message = '';
    let classes: string[] = []; // c'est un moyen d'ajouter des styles css supplémentaires

    // CONDITIONS
    if (noSelectedFilters && !isLoading) {
      message = 'Veuillez compléter les filtres';
    } else {
      message = getStatusMessage(isLoading, message, isTimeOut, classes, isNetError, isRequestError, isEmptyList, hasNotSearched);
    }
    return new FormLoadingStatus(message, classes, message === '', isLoading);
  }

  public get piscineDays(): boolean {
    return this.model.courseTypes.get("Piscine")??false;
  }

}
