import {Component, Input} from '@angular/core';
import {SelectionMember} from "../../../models/SelectionMember";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Member} from "../../../models/Member";

@Component({
  selector: 'app-member-edit-form',
  templateUrl: './member-edit-form.component.html',
  styleUrls: ['./member-edit-form.component.css']
})
export class MemberEditFormComponent {

  @Input() member!: Member;

  isMenuOpen: boolean = true;
  mainForm!: FormGroup;


  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit(){
    this.mainForm = this.formBuilder.group({
      nom: [(this.member==null? "" : this.member.nom), Validators.required], // Champ de formulaire Nom
      prenom: [(this.member==null? "" : this.member.prenom), Validators.required], // Champ de formulaire Prénom
      dateDeNaissance: [(this.member==null? "" : this.member.date_naissance), Validators.required], // Champ de formulaire Date de naissance
      gsm: [(this.member==null? "" : this.member.gsm), Validators.required], // Champ de formulaire GSM
      commentaires: [(this.member==null? "" : this.member.commentaires)], // Champ de formulaire Commentaire
      remarques: [(this.member==null? "" : this.member.remarques)], // Champ de formulaire Remarque
      professionParents: [(this.member==null? "" : this.member.profession_parents), Validators.required], // Champ de formulaire Profession parents
      fonction: [(this.member==null? "" : this.member.fonction), Validators.required], // Champ de formulaire Fonction
      sportPratique: [(this.member==null? "" : this.member.sport_pratique), Validators.required], // Champ de formulaire Sport pratique
      ecole: [(this.member==null? "" : this.member.ecole), Validators.required], // Champ de formulaire Ecole
      numeroNational: [(this.member==null? "" : this.member.numero_national), Validators.required], // Champ de formulaire Numéro national
      email: [(this.member==null? "" : this.member.email), Validators.email] // Champ de formulaire Email
    });
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }


}
