<nav aria-label="Page navigation example">
  <ul class="pagination flex-row justify-content-center flex-wrap">
    <li class="page-item">
      <button class="page-link" aria-label="Previous" (click)="onClick.emit(paginator.cursor-1)">
        <span aria-hidden="true">&laquo;</span>
        <span class="sr-only">Previous</span>
      </button>
    </li>
    <li class="page-item" *ngFor="let _ of Array.from({length: paginator.maxPages}); index as i" [ngClass]="{active: paginator.cursor===i}">
      <button class="page-link" (click)="onClick.emit(i)">{{i+1}}</button></li>
    <li class="page-item">
      <button class="page-link" aria-label="Next" (click)="onClick.emit(paginator.cursor+1)">
        <span aria-hidden="true">&raquo;</span>
        <span class="sr-only">Next</span>
      </button>
    </li>
  </ul>
</nav>
