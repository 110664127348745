import { Component } from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {
  protected isNaN = isNaN;

  image: string = '../../../../assets/images/logo_dimensionSport.png';
  protected connectedAccount = JSON.parse(localStorage.getItem('selectedCompte') || '{}') ?? undefined;
  private currentURL: string = "";

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.changeLogoToBlack(event.url);
        this.currentURL = event.url;
        this.connectedAccount = JSON.parse(localStorage.getItem('selectedCompte') || '{}') ?? undefined;
        // console.log('id : ' + this.connectedAccount.numero_compte);
      }
    });
  }

  ngOnInit() {
    // console.log('id : ' + this.connectedAccount.numero_compte);
    // this.connectedAccount = JSON.parse(localStorage.getItem('selectedCompte') || '{}');
  }

  changeLogoToBlack(url: string) {
    if (this.isMainRoot(url)) {
        this.image = '../../../../assets/images/logo_dimensionSport_Black.png';
    } else {
        this.image = '../../../../assets/images/logo_dimensionSport.png';
    }
  }

  protected isMainRoot(url?: string) {
    let currentURL = url ?? this.currentURL;
    return currentURL === '/' || currentURL === '/home';
  }

  goToConnection() {
    this.destroyInscriptionObjects();
    this.router.navigateByUrl('/connexion')
  }

  goToInscription() {
    this.router.navigateByUrl('/creation-compte')
  }

  goToHome() {
    this.destroyInscriptionObjects();
    this.router.navigateByUrl('/')
  }

  goToAcount() {
    this.destroyInscriptionObjects();
    this.router.navigateByUrl('gestion-compte');
  }

  disconnect() {
    this.destroyInscriptionObjects();
    // TODO Make a confirmation pop-up
    localStorage.removeItem('selectedCompte');
    // La déconnexion détruit la session
    sessionStorage.clear();
    this.connectedAccount = null;
    this.router.navigateByUrl('/');
    // location.reload(); // TODO faut probablement amélirorer ça x) C'est pas très propre
  }

  async reloadAndNavigate() {
    // Create a Promise that resolves when the page is reloaded
    const reloadPromise = new Promise<void>((resolve) => {
      window.location.reload();
      resolve();
    });

    // Wait for the reload to complete before navigating
    await reloadPromise;

    // Now you can navigate to your desired route
    this.router.navigate(['/your-route']);
  }
  destroyInscriptionObjects() {
    sessionStorage.removeItem('selectedStage');
    sessionStorage.removeItem('selectedMember');
    sessionStorage.removeItem('inscriptionStage');
  }

}
