import {Component, OnInit} from '@angular/core';
import {DataService} from "../../../../../service/data.service";
import {Member} from "../../../../../models/Member";
import {SelectionMember} from "../../../../../models/SelectionMember";
import {ActivatedRoute, Router} from '@angular/router';
import he from "he";
import {FooterService} from "../../../../common/footer/footer.service";

@Component({
  selector: 'app-selection-membre-stage',
  templateUrl: './selection-membre-stage.component.html',
  styleUrls: ['./selection-membre-stage.component.css', '../../../../common/styles/common.css']
})

export class SelectionMembreStageComponent implements OnInit {

  constructor(private dataService:DataService, private router:Router, private route: ActivatedRoute, private footerService: FooterService) {
    if (this.stage == null) {
      this.router.navigateByUrl("/");
    }
  }

  protected numero_compte:number = JSON.parse(localStorage.getItem('selectedCompte') || '{}').numero_compte;
  protected stage = JSON.parse(sessionStorage.getItem('selectedStage') || '{}');
  protected stageName! :string;
  protected age = {min:this.stage.ageStart, max:this.stage.ageEnd};
  protected dateStart: Date = this.stage.dateStart;
  protected dateEnd: Date = this.stage.dateEnd;
  protected participations: any[] = [];
  protected alreadyBusy: { [key: number]: boolean } = {};
  protected selectedMember: SelectionMember = new SelectionMember();
  protected member: Member = new Member();
  protected members: SelectionMember[] = [];
  protected allowNext: boolean = false;
  protected isChargement: boolean = false;


  async ngOnInit() {
    this.footerService.setFooterVisibility(true);
    this.isChargement = true;
    this.stageName = he.decode(this.stage.name);
    await this.getFamilyMembers(); // Récupère les membres de la famille
    await this.verifyAlreadyBusy(); // Vérifie si les membres sont déjà occupés
    this.isChargement = false;
  } // Les membres et le tableau d'occupation sont envoyés au selecteur dans sa déclaration HTML.

  removeSelectedStage(){
    sessionStorage.removeItem('selectedStage');
    // this.router.navigateByUrl('./stages/' + this.stage.id);
  }

  getFamilyMembers() {
    return new Promise<void>((resolve, reject) => {
      this.dataService.getFamilyMembers(this.numero_compte).subscribe({

        next: (res:any) => {
          console.log("res", res)
          for(const element of res) {
              let selectionMember = new SelectionMember(
              element.NUMERO_PERSONNE,
              element.NOM,
              element.PRENOM,
              new Date(element.DATE_NAISSANCE),
            )
            this.members.push(selectionMember);
          }
          resolve();
        },
        error: (error) => {
          reject(error);
        }
      });
    });
  }

  handleSelectedMember(selectedMember: SelectionMember) {
    this.selectedMember = selectedMember;
    this.allowNext = true;
  }

  async handleClick() {
    this.member = await this.getFullMember();
    sessionStorage.setItem('selectedMember', JSON.stringify(this.member));
    this.router.navigate(['/inscription.stages.preference']);
  }

  getFullMember(): Promise<Member> {
    return new Promise<Member>((resolve, reject) => {
      this.dataService.getFullMember(this.selectedMember.numero_personne).subscribe({
        next: (element: any) => {
          let member: Member;
          if (element) {
            member = new Member(
              element.NUMERO_PERSONNE,
              element.NOM,
              element.PRENOM,
              new Date(element.DATE_NAISSANCE),
              element.SEXE,
              element.GSM,
              element.COMMENTAIRES,
              element.REMARQUES,
              element.PROFESSION_PARENTS,
              element.FONCTION,
              element.SPORT_PRATIQUE,
              element.ECOLE,
              element.NUMERO_COMPTE,
              element.NUMERO_NATIONAL,
              element.EMAIL,
              element.INFO_MEDICAL)
          } else {
              member = new Member();
          }
          resolve(member);
        },
        error: (error) => {
          reject(error);
        },
        complete: () => {
          // You can add clean-up logic here if needed
        }
      });
    });
  }


  private async verifyAlreadyBusy() {
    const numeros_personnes: number[] = [];
    for(let member of this.members) {
      numeros_personnes.push(member.numero_personne)
    }

    this.dataService.getAllParticipationsOfTheseMembers(numeros_personnes, this.dateStart, this.dateEnd, 1, "").subscribe({
      next: (res:any) => {
        for (let i = 0; i < numeros_personnes.length; i++) {
          this.alreadyBusy[this.members[i].numero_personne] = res[i];
          // console.log("Person = " + this.members[i].prenom + " " + this.members[i].nom + " with number " + this.members[i].numero_personne + " is busy : " + this.alreadyBusy[this.members[i].numero_personne]);
        }
        console.warn(this.alreadyBusy);
      }, error: (error) => {}
    });
  }
}
