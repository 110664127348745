<section [ngClass]="{'shadow-sm': withBorder}" class="shadow-sm p-2 pb-3 mb-3 bg-white rounded mx-3">
  <span class="field2-section d-block mb-2">{{title}}</span>
  <ul class="list-group list-group-horizontal list-group-flush ds-select-list ds-checkbox-container d-flex flex-column flex-wrap">
    <li *ngFor="let entry of values | keyvalue" class="list-group-item border-0 py-1">
      <input class="form-check-input"
             [name]="title"
             [id]="entry.key"
             [type]="'radio'"
             [checked]="entry.value"
             (change)="onElemClick.emit({key: entry.key, value: !entry.value})"
             [disabled]="cantBeChecked?.get((entry.key)) === false"
      >
      <label [for]="entry.key">{{ entry.key }}</label>
    </li>
  </ul>
</section>
