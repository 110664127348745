<section [ngClass]="{'shadow-sm': withBorder}" class="shadow-sm p-2 pb-3 mb-3 bg-white rounded mx-3">
    <span class="field2-section d-block mb-2">{{title}}</span>
    <ul class="list-group list-group-horizontal list-group-flush ds-select-list ds-checkbox-container d-flex flex-column flex-wrap">
        <li *ngFor="let radio of values" class="list-group-item border-0 py-1">
            <input class="form-check-input"
                   [disabled]="!radio.canBeChecked"
                   [name]="title"
                   [id]="radio.nom"
                   [type]="'radio'"
                   [checked]="radio.isChecked"
                   (change)="emitEvent(radio)">
            <label [for]="radio.nom" [ngClass]="{'disabled': !radio.canBeChecked}">{{ radio.nom }}</label>
        </li>

    </ul>
</section>
