import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {API_ROUTES} from './config';
import {catchError, Observable, switchMap, throwError, timeout} from "rxjs";
import {fromFetch} from "rxjs/fetch";
import {convertDateFormat, Week} from "../models/Week";
import {AgeRange} from "../components/common/age-range/age-range";
import {Paginator} from "../models/Paginator";
import {Account} from "../models/Account";

@Injectable({
    providedIn: 'root'
})
export class DataService {

  readonly HEADERS : HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin':'*',
  });

  constructor(private httpClient: HttpClient) {
  }

  public getData() {
      return this.httpClient.get(API_ROUTES.test)
  }

  /**
   * Recupère les instances de cours en fonction des filtres utilisateur
   * @param typeCours
   * @param joursPiscine
   * @param lieux
   * @param ageRange
   * @param paginator
   * @return [List<Courses>: {}, totalSize]
   *    Cours :
   *    {
   *         "NUMERO_INSTANCE_COURS": 1409,
   *         "NOM": "Education rythmique &amp; spor",
   *         "AGE_MIN": "3.0",
   *         "AGE_MAX": "5.0",
   *         "LOCALITE": "Herve",
   *         "JOUR": "Samedi",
   *         "NUMERO_LIEU": 6
   *     },
   */
  getFilteredCourses(typeCours: string, joursPiscine: string[], lieux: string[], ageRange: number[], paginator: Paginator) {
    const type = typeCours === "Piscine" ? "piscine" : typeCours === "Escalade" ? "escalade" : typeCours === "Cours terrestre" ? "terrestre" : "undefined";
    let queryParams = new HttpParams()
    .append("jours-piscine", JSON.stringify(joursPiscine))
    .append("places", JSON.stringify(lieux))
    .append("age", JSON.stringify(ageRange))
    .append("quantite", paginator.quantite.toString())
    .append("cursor", paginator.cursor.toString())
    .append("type", type);
    return this.fetchThat(API_ROUTES.getInstanceCours, queryParams)
  }

  getCoursDetails(id:number) {
    let queryParams = new HttpParams();
    queryParams  = queryParams.append("id", JSON.stringify(id));
    return this.httpClient.get(API_ROUTES.getCoursDetails, {params:queryParams});
  }

  // select * from COMPTE WHERE LOGIN = '".$_POST['login']."' and MOT_DE_PASSE = '".$password."';
  sendUserLogin(email: string, password: string){
    let queryParams = new HttpParams();
    queryParams = queryParams.append("email", email);
    queryParams = queryParams.append("password", password);
    return this.httpClient.post(API_ROUTES.getStageDetails, {params:queryParams});
  }

  getCourseTypePlaces(type: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("courseType", encodeURIComponent(type));
    return this.httpClient.get(API_ROUTES.getCourseTypePlaces, {params: queryParams});
  }

  getStagesDates(startDate: string, endDate: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("startDate", encodeURIComponent(startDate));
    queryParams = queryParams.append("endDate", encodeURIComponent(endDate));
    return this.fetchThat(API_ROUTES.getStageDates, queryParams)
  }

  /**
   * Ret
   * @param weeks
   */
  getStagePlaces(weeks: Week[]) {
      let queryParams = new HttpParams();
      let objects = weeks.map((week) => [week.name, convertDateFormat(week.startDate), convertDateFormat(week.endDate)])
      queryParams = queryParams.append('semaines', JSON.stringify(objects));
      return this.fetchThat(API_ROUTES.getStagePlacesForSelectedWeek, queryParams)
  }

  /**
   * Recupère les instances de stages en fonction des filtres utilisateur
   * @param semaines
   * @param lieux
   * @param ageRange
   * @param paginator
   * @return [List<Stages>: {}, totalSize]
   *    Stage :
   *         {
   *             "NUMERO_INSTANCE_STAGES": 5494,
   *             "NOM": "Wally Top chef",
   *             "AGE_MIN": "3.0",
   *             "AGE_MAX": "8.0",
   *             "NUMERO_LIEU": 6,
   *             "LOCALITE": "Herve",
   *             "DATE_DEBUT": "2024-01-02",
   *             "DATE_FIN": "2024-01-05"
   *         },
   */
  getFilteredStages(semaines: string[][], lieux: string[], ageRange: AgeRange, paginator: Paginator) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("semaines", JSON.stringify(semaines));
    queryParams = queryParams.append("lieux", JSON.stringify(lieux));
    queryParams = queryParams.append("age", JSON.stringify(ageRange.toArray()));
    queryParams = queryParams.append("quantite", paginator.quantite.toString());
    queryParams = queryParams.append("cursor", paginator.cursor.toString());
    return this.fetchThat(API_ROUTES.getInstanceStages, queryParams)
  }

  getStageDetails(id:number) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("id", JSON.stringify(id));
    return this.httpClient.get(API_ROUTES.getStageDetails, {params:queryParams});
  }

  getFamilyMembers(numero_compte: number) {
      let queryParams = new HttpParams();
      queryParams = queryParams.append("numero_compte", JSON.stringify(numero_compte));
      return this.httpClient.get(API_ROUTES.getFamilyMembers, {params: queryParams});
  }

  getMedicalInformations(inscritID: number) {
      let queryParams = new HttpParams();
      queryParams = queryParams.append("inscritID", JSON.stringify(inscritID));
      return this.httpClient.get(API_ROUTES.getMedicalInformations, {params: queryParams});
  }

  getPeriodOfDay(instanceStageID: number) {
      let queryParams = new HttpParams();
      queryParams = queryParams.append("instanceStageID", JSON.stringify(instanceStageID));
      return this.httpClient.get(API_ROUTES.getPeriodOfDay, {params: queryParams});
  }
  getFullMember(numero_personne: number) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("ID", JSON.stringify(numero_personne));
    return this.httpClient.get(API_ROUTES.getFullPersonne, {params:queryParams});
  }

  getAllParticipationsStage(numero_personne: number, dateStart: Date, dateEnd: Date) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("ID", JSON.stringify(numero_personne));
    queryParams = queryParams.append("dateStart", JSON.stringify(dateStart));
    queryParams = queryParams.append("dateEnd", JSON.stringify(dateEnd));
    return this.httpClient.get(API_ROUTES.getParticipationsStage, {params:queryParams});
  }

  getAllParticipationsCours(numero_personne: number, dateStart: Date, dateEnd: Date) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("ID", JSON.stringify(numero_personne));
    queryParams = queryParams.append("dateStart", JSON.stringify(dateStart));
    queryParams = queryParams.append("dateEnd", JSON.stringify(dateEnd));
    return this.httpClient.get(API_ROUTES.getParticipationsCours, {params:queryParams});
  }

  /**
   * Recupère les participations des membres de la famille sous forme de tableau de boléens : true = occupé, false = non occupé
   * @param numeros_personnes ids des membres de la famille
   * @param dateStart date de début de stage/cours
   * @param dateEnd date de fin de stage/cours
   * @param coursOrStage type de participation : 0 = cours, 1 = stage
   * @param jourCours jour du cours
   */
  getAllParticipationsOfTheseMembers(numeros_personnes: number[], dateStart: Date, dateEnd: Date, coursOrStage: number, jourCours: String) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("IDs", JSON.stringify(numeros_personnes));
    queryParams = queryParams.append("dateStart", JSON.stringify(dateStart));
    queryParams = queryParams.append("dateEnd", JSON.stringify(dateEnd));
    queryParams = queryParams.append("coursOrStage", coursOrStage);
    queryParams = queryParams.append("jourCours", JSON.stringify(jourCours));
    return this.httpClient.get(API_ROUTES.getParticipationsOfTheseMembers, {params:queryParams});
  }

  getMember(nom: string, prenom: string, dateNaissance: Date) {
      let queryParams = new HttpParams();
      queryParams = queryParams.append("nom", JSON.stringify(nom));
      queryParams = queryParams.append("prenom", JSON.stringify(prenom));
      queryParams = queryParams.append("dateNaissance", JSON.stringify(dateNaissance));
      return this.httpClient.get(API_ROUTES.getMember, {params:queryParams});
  }

  getPersonneContact(numero_personne: number) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("numeroPersonne", JSON.stringify(numero_personne));
    return this.httpClient.get(API_ROUTES.getPersonneContact, {params:queryParams});
  }

  linkUserToAccount(numero_personne:number, numero_compte:number){
    let queryParams = new HttpParams();
    queryParams = queryParams.append("numero_personne", JSON.stringify(numero_personne));
    queryParams = queryParams.append("numero_compte", JSON.stringify(numero_compte));
    return this.httpClient.get(API_ROUTES.linkUserToAccount, {params:queryParams});
  }


  private fetchThat(url: string, queryParams: HttpParams) {
    let retryCount = 3;

    let request: Observable<any> = fromFetch(url + '?' + queryParams.toString()).pipe(
      timeout(10000),
      switchMap(response => {
        return response.json();
      }),
      catchError(error => {
        if (--retryCount >= 0) {
          return request;
        } else {
          throw error;
        }
      }),
    )
        return request;
    }


  addInscriptionStage(isReservation: boolean, idStage: number, idPersonne: number, periodOfDay: string, montantReduction: number | undefined, tailleTshirt: string | undefined, copains: string, commentaires: string, employeur: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("isReserve", JSON.stringify(isReservation));
    queryParams = queryParams.append("idStage", JSON.stringify(idStage));
    queryParams = queryParams.append("idPersonne", JSON.stringify(idPersonne));
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    // console.log("(data.service.ts) addInscriptionStage - formattedDate : " + formattedDate);
    // console.log("(data.service.ts) addInscriptionStage - formattedDate : " + formattedDate);
    queryParams = queryParams.append("ajourdhui", JSON.stringify(formattedDate));

    if (tailleTshirt == undefined) {
      queryParams = queryParams.append("tailleTshirt", JSON.stringify(null));
    }else {
      queryParams = queryParams.append("tailleTshirt", JSON.stringify(tailleTshirt));
    }

    queryParams = queryParams.append("periodOfDay", JSON.stringify(periodOfDay));
    queryParams = queryParams.append("montantReduction", JSON.stringify(montantReduction));
    queryParams = queryParams.append("copains", JSON.stringify(copains));
    queryParams = queryParams.append("commentaires", JSON.stringify(commentaires));
    queryParams = queryParams.append("employeur", JSON.stringify(employeur));
    return this.httpClient.get(API_ROUTES.addInscriptionStage, {params:queryParams});
  }
  addInscriptionCours(isReservation: boolean, idCours: number, idPersonne: number, periodOfYear: string, prix: number, montantReduction: number | undefined, copains: string, commentaires: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("isReserve", JSON.stringify(isReservation));
    queryParams = queryParams.append("idCours", JSON.stringify(idCours));
    queryParams = queryParams.append("idPersonne", JSON.stringify(idPersonne));
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    // console.log("(data.service.ts) addInscriptionStage - formattedDate : " + formattedDate);
    // console.log("(data.service.ts) addInscriptionStage - formattedDate : " + formattedDate);
    queryParams = queryParams.append("ajourdhui", JSON.stringify(formattedDate));
    queryParams = queryParams.append("periodOfYear", JSON.stringify(periodOfYear));
    queryParams = queryParams.append("prix", JSON.stringify(prix));
    queryParams = queryParams.append("reduction", JSON.stringify(montantReduction));
    queryParams = queryParams.append("copains", JSON.stringify(copains));
    queryParams = queryParams.append("commentaires", JSON.stringify(commentaires));
    return this.httpClient.get(API_ROUTES.addInscriptionCours, {params:queryParams});
  }

  updateMedicalInformations(idPersonne: number, medecin: string, gsm: string, annee_tetanos: number, allergies: string, medicaments: string, remarques: string) {
    let queryParams =new HttpParams();
    queryParams = queryParams.append("numero_personne", JSON.stringify(idPersonne));
    queryParams = queryParams.append("medecin", JSON.stringify(medecin));
    queryParams = queryParams.append("gsm", JSON.stringify(gsm));
    queryParams = queryParams.append("annee_tetanos", JSON.stringify(annee_tetanos));
    queryParams = queryParams.append("allergies", JSON.stringify(allergies));
    queryParams = queryParams.append("medicaments", JSON.stringify(medicaments));
    queryParams = queryParams.append("remarques", JSON.stringify(remarques));

    return this.httpClient.get(API_ROUTES.updateMedicalInformation, {params:queryParams});
  }

  PromotionAmountStage(compte: number, typeStage: string, date_debut: Date, date_fin: Date): Observable<any>{
    let queryParams = new HttpParams();
    queryParams = queryParams.append("compte", JSON.stringify(compte));
    queryParams = queryParams.append("typeStage", JSON.stringify(typeStage));
    let year = date_debut.getFullYear();
    let month = String(date_debut.getMonth() + 1).padStart(2, '0');
    let day = String(date_debut.getDate()).padStart(2, '0');
    let formattedDate = `${year}-${month}-${day}`;
    queryParams = queryParams.append("date_debut", JSON.stringify(formattedDate));
    year = date_fin.getFullYear();
    month = String(date_fin.getMonth() + 1).padStart(2, '0');
    day = String(date_fin.getDate()).padStart(2, '0');
    formattedDate = `${year}-${month}-${day}`;
    queryParams = queryParams.append("date_fin", JSON.stringify(formattedDate));

    return this.httpClient.get(API_ROUTES.PromotionAmountStage, {params:queryParams});
  }
  PromotionAmountCours(compte: number, typeCours: string, date_debut: Date, date_fin: Date): Observable<any>{
    let queryParams = new HttpParams();
    queryParams = queryParams.append("compte", JSON.stringify(compte));
    queryParams = queryParams.append("typeCours", JSON.stringify(typeCours));
    let year = date_debut.getFullYear();
    let month = String(date_debut.getMonth() + 1).padStart(2, '0');
    let day = String(date_debut.getDate()).padStart(2, '0');
    let formattedDate = `${year}-${month}-${day}`;
    queryParams = queryParams.append("date_debut", JSON.stringify(formattedDate));
    year = date_fin.getFullYear();
    month = String(date_fin.getMonth() + 1).padStart(2, '0');
    day = String(date_fin.getDate()).padStart(2, '0');
    formattedDate = `${year}-${month}-${day}`;
    queryParams = queryParams.append("date_fin", JSON.stringify(formattedDate));

    return this.httpClient.get(API_ROUTES.PromotionAmountCours, {params:queryParams});
  }

  // sendMail(numero_personne: number, numero_stage: number, allMails: string[], prix: number, formule: string) {
  //   let queryParams = new HttpParams();
  //   queryParams = queryParams.append("numero_personne", numero_personne);
  //   queryParams = queryParams.append("numero_stage", numero_stage);
  //   queryParams = queryParams.append("all_mails", JSON.stringify(allMails.join(',')));
  //   queryParams = queryParams.append("prix", prix);
  //   queryParams = queryParams.append("formule", formule);
  //
  //   return this.httpClient.get(API_ROUTES.sendInscriptionConfirmationMail, {params:queryParams});
  // }

  sendMailStageConfirmation(numero_personne: number, numero_stage: number, allMails: string[], prix: number, formule: string) {
    let body = {
      numero_personne: numero_personne,
      numero_stage: numero_stage,
      all_mails: allMails.join(','),
      prix: prix,
      formule: formule,
    };

    return this.httpClient.post(API_ROUTES.sendInscriptionStageConfirmationMail, body);
  }
  sendMailCoursConfirmation(numero_personne: number, numero_cours: number, allMails: string[], prix: number, formule: string) {
    let body = {
      numero_personne: numero_personne,
      numero_cours: numero_cours,
      all_mails: allMails.join(','),
      prix: prix,
      formule: formule,
    };

    return this.httpClient.post(API_ROUTES.sendInscriptionCoursConfirmationMail, body);
  }

  getPeriodOfYear(instanceCoursID: number) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("instanceCourID", JSON.stringify(instanceCoursID))
    return this.httpClient.get(API_ROUTES.getPeriodOfYear, {params:queryParams});
  }

  updateUserData(userId : any, formData: any){
    // En-têtes pour la requête HTTP
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
    });

    console.log("userId", userId);
    const data = {
      // Information personnelles
      userId : userId,
      login: formData.login?? "",
      password: formData.password?? "",

      // Numéro National
      numNat: formData.numNat?? "",

      // Adresse
      rue: formData.rue?? "",
      numero: formData.numero?? "",
      postCodeControl : formData.postCodeControl,
      pays : formData.pays,

      // Parent 1
      nom1: formData.nom1?? "",
      prenom1: formData.prenom1?? "",
      gsm1: formData.gsm1?? "",
      telFixe: formData.telFixe?? "",

      // Parent 2
      nom2: formData.nom2?? "",
      prenom2: formData.prenom2?? "",
      gsm2: formData.gsm2?? "",
      email2: formData.email2?? "",
    };

    // Envoie les données au backend en tant que requête POST
    console.log(data);
    return this.httpClient.post<any>(API_ROUTES.updateUserData,  data, { headers });
  }

  updateMemberData(numero_personne: number | undefined, numCompte : number | undefined, formData: any) {
    // En-têtes pour la requête HTTP
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
    });

    console.log("memberId", numero_personne);
    console.log("allergies", formData.allergies);
    const body = {
      // Informations personnelles
      numero_personne : numero_personne,
      nom : formData.nom,
      prenom : formData.prenom,
      date_naissance : formData.dateNaissance,
      sexe : formData.genre,
      email : formData.email,
      gsm : formData.gsm,
      sport_pratique : formData.sportPratique,
      commentaires : formData.commentaire,
      numero_national : formData.numNat,
      numero_compte : numCompte,

      // Informations médicales
      nom_medecin : formData.medecinTraitant_nom,
      num_medecin : formData.medecinTraitant_tel,
      annee_tetanos : formData.anneeDernierRappelTetanos,
      allergies : formData.allergies,
      medicaments : formData.medicaments,
      donnees_medicales_specifiques : formData.donnees_medicales_specifiques,

      // Others...
      remarques : formData.remarques,
      profession_parents : formData.professionParents,
      fonction : formData.fonction,
      ecole : formData.ecole,
      info_medicales : formData.info_medicales,
    };
    console.log("date" + body.date_naissance);
    // Envoie les données au backend en tant que requête POST
    return this.httpClient.post<any>(API_ROUTES.updateMemberData, body, { headers });
    // return new Promise((resolve, reject) => {
    //   .subscribe({
    //     next: (data) => {
    //       resolve(data);
    //     },
    //     error: (error) => {
    //       // Si données incorrectes
    //       if (error.status === 400) {
    //
    //       }
    //       console.dir(error)
    //       reject(error.error);
    //     },
    //   });
    // });
  }

  /**
   * Ajoute un membre lorsqu'un parent souhaite l'inscrire à un stage
   * @param member
   * @param numCompte
   * @param loginEmail
   */
  async addMember(member: any, numCompte: number, loginEmail: string) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    member['numCompte'] = numCompte;
    const authUser = {numCompte: numCompte, login: loginEmail};
    const body = JSON.stringify({form: member, authUser: authUser});

    return new Promise((resolve, reject) => {
      this.httpClient.post(API_ROUTES.addMember, body, { headers }).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (error) => {
          // Si données incorrectes
          if (error.status === 400) {

          }
          console.dir(error)
          reject(error.error);
        },
      });
    });
  }


  /**
   * Ajoute un membre lorsqu'un parent souhaite l'inscrire à un stage
   * @param account
   */
  async createAccount(formData: any) {
    console.log("createAccount");
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    if(formData.newsletter == null){
      console.log("newsletter null");
    }
    const body = {
      // Informations personnelles
      login : formData.login,
      password : formData.password,
      cpassword : formData.cpassword,

      // Numero national
      numNat : formData.numNat,

      // Adresse
      rue : formData.rue,
      numero : formData.numero,
      postCodeControl : formData.postCodeControl,
      pays : formData.pays,

      // Parent 1
      nom1 : formData.nom1,
      prenom1 : formData.prenom1,
      gsm1 : formData.gsm1,
      telFixe : formData.telFixe?? "",

      // Parent 2
      nom2 : formData.nom2?? "",
      prenom2 : formData.prenom2?? "",
      gsm2 : formData.gsm2?? "",
      email2 : formData.email2?? "",

      newsletter : formData.newsletter?? false,
    };

    console.log("createAccount body", body.newsletter==true? "true" : "false");

    return new Promise((resolve, reject) => {
      this.httpClient.post(API_ROUTES.createAccount, body, { headers }).subscribe({
        next: (data) => {
          resolve(data);
          console.log("createAccount ok");
        },
        error: (error) => {
          // Si données incorrectes
          if (error.status === 400) {

          }
          console.log("createAccount not ok");
          console.dir(error)
          reject(error.error);
        },
      });
    });
  }

  sendActivationMail(login: string, token: string, baseURL: string) {
    let body = {
      login: login,
      token: token,
      baseURL: baseURL
    };
    return this.httpClient.post(API_ROUTES.sendActivationMail, body);
  }

  sendMailResetPassword(email: string) {
    let body = {email: email};

    return new Promise((resolve, reject) => {
      this.httpClient.post(API_ROUTES.sendResetPasswordMail, body).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (error) => {
          // Si données incorrectes
          // if (error.status === 404) {
          //
          // }
          reject(error);
        },
      });
    });
  }

  getMemberStages(numero_personne: number) {
    console.log("dataservice getMemberStages");
    let queryParams = new HttpParams();
    queryParams = queryParams.append("numero_personne", JSON.stringify(numero_personne));
    return this.httpClient.get(API_ROUTES.getMemberStages, {params: queryParams});
  }
  getMemberCourses(numero_personne: number) {
    console.log("dataservice getMemberCourses");
    let queryParams = new HttpParams();
    queryParams = queryParams.append("numero_personne", JSON.stringify(numero_personne));
    return this.httpClient.get(API_ROUTES.getMemberCourses, {params: queryParams});
  }

  activateAccount(login: string , activation: string ) {
    console.log("dataservice activateAccount");
    // En-têtes pour la requête HTTP
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    let body = {
      login: login,
      activation: activation
    };

    console.log("login", login);
    console.log("activation", activation);
    return this.httpClient.post<any>(API_ROUTES.activateAccount, body, { headers });
  }

  getAllPostCode(): Observable<any> {
    return this.httpClient.get(API_ROUTES.getAllPostCode);
  }

  downloadPdf() {
    return this.httpClient.get(API_ROUTES.getAttestationTEST, {
      responseType: 'blob' as 'json', // Set the response type to blob
    });
  }

  getAttestationFiscale(idMembre: number, idInstanceStage: number, idInscription: number) {
    let body = {
      idMembre: idMembre,
      idInstanceStage: idInstanceStage,
      idInscription: idInscription,
    };

    return this.httpClient.post(API_ROUTES.getAttestationFiscale, body, {
      responseType: 'blob' as 'json' // Set the response type to blob for downloading the PDF
    });
  }

  getAttestationMutuelle(idMembre: number, idInstanceStage: number, idInscription: number) {
    let body = {
      idMembre: idMembre,
      idInstanceStage: idInstanceStage,
      idInscription: idInscription,
    };

    return this.httpClient.post(API_ROUTES.getAttestationMutuelle, body, {
      responseType: 'blob' as 'json' // Set the response type to blob for downloading the PDF
    });
  }

  getAttestationParticipation(idMembre: number, idInstanceStage: number, idInscription: number) {
    let body = {
      idMembre: idMembre,
      idInstanceStage: idInstanceStage,
      idInscription: idInscription,
    };

    return this.httpClient.post(API_ROUTES.getAttestationParticipation, body, {
      responseType: 'blob' as 'json' // Set the response type to blob for downloading the PDF
    });
  }

}
