import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  private footerVisible = new BehaviorSubject<boolean>(true);

  constructor() { }

  setFooterVisibility(visible: boolean) {
    this.footerVisible.next(visible);
  }

  getFooterVisibility() {
    return this.footerVisible.asObservable();
  }
}
