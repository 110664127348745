<main>
  <app-return-link
    [text]="'retour'">
  </app-return-link>

  <div class="p-2" ></div>

  <form *ngIf="resetPasswordForm" novalidate class="m-3 needs-validation" [formGroup]="resetPasswordForm" (ngSubmit)="validateForm()">
    <h1 class="text-center fw-bold mt-3" [innerHTML]="'Réinitialisation du mot de passe'"></h1>
  <!--  <h2 class="text-center fw-bold mt-3">Inscription terminée !</h2>-->

    <div class="py-4">
      <ng-container *ngTemplateOutlet="field; context:
        {name : 'email', label: 'email', placeholder: 'Adresse mail'}" />
    </div>

    <!--BOUTON ENVOYER-->
    <app-floating-button text="réinitialiser" [disabled]="!resetPasswordForm.valid || buttonStatus === 'loading'"/>

    <div class="d-flex justify-content-center align-items-center flex-column">
      <p>Vous n'avez pas de compte DS ?</p>
      <a class="custom-link" [routerLink]="'/creation-compte'" >Créer un compte DS</a>
    </div>

    <ng-template #field let-name="name" let-type="type" let-label="label" let-placeholder="placeholder"><div class="form-group">
      <label [for]="name" class="fw-normal">{{label??name}}</label>

      <input [type]="type" [id]="name" [formName]="name"
             [formControlName]="name" [appFieldError]="resetPasswordForm.get(name)!"
             [placeholder]="placeholder??''" >
      <app-error-message-for-field [control]="resetPasswordForm.get(name)!" [submitted]="submitted"/>
      <!--        <pre>{{registerForm.get(name)!.errors | json}}</pre>-->
    </div></ng-template>
  </form>

</main>


