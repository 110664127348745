import {Component, OnInit, ViewChild} from '@angular/core';
import {WebPushService} from "../../../../service/web-push.service";
import { SwPush, SwUpdate } from '@angular/service-worker';

import { HttpClient } from '@angular/common/http';
import { NgForm } from "@angular/forms";


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],

})
export class NotificationsComponent implements OnInit {
  private subscription!: PushSubscription //TODO: Add type definition for PushSubscription
  isNotificationPermissionGranted: boolean = Notification.permission === 'granted';
  publicKey = "BFpeKd8fWmV2AC9O9aWHpUZKSDtBer8ue8TgfnRBAz3fPCBvkqO4wHJiXWxvkrBgjQkIuojmPaTOdKeFic2ilCM";
  private deferredPrompt : any;

  @ViewChild('notificationForm', { static: false }) notificationForm!: NgForm;
  notification = {
    title: '',
    body: ''
  };

  constructor(private webPushService: WebPushService, private swPush: SwPush, private swUpdate: SwUpdate, private http: HttpClient) { }

  installApp(){
    console.log('install-btn clicked')
    this.deferredPrompt.prompt();
    this.deferredPrompt.userChoice.then((choiceResult : any) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
      this.deferredPrompt = null;
    });
  }


  // Request permission to send notifications
  requestPermission() {
    this.webPushService.requestNotificationPermission().subscribe(permission => {
      if (permission === 'granted') {
        // Permission granted, handle further logic
        console.log("Angular : Permission granted");
      } else if (permission === 'denied') {
        // Permission denied
        console.log("Angular : Permission refused");
      } else if (permission === 'default') {
        // The user closed the permission prompt without making a selection
        console.log("Angular : Permission default");
      }
    });
  }

  // Subscribe to notifications
  subscribe() {
    console.log("Angular : subscribe opened");
    this.webPushService.subscribeToPushNotifications().subscribe(subscription => {
      console.log("Angular subscribe : subscription : ", subscription);
      this.subscription = subscription;
      // Send the subscription details to the server using the Fetch API.
      this.sendSubscription();
    });
    console.log("Angular : subscribe closed");
  }

  // Send the subscription details to the server

  sendSubscription() {
    this.webPushService.sendSubscriptionDetails(this.subscription).subscribe({
      next: (response) => {
        // Server response received, handle further logic
        console.log('Angular : Subscription details sent:', response);
      },
      error: (error) => {
        // Error sending subscription details
        console.error('Angular : Error:', error);
      }
    });
  }

  // Send a push notification

  sendNotification() {
    console.log("Angular : sendNotification opened");
    if(this.notificationForm.valid) {
      this.webPushService.sendWebPushNotification();
      console.log("Angular : sendNotification sent")
    }
    console.log("Angular : sendNotification closed");
  }

  localNotification() {
    console.log("Angular : localNotification opened")
    if (Notification.permission === 'granted' && this.notificationForm.valid) {
      const message = {
        notification: {
          title: this.notification.title,
          body: this.notification.body
        }
      };
      this.handlePushNotification(message);
    }
    console.log("Angular : localNotification closed")
  }

  // Receive notifications
  ngOnInit() {
    console.log("Angular : swPush.messages opened")
    this.swPush.messages.subscribe((message: any) => {
      console.log("Angular : swPush.messages.subscribe opened");
      this.handlePushNotification(message);
      console.log("Angular : swPush.messages.subscribe closed");
    });
    console.log("Angular : swPush.messages closed")
  }

  handlePushNotification(message: any) {
    console.log("Angular : handlePushNotification opened");
    if (Notification.permission === 'granted') {
      const notificationOptions = {
        body: message.notification.body,
        //icon: 'assets/notification-icon.png'
      };
      new Notification(message.notification.title, notificationOptions);
    }
    console.log(message.notification.title, message.notification.body);
    console.log("Angular : handlePushNotification closed");
  }
}
