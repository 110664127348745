<app-return-link
  [route]="'/home'"
  [text]="'Accueil'">
</app-return-link>
<h1 class="text-center mt-3">Compte famille DS</h1>
<div class="personal-info-container shadow-sm p-2 pb-3 mb-3 bg-white rounded mx-3 d-flex flex-column">
  <div class="menu-toggle" (click)="toggleMenu()">
    <span class="field2-section d-block mb-2">Informations personnelles</span>
    <span class="arrow" [class.up]="isMenuOpen">&#9660;</span>
  </div>
  <ul class="aboveList" [ngClass]="{'d-none':isMenuOpen}">
    <li>
      <label><i class="fa-regular fa-user"></i></label>
      <p>{{firstname + ' ' + lastname}}</p>
    </li>
  </ul>
  <ul class="menu" [class.active]="isMenuOpen">
    <li>
      <label><i class="fa-regular fa-user"></i></label>
      <p>{{firstname + ' ' + lastname}}</p>
    </li>
    <li>
      <label><i class="fa-solid fa-at"></i></label>
      <p>{{email}}</p>
    </li>
    <li>
      <label><i class="fa-solid fa-phone"></i></label>
      <p>{{phoneNumber}}</p>
    </li>
    <li>
      <label><i class="fa-solid fa-map-location-dot"></i></label>
      <p>{{adress}}</p>
    </li>
    <li class="d-flex justify-content-center mb-3 mx-3">
      <button class="button button-big-size blue text-decoration-none my-2 d-flex flex-column" (click)="onAccountEdit()">Modifier</button>
    </li>
  </ul>
</div>

<h2 class="fw-bold field my-2 mx-1"> Gestion membres</h2>




<ul [ngClass]="{'ds-message' : loadingStatus.member !== LoadingStatus.LOADED}">
  <li [ngClass]="{'spinner-border spinner-border-sm ': loadingStatus.member !== LoadingStatus.LOADED}"></li>
  <li *ngIf="loadingStatus.member !== LoadingStatus.LOADED">Chargement...</li>
  <li class="member-button button-member-size text-decoration-none" *ngFor="let member of members">
    <a class="grey_text" (click)="onEditMember(member)">
      <i class="fa-regular fa-user"></i> {{member.prenom}} {{member.nom.toUpperCase()}} - {{member.date_naissance | date:'dd/MM/YY'}}
    </a>
  </li>
</ul>

<div class="d-flex flex-column mx-3 my-5 justify-content-center">
  <div class="mb-3 d-flex justify-content-center">
    <button class="button button-big-size blue text-decoration-none" (click)="onMemberAdd()">
      <i class="fa-solid fa-user-plus"></i> Ajouter un membre
    </button>
  </div>
  <div class="d-flex justify-content-center">
    <button class="button button-big-size blue text-decoration-none" (click)="onMemberSearch()">
      <i class="fa-solid fa-magnifying-glass"></i> Rechercher un membre
    </button>
  </div>
</div>

