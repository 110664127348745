import {Component, OnInit, SecurityContext} from '@angular/core';
import {InscriptionCours} from "../../../../../models/inscriptionCours";
import {Member} from "../../../../../models/Member";
import {MedicalInformation} from "../../../../../models/MedicalInformation";
import {RadioElement} from "../../../../../models/RadioElement";
import {DomSanitizer} from "@angular/platform-browser";
import {DataService} from "../../../../../service/data.service";
import {Router} from "@angular/router";
import he from "he";
import {FooterService} from "../../../../common/footer/footer.service";
@Component({
  selector: 'app-preference-cours',
  templateUrl: './preference-cours.component.html',
  styleUrls: ['./preference-cours.component.css']
})
export class PreferenceCoursComponent implements OnInit {

  /*
  * Je reçois comme information de la page précédente :
  * - L'ID du stage
  * - L'ID de la personne (à inscrire)
  *
  * J'ai besoin :
  * - des périodes de la journée possible pour le stage
  * - Informations concernant la personne à inscrire
  *     (
  * */

  /* Info qui seront passée concernant la page 1 du formulaire */
  protected connectedAccount = JSON.parse(localStorage.getItem('selectedCompte') || '{}');

  protected selectedMember = JSON.parse(sessionStorage.getItem('selectedMember') || '{}');
  protected inscritID: number = this.selectedMember.numero_personne;

  protected selectedCours = JSON.parse(sessionStorage.getItem('selectedCours') || '{}');
  protected coursID: number = this.selectedCours.id;

  protected infoInscription : InscriptionCours = JSON.parse(sessionStorage.getItem('inscriptionCours') || '{}');

  /* Données */
  protected fCopain: string = this.infoInscription.copain;
  protected fCommentaire: string = this.infoInscription.commentaire_copain;
  protected facturation: string = this.infoInscription.moyen_payement != null ? this.infoInscription.moyen_payement : "Pas de facture";
  protected commentaire: string = this.infoInscription.commentaire_global;

  protected personnalInfos!: Member; // Information personnel (non-modifiable)
  protected haveDayOption: boolean = false;

  /* Constantes donnant les possibilités de réponses */
  protected typeCours: RadioElement[] = new Array(0); // Tableau des options présentent dans la sélection 'type stage'
  protected payementTypes: Map<number, string> = new Map();
  protected stageName!: string;


  error: boolean = false;
  showPopUp: boolean = false;
  protected message: Map<string, string> = new Map();

  constructor(private sanitizer: DomSanitizer, private dataService: DataService, private router:Router, private footerService: FooterService) {
    if (this.selectedMember == null || this.selectedCours == null || this.connectedAccount == null) {
      this.router.navigateByUrl("/");
    }
  }

  async ngOnInit(): Promise<void> {
    this.footerService.setFooterVisibility(true);
    this.stageName = he.decode(this.selectedCours.name);
    this.payementTypes = this.getPayementTypes();
    this.personnalInfos = this.getPersonnalInfo();
    this.haveDayOption = true;
    this.typeCours = await this.getDayOptions();
    this.updateChosenDayPeriod(this.infoInscription.periode_annee === null ? 'Annee' : this.infoInscription.periode_annee);
    this.typeCours.forEach(value => {
      if (this.infoInscription.periode_annee === value.value) {
        value.isChecked = true;
      }
      // if (this.selectedStage.semesterPrice == null && (value.value === "AM" || value.value === "PM")) {
      //   value.canBeChecked.false
      // }
    });
  }

  protected togglePopup() {
    this.showPopUp = !this.showPopUp;
    // console.log('Close popup : ' + this.error);
  }

  async getDayOptions(): Promise<RadioElement[]> {
    let periodOfDay: RadioElement[] = [
      new RadioElement("Année complète (septembre à juin)", "Annee", false, true),
      new RadioElement("Premier semestre (septembre à janvier)", "Premier_semestre", false, true),
      new RadioElement("Second semestre (janvier à juin)", "Second_semestre", false, true)
    ];
    if (this.selectedCours.semesterPrice == null) {
      periodOfDay.splice(1, 2);
    }
    // console.log('Before promess');
    return new Promise<RadioElement[]>((resolve, reject) => {
      this.dataService.getPeriodOfYear(this.coursID).subscribe({
        next: (element: any) => {
          if (element) {
            // Define an array of keys for each option
            const optionKeys = ["Annee", "Premier_semestre", "Second_semestre"];

            // Iterate through the options and modify the corresponding RadioElement
            for (let i = 0; i < optionKeys.length; i++) {
              const key = optionKeys[i];
              const index = i;

              if (element[key] === null) {
                // Remove the option if it's null
                periodOfDay.splice(index, 1);
              } else if (element[key] >= 1) {
                // Modify the option if it's 1
                periodOfDay[index].canBeChecked = false;
              }
            }
            if (periodOfDay.length == 1) {
              periodOfDay[0].isChecked = true;
            }
          }
          resolve(periodOfDay);
        },
        error: (error) => {
          reject(error);
        }
      });
    });
  }

  private getPayementTypes() {
    return new Map<number, string>([
      [1, "BNP PARIBAS FORTIS"],
      [2, "CPAS"],
      [3, "SAJ"],
      [4, "SPJ"],
      [5, "FWB"],
      [6, "AUTRE"]
    ]);
  }
  protected getSelectedPayementTypes() {
    let correspondingKey = -1;
    this.payementTypes.forEach((value, key) => {
      if (value === this.facturation) {
        correspondingKey = key;
      }
    });
    // console.log('Preference - getSlectedPayementTypes - SelectedValue : ' + correspondingKey);
    return correspondingKey;
  }


  private getPersonnalInfo() {
    return new Member(
      this.selectedMember.numero_personne,
      this.selectedMember.nom,
      this.selectedMember.prenom,
      this.selectedMember.birthDate,
      this.selectedMember.genre,
      this.selectedMember.phoneNumber,
      this.selectedMember.commentaire,
      this.selectedMember.remarques,
      this.selectedMember.profession_parents,
      this.selectedMember.fonction,
      this.selectedMember.sport_pratique,
      this.selectedMember.ecole,
      this.selectedMember.numero_compte,
      this.selectedMember.numero_national,
      this.selectedMember.email
    )
  }

  /*All Emiter events handlers*/
  updateChosenDayPeriod(key: string) {
    // Update the resultData based on the emitted data
    console.log('List Call')
    this.infoInscription.periode_annee = key;
  }

  updateFacturationOption(eventData: string) {
    // console.log("La data reçue : " + eventData);
    this.facturation = eventData;
  }

  private isThereErrors(): boolean {
    // TODO soulever une erreur si manquant
    // TODO Montrer l'erreur dans la vue

    return this.checkMoyenPayement() || this.checkPeriodeOfDay();
  }

  private checkPeriodeOfDay() {
    if (this.infoInscription.periode_annee != "Annee" && this.infoInscription.periode_annee != "Premier_semestre" && this.infoInscription.periode_annee != "Second_semestre") {
      console.log('(cours.preference) - checkPeriodeOfDay() : error : missing period of Year');

      this.message.set('periodeAnnee', "Vous n'avez pas indiqué sur quelle période de l'année vous souhaitez vous inscrire.");
      return true;
    } else {
      this.message.delete('perdiodeAnnee');
      return false;
    }
  }

  private checkMoyenPayement() {
    if (this.infoInscription.moyen_payement == undefined) {
      console.log('(cours.preference) - checkMoyenPayement() : error : missing payement method');

      this.message.set('moyenPayement', "Vous n'avez pas indiqué de moyen de facturation.");
      return  true;
    } else {
      this.message.delete('moyenPayement');
      return false
    }
  }

  handleForm() {
    /* Récupération du moment de la journée sélectionné */
    // Pas besoin ici car fait en direct

    /* Récupération de l'ami */
    this.infoInscription.copain = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(this.fCopain)) ?? '';
    /* Récupération du commentaire pour l'ami*/
    this.infoInscription.commentaire_copain = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(this.fCommentaire)) ?? '';
    /* Récupération du commentaire à l'inscription */
    this.infoInscription.commentaire_global = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(this.commentaire)) ?? '';
    /* Récupération du moyen de payement */
    this.infoInscription.moyen_payement = this.facturation;

    console.log(this.infoInscription);

    if (!this.isThereErrors()) {
      sessionStorage.setItem('inscriptionCours', JSON.stringify(this.infoInscription));
      this.router.navigateByUrl('inscription.cours.recapitulatif')
    } else {
      this.togglePopup();
    }
    console.log('Handle Form : ' + this.error);

  }
}
