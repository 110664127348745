<button (click)="onStageClick(0)" class="card shadow btn btn-secondary">

  <label class="fw-bold field mb-2 mx-auto" [innerHTML]="Stage.NomStage"></label>

  <div class="row align-items-start">

    <div class="col-12">
      <Strong>Lieu: </Strong><span>{{Stage.Lieu}}</span>
    </div>
    <div class="col-12">
      <Strong>Date: </Strong><span>du {{Stage.DateDebutInstanceStage | date:'dd/MM/yyyy'}}
                                    au {{Stage.DateFinInstanceStage | date:'dd/MM/yyyy'}}</span>
    </div>

    <div class="col-12">
      <Strong>Reste à payer : </Strong><span>{{this.prix | currency:'EUR':'symbol':'1.2-2'}}</span>
    </div>

    <div></div>
  </div>
</button>

