import {Component, Input} from '@angular/core';
import {StageCard} from "../../../models/StageCard";
import {Router} from "@angular/router";
import {StageRecap} from "../../../models/StageRecap";

@Component({
  selector: 'app-stage-card-recap',
  templateUrl: './stage-card-recap.component.html',
  styleUrls: ['./stage-card-recap.component.css']
})
export class StageCardRecapComponent {
  @Input() public Stage:StageRecap

  prix: number = 0;
  constructor(private router: Router) {
    this.Stage = new StageRecap();
  }


  ngOnInit() {
    this.prix = this.Stage.TypeStage === "JC" ? this.Stage.PrixJournee : this.Stage.PrixDemiJournee
    - (this.Stage.Reduction - this.Stage.Remboursement + this.Stage.Acompte + this.Stage.Acompte2);
  }
  /**
   * When stage card clicked, redirect to stage details
   * @param Id id of the stage
   */
  onStageClick(Id: number) {
    // setTimeout(() => this.router.navigate(['/stages/', Id]), 500)
    // setTimeout(() => this.router.navigateByUrl(`facesnaps/${Id}`), 500)
    // setTimeout(() => this.router.navigateByUrl(`stages/${Id}`), 500)
  }
}
