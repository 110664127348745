import {Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-scroll-up',
  templateUrl: './scroll-up.component.html',
  styleUrls: ['./scroll-up.component.css']
})
export class ScrollUpComponent implements OnInit, OnDestroy {
  @Input() selector!: string;
  protected visible: boolean = false;

  constructor(private zone: NgZone) {
  }
  ngOnInit() {
    $(document).ready(() => {
      window.addEventListener("scroll", () => {
        this.visible = window.pageYOffset > 100;
      });
    });
  }

  ngOnDestroy(): void {
    $(document).ready(() => {
      window.removeEventListener("scroll", () => {});
    });
  }

  onScrollTo() {
    setTimeout(() => window.scrollTo({top: 0, behavior: "smooth"}), 0)
  }
}
