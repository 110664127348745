<app-return-link
  [route]="'/home'"
  [text]="'Accueil'">
</app-return-link>

<h1 class="text-center">Cours</h1>



<!-- Filtres-->
<div class="d-flex justify-content-between align-items-start mx-3 mb-2">
  <span class="field-section">Filtres</span>
  <!-- reset-->
  <button class="refresh-filter" (click)="CoursEvent.onResetFiltersClick.call(this)">
    Effacer filtres
  </button>
</div>


<!-- Type de cours-->
<app-radio-list
  [values]="Model.courseTypes"
  [title]="'Type de cours'"
  (onElemClick)="CoursEvent.onCoursTypeClick.call(this, $event.key)">
</app-radio-list>


<!-- Lieux choix multiple-->
<!--<app-multi-select
      [Id]="IdMultiselect"
      [Values]="Array.from(Model.places.keys())"
      [Enables]="Array.from(Model.places.values())"
      (change)="onPlaceClick($event)">
    </app-multi-select>
</section>-->
<app-multiselect-block
  [status]="coursStatusProvider.places"
  [initData]="multiselectInilialiser"
  [update$]="multiselectUpdate$"
  (onMultiselectClick)="CoursEvent.onPlaceClick.call(this, $event.value, $event.checked)">
</app-multiselect-block>


<!--  age-->
<app-age-range
  [resetEvent$]="resetFilters$"
  [ageRange]="this.Model.ageRange"
  [delay]="REQUEST_DELAY"
  (ageUpdate)="CoursEvent.onAgeUpdate.call(this, $event)" >
</app-age-range>


<!--  jours -->
<section class="shadow-sm p-2 pb-1 mb-3 bg-white rounded mx-3 d-flex flex-column" *ngIf="coursStatusProvider.piscineDays">
  <span class="field2-section d-block mb-1">Jours (uniquement pour piscine)</span>
  <div class="lieux-list list-group d-flex flex-row flex-wrap align-content-start mt-0">
    <button *ngFor="let day of Model.days |keyvalue: daysOriginalOrder " class="app-list-button list-group-item active shadow-sm" [class.active]="day.value" (click)="CoursEvent.onPiscineDaysClick.call(this, day.key, day.value)">{{ day.key }}</button>
  </div>
</section>


<!-- appliquer filtre -->
<div class="ds-btn-confirmation-container">
  <!-- appliquer filtre-->
  <button class="shadow btn btn-outline-success ds-btn-confirmation ml-auto mr-1" type="button"
          (click)="Stream.filteredCourses$.next('confirm-button')"
          [ngClass]="{disabled: !Model.isValidFilter()}" [ngStyle]="!Model.isValidFilter()?{'background-color' : 'darkgray'}:{}">
    Rechercher
  </button>

  <!-- auto filtres-->
  <button class="shadow btn btn-rounded auto-filter ds-btn-confirmation mr-auto ml-1"
          [ngClass]="{'shadow-sm': !Model.isAutoApplyFilters, disabled: !Model.isValidFilter()}"
          [ngStyle]="{'background-color': !Model.isValidFilter() ? 'darkgray' : (!Model.isAutoApplyFilters ? 'lightgray' : '#48ABF6FF')}"
          (click)="CoursEvent.onAutoFilterClick.call(this)">
    <input class="form-check-input ds-checkbox ml-auto position-relative" type="checkbox" [checked]="Model.isAutoApplyFilters">
    <span>Auto</span>
  </button>
</div>


<!-- espace-->
<hr class="section-separator {{scrollToCoursClass}}">


<!--  cours disponibles-->
<h2 class="field-section text-center">Cours disponibles [{{paginator.total!==undefined && coursStatusProvider.cours.isValid?paginator.total:'...'}}]</h2>

<div class="card-list mb-5" [ngStyle]="{'min-height': window.innerHeight-120 + 'px'}">

  <!--PAGINATION-->
  <app-pagination *ngIf="Model.isValidFilter() && coursStatusProvider.cours.isValid && this.paginator.total"
    [ngClass]="{'d-none': !coursStatusProvider.cours.isValid}"
    [paginator]="this.paginator"
    [status]="coursStatusProvider.cours"
    (onClick)="CoursEvent.onPaginationClick.call(this, $event)">
  </app-pagination>

  <!-- MESSAGE D'ÉTAT DE CHARGEMENT DES COURS-->
  <span class="ds-message m-4" [ngClass]="coursStatusProvider.cours.classes">
    <div *ngIf="coursStatusProvider.cours.isLoading" class="spinner-border spinner-border-sm" role="status">
      <span class="sr-only">Loading...</span>
    </div> {{coursStatusProvider.cours.message}}
  </span>

  <!-- RÉSULTAT STAGES-->
  <app-course-card
    class="mb-3"
    [ngClass]="{'d-none': !coursStatusProvider.cours.isValid}"
    *ngFor="let course of Model.filteredCourses"
    [Course]="course">
  </app-course-card>
</div>

<!-- SCROLL TO STAGES-->
<app-scroll-to-icon
 [selector]="scrollToCoursClass">
</app-scroll-to-icon>
