<section class="shadow-sm p-2 pb-3 mb-3 bg-white rounded mx-3">
  <span class="field2-section d-block mb-2">{{title}}</span>
  <!-- MESSAGE DE STATUS SEMAINES-->
  <span class="ds-message" [ngClass]="status.classes">
      <div *ngIf="status.isLoading" class="spinner-border spinner-border-sm" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    {{status.message}}</span>

  <ul *ngIf="!status.isLoading" class="list-group list-group-horizontal list-group-flush ds-select-list ds-checkbox-container d-flex flex-column flex-wrap">
    <li *ngFor="let entry of getRecords(values)" class="list-group-item border-0 py-1">
      <input class="form-check-input ds-checkbox"
             [id]="entry.key"
             [type]="'checkbox'"
             [checked]="entry.value"
             (change)="onElemClick.emit({key: entry.key, value: !entry.value})">
      <label [for]="entry.key">{{ entry.key }}</label>
    </li>
  </ul>
</section>
