import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {API_BASE_URL} from "./config";
import {Observable} from "rxjs";

@Injectable()
export class AuthService {
  private loginUserUrl= `${API_BASE_URL}loginUser`;

  private token = 'MyFakeToken';

  constructor(private http: HttpClient) { }

  getToken(): string {
    return this.token;
  }

  login(formData: any): Observable<any> {
    const username = formData.email;
    const password = formData.password;

    // Créer un objet avec les données à envoyer
    const credentials = { username, password };

    // En-têtes pour la requête HTTP
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*',
    });

    // Envoie les données au backend en tant que requête POST
    console.log('loginUserUrl', this.loginUserUrl);
    return this.http.post<any>(this.loginUserUrl, credentials, { headers });
  }

  logout(): void {
    localStorage.removeItem('selectedCompte');
  }

  isAuthenticatedUser(): boolean {
    //return this.isAuthenticated;
    const selectedCompte = localStorage.getItem('selectedCompte');
    return selectedCompte !== null && selectedCompte !== undefined;
  }

}
