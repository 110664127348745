export class StageRecap {
  public NumeroInscriptionStage: number;
  public NumeroPersonne: number;
  public NumeroInstanceStage: number;
  public NumeroCompte: number;
  public Reduction: number;
  public Acompte: number;
  public Acompte2: number;
  public Solde: number;
  public Remboursement: number;
  public DateDebutInstanceStage: Date;
  public DateFinInstanceStage: Date;
  public NomStage: string;
  public Lieu: string;
  public TypeStage: string;
  public PrixJournee: number;
  public PrixDemiJournee: number;

  constructor(NumeroInscriptionStage: number = 0,
              NumeroPersonne: number = 0,
              NumeroInstanceStage: number = 0,
              NumeroCompte: number = 0,
              Reduction: number = 0,
              Acompte: number = 0,
              Acompte2: number = 0,
              Solde: number = 0,
              Remboursement: number = 0,
              DateDebutInstanceStage: Date = new Date(),
              DateFinInstanceStage: Date = new Date(),
              NomStage: string = "",
              Lieu: string = "",
              TypeStage: string = "",
              PrixJournee: number = 0,
              PrixDemiJournee: number = 0) {
    this.NumeroInscriptionStage = NumeroInscriptionStage;
    this.NumeroPersonne = NumeroPersonne;
    this.NumeroInstanceStage = NumeroInstanceStage;
    this.NumeroCompte = NumeroCompte;
    this.Reduction = Reduction;
    this.Acompte = Acompte;
    this.Acompte2 = Acompte2;
    this.Solde = Solde;
    this.Remboursement = Remboursement;
    this.DateDebutInstanceStage = DateDebutInstanceStage;
    this.DateFinInstanceStage = DateFinInstanceStage;
    this.NomStage = NomStage;
    this.Lieu = Lieu;
    this.TypeStage = TypeStage;
    this.PrixJournee = PrixJournee;
    this.PrixDemiJournee = PrixDemiJournee;
  }
}
