import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {CustomValidators} from "../input/CustomValidators";
import {DataService} from "../../../../service/data.service";
import {ToastrService} from "ngx-toastr";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {PopupModalComponent} from "../../../common/popup-modal/popup-modal.component";
import {Location} from "@angular/common";
import {Router} from "@angular/router";


@Component({
  selector: 'app-cree-membre',
  templateUrl: './cree-membre.component.html',
  styleUrls: ['./cree-membre.component.css', '../../../common/styles/common.css']
})
export class CreeMembreComponent implements OnInit, AfterViewInit {
  returnName: string = 'Retour';

  registerForm!:FormGroup;
  protected buttonStatus : 'loading' | 'idle' = 'idle';
  private loadingDialog!: MatDialogRef<any, any>;

  protected readonly JSON = JSON;
  selectedLink: string;


  constructor(private formBuilder : FormBuilder,
              private dataService: DataService,
              private toastr: ToastrService,
              private dialog: MatDialog,
              private router: Router) {

    const selectedStage = sessionStorage.getItem('selectedStage');
    const selectedCours = sessionStorage.getItem('selectedCours');
    this.selectedLink = selectedStage ? '/inscription.stages.selection-membre-stage' :
                        selectedCours ? '/inscription.cours.selection-membre-cours' : '/gestion-compte';

    this.returnName = selectedStage ? 'Étape 1/3 : Sélectionner un membre' :
                      selectedCours ? 'Étape 1/3 : Sélectionner un membre' : 'Compte';
  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      nom: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})]],
      prenom: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})]],
      dateNaissance: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})]],
      genre: ['', [
        CustomValidators.required()]],
      numNat: ['', [
        CustomValidators.required(),
        CustomValidators.numNat()]],
      email: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100}),
        CustomValidators.email()]],
      gsm: ['', [
        CustomValidators.required()]],
      sportPratique: ['', [
        CustomValidators.maxLen({length: 5000})]],
      commentaire: ['', [
        CustomValidators.maxLen({length: 5000})]],
      medecinTraitant_nom: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})]],
      medecinTraitant_tel: ['', [
          CustomValidators.required()
      ]],
      anneeDernierRappelTetanos: ['', [
        CustomValidators.required(),
        CustomValidators.minmax({min: this.getCurrentYear() - 100, max: this.getCurrentYear()}),
      ]],
      allergies: ['', [
        CustomValidators.maxLen({length: 5000})]],
      medicaments: ['', [
        CustomValidators.maxLen({length: 5000})]],
      donnees_medicales_specifiques: ['', [
        CustomValidators.maxLen({length: 5000})]],
    })
    // -> pour remplir les champs
    // this.registerForm.patchValue({
    //   nom: 'TEST',
    //   prenom: 'DETEST',
    //   dateNaissance: '2003-01-25',
    //   genre: 'M',
    //   numNat: '00000000000',
    //   email: 'test@test',
    //   gsm: '1234678910',
    //   sportPratique: 'judo',
    //   commentaire: '',
    //   medecinTraitant_nom: 'M. Docteur',
    //   medecinTraitant_tel: '11111111111',
    //   anneeDernierRappelTetanos: 2000,
    //   allegie: '',
    //   medicaments: '',
    //   donneesMedicalSpecifiques: '',
    // });
  }

  ngAfterViewInit() {
  }

  validateForm() {
    this.submitted = true;
    // Mark all controls as touched to trigger validation messages
    this.registerForm.markAllAsTouched();

    // Check if any of the form controls have invalid validators
    const invalidControls = Object.keys(this.registerForm.controls).filter(controlName =>
      this.registerForm.get(controlName)?.invalid
    );

    this.registerForm.patchValue({
      numNat: (this.registerForm.get("numNat")?.getRawValue().replace(/[.\-\s]/g, '')),
      nom: (this.registerForm.get('nom')?.getRawValue().toUpperCase())
    })

    // If there are invalid controls, handle them accordingly
    if (invalidControls.length > 0) {
      // Handle the case when there are invalid controls
      console.log("Invalid validators found:", invalidControls);
      // You can show a message or perform any other action here
    } else {
      // All validators are valid, proceed with form submission
      this.submitForm();
    }
  }
  submitForm() {
    // mise à jour des états
    this.buttonStatus = "loading";
    // CHARGEMENT
    this.loadingDialog = this.dialog.open(PopupModalComponent, {
      disableClose: true,
      panelClass: 'loading-dialog-overlay',
      data: {
        titre: 'Chargement',
        width: 100,
      },
    });

    // vérifie si le numéro du compte est stocké en local
    let data = localStorage.getItem('selectedCompte');
    let user;
    let numCompte;
    if (!data || !(user = JSON.parse(data)) || !user.numero_compte) {
      this.router.navigateByUrl(`connexion`);
    } else {
      let numCompte = user.numero_compte;
      let login = user.login;
      this.sendRequestAddMember(numCompte, login);
    }

    // modification des validations dynamiquement
    // this.registerForm.get('nom')?.clearValidators();
    // this.registerForm.get('nom')?.addValidators(CustomValidators.maxLen({length: 1}));
    // this.registerForm.get('nom')?.updateValueAndValidity();
  }

  protected readonly Object = Object;
  protected readonly console = console;
  protected readonly Array = Array;
  protected readonly Date = Date;
  protected submitted: boolean = false;

  protected getCurrentYear() : number {
    return new Date(Date.now()).getFullYear();
  }

  private sendRequestAddMember(numCompte: number, loginEmail: string) {
    // ENVOIE REQUETE VERS LE BACK-END
    this.dataService.addMember(this.registerForm.value, numCompte, loginEmail)
      .then((value) => {
        console.log("VALUE: " + JSON.stringify(value))
        // CRÉATION AVEC SUCCÈS
        this.toastr.success( "Le membre a été créé avec succès", "Membre créé", {
          timeOut: 5000,
          progressBar: true,
          closeButton: true,
          enableHtml: true,
          positionClass:"toast-top-full-width"
        });

        // redireciton
        this.router.navigateByUrl(this.selectedLink);
    })
      .catch(reason => {
        this.toastr.error( reason, "Une erreur s'est produit", {
          timeOut: 5000,
          progressBar: true,
          closeButton: true,
          enableHtml: true,
          positionClass:"toast-top-full-width"
        });
      })
      .finally(() => {
        setTimeout(() => {
          this.submitted = false;
          this.buttonStatus = "idle";
        }, 2000);
        this.loadingDialog.close();
      });
  }

}
