import { Component } from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Account} from "../../../models/Account";
import {DataService} from "../../../service/data.service";
import {CustomValidators} from "../membre/input/CustomValidators";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {PopupModalComponent} from "../../common/popup-modal/popup-modal.component";
import {ToastrService} from "ngx-toastr";
import {NationalNumberPipe} from "../../../pipes/national-number.pipe";
import {map, Observable, startWith, tap} from "rxjs";

@Component({
  selector: 'app-modification-compte',
  templateUrl: './modification-compte.component.html',
  styleUrls: ['./modification-compte.component.css', '../../common/styles/common.css']
})
export class ModificationCompteComponent {

  registerForm!:FormGroup;

  protected submitted: boolean = false;
  user : any;
  protected buttonStatus : 'loading' | 'idle' = 'idle';
  private loadingDialog!: MatDialogRef<any, any>;

  protected postCodeControl = new FormControl();
  protected postCodeData: PostCodeData[] = [];
  protected filteredPostCodeData: PostCodeData[] = [];

  constructor(private dialog: MatDialog,
              private toastr: ToastrService,
              private dataService:DataService,
              private router: Router,
              private formBuilder: FormBuilder,
              private numNatPipe: NationalNumberPipe) {
    // Récupération de l'utilisateur
    this.getUser();
  }

  async ngOnInit() {
    this.registerForm = this.formBuilder.group({
      login: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100}),
        CustomValidators.email()
      ]],
      password: ['', [
        CustomValidators.maxLen({length: 100})
      ]],
      numNat: ['', [
        CustomValidators.numNat(),
      ]],
      rue: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})
      ]],
      numero: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})
      ]],
      postCodeControl: this.postCodeControl,
      pays: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})
      ]],
      nom1: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})
      ]],
      prenom1: ['', [
        CustomValidators.required(),
        CustomValidators.maxLen({length: 100})
      ]],
      gsm1: ['', [
        CustomValidators.maxLen({length: 100})
      ]],
      telFixe: ['', [
        CustomValidators.maxLen({length: 100})
      ]],
      nom2: ['', [
        CustomValidators.maxLen({length: 100})
      ]],
      prenom2: ['', [
        CustomValidators.maxLen({length: 100})
      ]],
      gsm2: ['', [
        CustomValidators.maxLen({length: 100})
      ]],
      email2: ['', [
        CustomValidators.maxLen({length: 100}),
        CustomValidators.email()
      ]]
    });
    this.registerForm.patchValue({
      // Infos personnelles
      login: (this.user == null ? "" : this.user.email),
      password: (""),
      numNat: (this.user == null ? "" : this.numNatPipe.transform(this.user.numeroNational)),
      rue: (this.user == null ? "" : this.user.rue),
      numero: (this.user == null ? "" : this.user.numero),
      codePostal: (this.user == null ? "" : this.user.code_postal),
      localite: (this.user == null ? "" : this.user.localite),
      pays: (this.user == null ? "" : this.user.pays),
      nom1: (this.user == null ? "" : this.user.nom),
      prenom1: (this.user == null ? "" : this.user.prenom),
      gsm1: (this.user == null ? "" : this.user.gsm),
      telFixe: (this.user == null ? "" : this.user.telephone),
      nom2: (this.user == null ? "" : this.user.nom2),
      prenom2: (this.user == null ? "" : this.user.prenom2),
      gsm2: (this.user == null ? "" : this.user.gsm2),
      email2: (this.user == null ? "" : this.user.email2),
    });

    // Fetch postal code data and then set initial value and filter
    this.getAllPostCodeData().subscribe(() => {
      this.postCodeControl = this.formBuilder.control('', [
        CustomValidators.required(),
        CustomValidators.possibleValue(this.postCodeData)
      ]);
      this.registerForm.setControl('postCodeControl', this.postCodeControl);
      this.filterPostCodeData(); // Added to start filtering after setting initial value
      this.setInitialPostCodeValue(this.user?.numero_cp);
    });
  }

  protected arePosalCodesLoaded(): boolean {
    return this.postCodeData.length > 0;
  }

  private setInitialPostCodeValue(numero_cp: number) {
    const initialPostCodeData = this.postCodeData.find(entry => entry.NUMERO_CP.toString() == numero_cp.toString());
    if (initialPostCodeData) {
      // console.log("initialPostCodeData: " + initialPostCodeData.CODE_POSTAL)
      // console.log("initialPostCodeData: " + initialPostCodeData.LOCALITE)
      // console.log("initialPostCodeData: " + initialPostCodeData.NUMERO_CP)
      this.postCodeControl.setValue(initialPostCodeData);  // Setting the initial value for the form control
    } else {
      console.error('No matching post code data found for NUMERO_CP:', numero_cp);  // Debugging statement
    }
  }

  private getAllPostCodeData(): Observable<PostCodeData[]> {
    return this.dataService.getAllPostCode().pipe(
      tap((data: PostCodeData[]) => {
        this.postCodeData = data;  // Assigning fetched data to local variable
      })
    );
  }

  private filterPostCodeData(): void {
    this.postCodeControl.valueChanges.pipe(  // Listening to value changes on the form control
      startWith(''),
      map(value => typeof value === 'string' ? this.filter(value) : this.postCodeData.slice())
    ).subscribe(filteredData => {
      this.filteredPostCodeData = filteredData;  // Assigning filtered data to local variable
    });
  }

  private filter(value: string): PostCodeData[] {
    const filterValue = value.toLowerCase();
    // console.log("this.postCodeControl.value: " + this.postCodeControl.value);
    return this.postCodeData.filter(entry =>
      `${entry.LOCALITE.toLowerCase()} (${entry.CODE_POSTAL})`.includes(filterValue)
    );
  }

  displayFn(entry: PostCodeData): string {
    // return entry ? `${entry.LOCALITE} (${entry.CODE_POSTAL}) - ${entry.NUMERO_CP}` : ''; // For debugging
    return entry ? `${entry.LOCALITE} (${entry.CODE_POSTAL})` : ''; // Normal behavior
  }

  validateForm() {
      // Mark all controls as touched to trigger validation messages
      this.registerForm.markAllAsTouched();

      // Check if any of the form controls have invalid validators
      const invalidControls = Object.keys(this.registerForm.controls).filter(controlName =>
          this.registerForm.get(controlName)?.invalid
      );

    this.registerForm.patchValue({
      numNat: (this.registerForm.get("numNat")?.getRawValue().replace(/[.\-\s]/g, '')),
      nom1: (this.registerForm.get('nom1')?.getRawValue().toUpperCase()),
      nom2: (this.registerForm.get('nom2')?.getRawValue().toUpperCase())
    })

    // If there are invalid controls, handle them accordingly
      if (invalidControls.length > 0) {
          // Handle the case when there are invalid controls
          console.log("Invalid validators found:", invalidControls);
          // You can show a message or perform any other action here
      } else {
          // All validators are valid, proceed with form submission
          this.submitForm();
      }
  }

  submitForm() {
    // mise à jour des états
    this.submitted = true;
    this.buttonStatus = "loading";
    // CHARGEMENT
    this.loadingDialog = this.dialog.open(PopupModalComponent, {
      disableClose: true,
      panelClass: 'loading-dialog-overlay',
      data: {
        titre: 'Chargement',
        width: 100,
      },
    });

    this.sendEditMemberRequest();
  }

  private matchAccountObject($userData: any): Account {
    return new Account({
      numero_compte: $userData[0]['NUMERO_COMPTE'] ?? 0,
      login: $userData[0]['LOGIN'] ?? '',
      email: $userData[0]['EMAIL'] ?? '',
      rue: $userData[0]['RUE'] ?? '',
      numero: $userData[0]['NUMERO'] ?? '',
      code_postal: $userData[0]['CODE_POSTAL'] ?? '',
      numero_cp: $userData[0]['NUMERO_CP'] ?? '',
      localite: $userData[0]['LOCALITE'] ?? '',
      pays: $userData[0]['PAYS'] ?? '',
      telephone: $userData[0]['TELEPHONE'] ?? '',
      gsm: $userData[0]['GSM'] ?? '',
      nom: $userData[0]['NOM'] ?? '',
      gsm2: $userData[0]['GSM_2'] ?? '',
      nom2: $userData[0]['NOM_2'] ?? '',
      newsletter: $userData[0]['NEWSLETTER'] ?? '0',
      prenom: $userData[0]['PRENOM'] ?? '',
      prenom2: $userData[0]['PRENOM_2'] ?? '',
      email2: $userData[0]['EMAIL_2'] ?? '',
      numeroNational: $userData[0]['NUMERO_NATIONAL'] ?? '',
    });
  }

  private getUser(): any {
    const selectedCompteString = localStorage.getItem('selectedCompte');
    if (selectedCompteString) {
      this.user = JSON.parse(selectedCompteString);
    }
  }

  private sendEditMemberRequest() {
    const allFormValues = this.registerForm.value;
    const user = this.user;
    console.log('Tous les champs du formulaire :', allFormValues);
/*
    this.dataService.updateUserData(user.numero_compte, allFormValues).subscribe(
      response => {
        console.log("response : " + response);
        if (response.message === 'ok') {
          // Update du user en localStorage
          localStorage.setItem('selectedCompte', JSON.stringify(this.matchAccountObject(response.user)));
        }});*/
    this.dataService.updateUserData(user.numero_compte, allFormValues).subscribe(
      response => {
        console.log("response : " + response);
        if (response.status === 'ok') {
          // Update du user en localStorage
          localStorage.setItem('selectedCompte', JSON.stringify(this.matchAccountObject(response.user)));
          this.toastr.success( "Le compte a été modifié avec succès", "Compte modifié", {
            timeOut: 5000,
            progressBar: true,
            closeButton: true,
            enableHtml: true,
            positionClass:"toast-top-full-width"
          });
          this.router.navigateByUrl('gestion-compte');
        }
      },
      error => {
        console.log("error : " + error.message);
        this.toastr.error( "Erreur lors de la modification du compte", "Dimension Sport", {
          timeOut: 5000,
          progressBar: true,
          closeButton: true,
          enableHtml: true,
          positionClass:"toast-top-full-width"
        });
      },
() => {
        this.buttonStatus = "idle";
      });
    this.loadingDialog.close();
    this.getUser();
  }
}

interface PostCodeData {
  NUMERO_CP: number;
  CODE_POSTAL: string;
  LOCALITE: string;
}
