<div class="alert">
  <i class="fa-solid fa-triangle-exclamation fa-bounce fa-2xl h-auto"></i>
  <div class="flex-row mx-5">
    <div class="msg" *ngFor="let value of errorMsg.values(); let i = index">
      <hr *ngIf="i !== 0">
      {{ value }}
    </div>
  </div>
  <div class="bouton-ferme" (click)="closePopup()"><i class="fa-solid fa-xmark fa-2xl"></i></div>
</div>
