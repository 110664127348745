<button (click)="onCourseClick(Course.Id)" class="card shadow btn btn-secondary">
  <label class="fw-bold field mb-2 mx-auto" [innerHTML]="Course.Title"></label>
  <div class="d-flex align-items-center">
    <!-- Message affiché lorsqu'un cours est complet -->
    <ng-container *ngIf="isFilled()">
      <!--    <ng-container *ngIf="true">-->
      <div style="width: 1.25rem; height: 1.25rem; background-color: red; border-radius: 50%; margin-right: 0.3125rem; margin-bottom: 0.5625rem;"></div>
      <label style="color: red;">Complet (Inscription en liste d'attente)</label>
    </ng-container>
    <!-- Message affiché lorsqu'un cours est presque complet -->
    <ng-container *ngIf="isNearlyFilled()">
      <div style="width: 1.25rem; height: 1.25rem; background-color: orange; border-radius: 50%; margin-right: 0.3125rem; margin-bottom: 0.5625rem;"></div>
      <label style="color: orange;">Plus que quelques places</label>
    </ng-container>
  </div>

  <div class="row align-items-start">
    <div class="col-6 g-1" style="min-width: 12em">
      <Strong>Type: </Strong><span>{{Course.Type}}</span>
    </div>
    <div class="col-6 g-1" style="min-width: 10em">
      <Strong>Lieu: </Strong><span>{{Course.Place}}</span>
    </div>
    <div class="col-6 g-1" style="min-width: 7em">
      <Strong>Jour: </Strong><span>{{Course.Day}} à {{Course.Hour}}</span>
    </div>
    <div class="col-6 g-1" style="min-width: 10em">
      <Strong>Age: </Strong><span>{{Course.Age}}</span>
    </div>
    <div class="col-6 g-1" style="min-width: 10em">
      <Strong>Saison: </Strong><span>{{Course.Saison}}</span>
    </div>
<!--    <div class="col-6 g-1" style="min-width: 10em">-->
<!--      <Strong>id: </Strong><span>{{Course.Id}}</span><br>-->
<!--      <Strong>RemainingSlots: </Strong><span>{{Course.RemainingSlots}}</span><br>-->
<!--      <Strong>isFillForced: </Strong><span>{{Course.isFillForced}}</span><br>-->
<!--      <Strong>isFillForcedSem1: </Strong><span>{{Course.isFillForcedSem1}}</span><br>-->
<!--      <Strong>isFillForcedSem2: </Strong><span>{{Course.isFillForcedSem2}}</span><br>-->
<!--      <Strong>isFillForcedTri: </Strong><span>{{Course.isFillForcedTri}}</span><br>-->
<!--      <Strong>isFillForcedMois: </Strong><span>{{Course.isFillForcedMois}}</span><br>-->
<!--    </div>-->
  </div>
</button>
