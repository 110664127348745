import {Component, Input} from '@angular/core';
import {Router} from "@angular/router";
import {StageRecap} from "../../../models/StageRecap";
import {DataService} from "../../../service/data.service";
import {Member} from "../../../models/Member";
import moment from "moment";

@Component({
  selector: 'app-stage-card-recap',
  templateUrl: './stage-card-recap.component.html',
  styleUrls: ['./stage-card-recap.component.css']
})
export class StageCardRecapComponent {
  @Input() public Stage:StageRecap
  @Input() public Member: Member

  prix: number = 0;
  constructor(private router: Router, private dataService:DataService) {
    this.Stage = new StageRecap();
    this.Member = new Member()
  }


  ngOnInit() {
    this.prix = this.Stage.TypeStage === "JC" ? this.Stage.PrixJournee : this.Stage.PrixDemiJournee
    - (this.Stage.Reduction - this.Stage.Remboursement + this.Stage.Acompte + this.Stage.Acompte2);
    console.log(this.Stage);
  }
  /**
   * When stage card clicked, redirect to stage details
   */
  onStageClick() {
    setTimeout(() => this.router.navigate(['/stages/', this.Stage.NumeroInstanceStage]), 500)
  }

  onDl_attestationFiscale() {
    this.dataService
      .getAttestationFiscale(this.Member.numero_personne, this.Stage.NumeroInstanceStage, this.Stage.NumeroInscriptionStage)
      .subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        console.log(a)
        a.download = "Dimension-Sport_AttestationFiscale_" + this.Member.nom + '_' + this.Member.prenom + '_Stage_' + moment(this.Stage.DateDebutInstanceStage).format('DD-MM-YYYY') + '_' + moment(this.Stage.DateFinInstanceStage).format('DD-MM-YYYY') + '.pdf';;
        a.click();
        window.URL.revokeObjectURL(url); // Clean up
      });
  }

  onDl_attestationMutuelle() {
    this.dataService
      .getAttestationMutuelle(this.Member.numero_personne, this.Stage.NumeroInstanceStage, this.Stage.NumeroInscriptionStage)
      .subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = "Dimension-Sport_AttestationMutuelle_" + this.Member.nom + '_' + this.Member.prenom + '_Stage_' + moment(this.Stage.DateDebutInstanceStage).format('DD-MM-YYYY') + '_' + moment(this.Stage.DateFinInstanceStage).format('DD-MM-YYYY') + '.pdf';;
        a.click();
        window.URL.revokeObjectURL(url); // Clean up
      });
  }

  onDl_attestationParticipation() {
    this.dataService
      .getAttestationParticipation(this.Member.numero_personne, this.Stage.NumeroInstanceStage, this.Stage.NumeroInscriptionStage)
      .subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = "Dimension-Sport_AttestationParticipation_" + this.Member.nom + '_' + this.Member.prenom + '_Stage_' + moment(this.Stage.DateDebutInstanceStage).format('DD-MM-YYYY') + '_' + moment(this.Stage.DateFinInstanceStage).format('DD-MM-YYYY') + '.pdf';;
        a.click();
        window.URL.revokeObjectURL(url); // Clean up
      });
  }
}
