import {timer} from "rxjs";

export class UselessLoadingSkipper {
  /** délai entre chaque évenement pour ne pas saturer le serveur de requête continu si l'utilisateur click plusieurs fois.
   */
  private delay: number = 0;

  /** permet de skiper le chargement quand c'est pas nécessaire, dans le cas d'un désélection où on enlève les éléments
   * sans besoin de faire de requête pour chercher les informations que l'on a déjà.
   */
  private skipDelay = false;

  /** utile pour quand même envoyer la requête dans le cas d'une désélection si l'utilisateur a rapidement sélectionné et
   * sélectionné plusieur fois, ce qui a probablement entrainé des requêtes écrasées. Et donc le
   */
  private previousValue : {key: string, value: boolean} | null = null;

  /** utile dans le cas où l'utilisateur sélectionne et désélectionne continuellement pour tromper le flux d'évenement
   * et faire afficher des données incohérents, dans ce cas, le chargement est innévitable pour garder la cohérence.
   */
  private brokenTrust = false;

  /**
   * Détermine le timer de délai entre les requête
   */
  get timer() {
    return ()=>timer(this.skipDelay?0 : this.delay);
  }

  constructor(delay?: number) {
    this.delay = delay??0;
  }

  /**
   * Défini la skippabilité du chargement en fonction des états
   * @param v: valeur de l'évènement
   */
  defineSkipStatus(v: {key: any, value: boolean}) {
    if (v.value) {
      // this.loadingStatus.week = "loading";
      this.previousValue = v;
    } else {
      this.previousValue = this.previousValue??v;
      // skip seulement si il désélectionne ce qu'il vient de sélectionner
      this.skipDelay = v.key===(this.previousValue.key || v.value===this.previousValue.value) && !this.brokenTrust;
      this.previousValue = v;

      // la confiance ne peut êter que détruite, et ne revient plus à la valeur de départ comme brokenTrust = false
      // jusqu'au prochain cycle
      this.brokenTrust = !this.skipDelay||this.brokenTrust;
    }
  }

  /**
   * met fin au status du skip
   * @param v
   */
  endSkipStatus(v: {key: string, value: boolean}) {
    let tempSkip = !this.skipDelay;
    this.skipDelay = false;
    this.brokenTrust = false;

    if (v.value) {
      this.previousValue = null;
    }

    return tempSkip;
  }
}
