import { Component } from '@angular/core';

import {DataService} from "../../../service/data.service";
import {FileSaverService} from "ngx-filesaver";


@Component({
  selector: 'app-attestation-telechargement',
  templateUrl: './attestation-telechargement.component.html',
  styleUrls: ['./attestation-telechargement.component.css']
})
export class AttestationTelechargementComponent {
    private fileSaverService: FileSaverService;

  constructor(private dataService:DataService) {
    this.fileSaverService = new FileSaverService();
  }
  generatePdf() {
    this.dataService.getAttestationTEST().subscribe({
      next: (res: Blob) => {
        const file = new Blob([res], { type: 'application/pdf' });
        // const fileURL = URL.createObjectURL(file);
        this.fileSaverService.save(file, 'attestation.pdf'); // Requires file-saver library
      },
      error: (error) => {
        console.error('Error fetching PDF:', error);
      }
    });
  }

  generatePdfTest() {
    /// Ajouter du contenu au document PDF
    const content = `
      <h1>Attestation</h1>
      <p>Ceci est un exemple d'attestation.</p>
      <p>Vous pouvez ajouter autant d'éléments que nécessaire ici.</p>
    `;

    // Créer un blob avec le contenu
    const blob = new Blob([content], { type: 'application/pdf' });

    // Enregistrer le blob en tant que fichier
    this.fileSaverService.save(blob, 'attestation.pdf');
  }

}
