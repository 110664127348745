<section class="shadow-sm p-3 mb-3 bg-white rounded mx-3">
  <span class="field2-section d-block mb-3">Age</span>
  <div class="text-center">

    <!-- range-->
    <div class="age-selector">
      <span class="badge badge-secondary age-min mr-auto">{{ageRange.min}}</span>
      <!--        <button class="m-0 p-0">-->
      <!--        </button>-->
      <input id="slider1" type="text"/>
      <span class="badge badge-secondary age-max ml-auto">{{ageRange.max}}</span>
      <!--        <button class="btn m-0 p-0">-->
      <!--        </button>-->
    </div>
  </div>
</section>
