import {Component} from '@angular/core';
import {Member} from "../../../models/Member";
import {Router} from "@angular/router";
import {DataService} from "../../../service/data.service";
import {MedicalInformation} from "../../../models/MedicalInformation";
import {GestionCompteLoadingStatus} from "./GestionCompteLoadingStatus";
import {LoadingStatus} from "../../../models/FormLoadingStatus";

@Component({
  selector: 'app-gestion-compte',
  templateUrl: './gestion-compte.component.html',
  styleUrls: ['./gestion-compte.component.css']
})
// TODO : Inverser modification compte et gestion compte
export class GestionCompteComponent {
  isMenuOpen: boolean = false;
  protected members: Member[] = [];
  protected user = JSON.parse(localStorage.getItem('selectedCompte') || '{}');

  loadingStatus = new GestionCompteLoadingStatus();

  constructor(private router : Router, private dataService: DataService) {
    if (this.user == null) {
      this.router.navigateByUrl("/");
    }
  }

  lastname?: string;
  firstname?: string;
  email?: string;
  phoneNumber?: string;
  adress?: string

  async ngOnInit() {
    this.lastname = this.user.nom;
    this.firstname = this.user.prenom;
    this.phoneNumber = this.user.gsm;
    this.email = this.user.email;
    this.adress = `${this.user.rue}  ${this.user.numero}, ${this.user.code_postal}  ${this.user.localite} ` + this.user.pays.toUpperCase();

    await this.getFamilyMembers();

  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  onAccountEdit() {
    this.router.navigate(['/modification-compte']);
  }

  onMemberAdd() {
    this.router.navigate(['/creation-membre']);
  }

  onMemberSearch() {
     this.router.navigate(['/recherche-membre']);
  }

  getFamilyMembers() {
    return new Promise<void>((resolve, reject) => {
      this.dataService.getFamilyMembers(this.user.numero_compte).subscribe({
        next: (res:any) => {
          for(const element of res) {
            let member = new Member(
              element.NUMERO_PERSONNE,
              element.NOM,
              element.PRENOM,
              new Date(element.DATE_NAISSANCE),
              element.SEXE,
              element.GSM,
              element.COMMENTAIRES,
              element.REMARQUES,
              element.PROFESSION_PARENTS,
              element.FONCTION,
              element.SPORT_PRATIQUE,
              element.ECOLE,
              element.NUMERO_COMPTE,
              element.NUMERO_NATIONAL,
              element.EMAIL,
              // Obsolète... à supprimer
              new MedicalInformation(element.NOM_MEDECIN,
                element.GSM_MEDECIN, element.ANNEE_TETANOS,
                element.ALLERGIES, element.MEDICAMENTS,
                element.REMARQUES_MEDICALES),

              element.NOM_MEDECIN,
              element.GSM_MEDECIN,
              element.ANNEE_TETANOS,
              element.ALLERGIES,
              element.MEDICAMENTS,
              element.REMARQUES_MEDICALES,
            )
            this.members.push(member);
          }
          this.loadingStatus.member = LoadingStatus.LOADED;
          resolve();
        },
        error: (error) => {
          this.loadingStatus.member = LoadingStatus.ERROR;
          reject(error);
        }
      });
    });
  }

  onEditMember(member : Member) {
    sessionStorage.setItem('selectedMember', JSON.stringify(member));
    this.router.navigate(['/gestion-membre']);
  }

  protected readonly LoadingStatus = LoadingStatus;
}
