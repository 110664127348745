import {MedicalInformation} from "./MedicalInformation";

export class Member {

  numero_personne: number;
  nom: string;
  prenom: string;
  date_naissance: Date;
  sexe: string;
  gsm: string;
  commentaires: string;
  remarques: string;
  profession_parents: string;
  fonction: string;
  sport_pratique: string;
  ecole: string;
  numero_compte: number;
  numero_national: string;
  email: string;
  info_medicales?: MedicalInformation;
  nom_medecin: string;
  gsm_medecin: string;
  annee_tetanos: number;
  allergies: string;
  medicaments: string;
  remarques_medicales: string;


  constructor(numero_personne = 0, nom = '', prenom = '', date_naissance = new Date(),
              sexe = '', gsm = '', commentaires = '', remarques = '',
              profession_parents = '', fonction = '', sport_pratique = '',
              ecole = '', numero_compte = 0, numero_national = '', email = '',
              info_medicales: MedicalInformation = new MedicalInformation(),
              nom_medecin = '', gsm_medecin = '', annee_tetanos = -1,
              allergies = '', medicaments = '', remarques_medicales = '') {
    this.numero_personne = numero_personne;
    this.nom = nom;
    this.prenom = prenom;
    this.date_naissance = date_naissance;
    this.sexe = sexe;
    this.gsm = gsm;
    this.commentaires = commentaires;
    this.remarques = remarques;
    this.profession_parents = profession_parents;
    this.fonction = fonction;
    this.sport_pratique = sport_pratique;
    this.ecole = ecole;
    this.numero_compte = numero_compte;
    this.numero_national = numero_national;
    this.email = email;
    this.info_medicales = info_medicales;
    this.nom_medecin = nom_medecin;
    this.gsm_medecin = gsm_medecin;
    this.annee_tetanos = annee_tetanos;
    this.allergies = allergies;
    this.medicaments = medicaments;
    this.remarques_medicales = remarques_medicales;
  }


}
