import {Component, Input} from '@angular/core';
import {Router} from "@angular/router";
import {DataService} from "../../../../service/data.service";
import {Member} from "../../../../models/Member";
import {CourseRecap} from "../../../../models/CourseRecap";
import {StageRecap} from "../../../../models/StageRecap";
import {GestionMembreLoadingStatus} from "./GestionMembreLoadingStatus";
import {LoadingStatus} from "../../../../models/FormLoadingStatus";

@Component({
  selector: 'app-gestion-membre',
  templateUrl: './gestion-membre.component.html',
  styleUrls: ['./gestion-membre.component.css']
})
export class GestionMembreComponent {

  isMenuOpen: boolean = false;
  @Input() member!: Member;
  protected stageRecaps: StageRecap[] = [];
  protected courseRecaps: CourseRecap[] = [];
  loadingStatus = new GestionMembreLoadingStatus()

  constructor(private router : Router, private dataService: DataService) {

  }


  adress:any;
  async ngOnInit() {
    this.member = JSON.parse(sessionStorage.getItem("selectedMember") || '{}');
    await this.getMemberStages();
    await this.getMemberCourses();
    console.log("nb stages : " + this.stageRecaps.length)
    console.log("nb cours : " + this.courseRecaps.length)
  }

  onMemberEdit() {
    this.router.navigate(['/modification-membre']);
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  private async getMemberStages() {
    console.log("getMemberStages" + this.member.numero_personne);
    return new Promise<void>((resolve, reject) => {
      this.dataService.getMemberStages(this.member.numero_personne).subscribe({
        next: (res:any) => {
          for(const element of res) {
            let stageRecap = new StageRecap(
              element.NUMERO_INSCRIPTION_STAGE,
              element.NUMERO_PERSONNE,
              element.NUMERO_INSTANCE_STAGE,
              element.NUMERO_COMPTE,
              element.REDUCTION,
              element.ACOMPTE,
              element.ACOMPTE2,
              element.SOLDE,
              element.REMBOURSEMENT,
              element.DATE_DEBUT_INSTANCE_STAGE,
              element.DATE_FIN_INSTANCE_STAGE,
              element.NOM,
              element.NOM_LIEU,
              element.TYPE_STAGE,
              element.PRIX_JOURNEE,
              element.PRIX_DEMI_JOURNEE,
            )
            this.stageRecaps.push(stageRecap);
          }
          console.log("getMemberStages done")
          this.loadingStatus.stages = LoadingStatus.LOADED;
          resolve();
        },
        error: (error) => {
          this.loadingStatus.stages = LoadingStatus.ERROR;
          reject(error);
        }
      });
    });
  }

  private async getMemberCourses() {
    return new Promise<void>((resolve, reject) => {
      this.dataService.getMemberCourses(this.member.numero_personne).subscribe({
        next: (res:any) => {
          for(const element of res) {
            console.log("saison" + element.SAISON);
            let courseRecap = new CourseRecap(
              element.NUMERO_INSCRIPTION_COURS,
              element.NUMERO_PERSONNE,
              element.NUMERO_INSTANCE_COURS,
              element.NUMERO_COMPTE,
              element.PRIX,
              element.REDUCTION,
              element.ACOMPTE,
              element.ACOMPTE2,
              element.SOLDE,
              element.REMBOURSEMENT,
              element.NOM_COURS,
              element.JOUR_INSTANCE_COURS,
              element.HEURE_INSTANCE_COURS,
              element.DATE_DEBUT_INSTANCE_COURS,
              element.DATE_FIN_INSTANCE_COURS,
              element.NOM_LIEU,
              element.PERIODE,
              element.PRIX_ANNEE,
              element.PRIX_PREMIER_SEMESTRE,
              element.PRIX_SECOND_SEMESTRE,
              element.SAISON,
            )
            this.courseRecaps.push(courseRecap);
          }
          this.loadingStatus.courses = LoadingStatus.LOADED;
          resolve();
        },
        error: (error) => {
          this.loadingStatus.courses = LoadingStatus.ERROR;
          reject(error);
        }
      });
    });
  }

  protected readonly LoadingStatus = LoadingStatus;
}
