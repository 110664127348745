export class Paginator {
  private _quantite: number;
  private _cursor: number;
  private _total?: number;

  constructor(quantite: number, cursor: number, total?: number) {
    this._quantite = quantite;
    this._cursor = cursor;
    this._total = total;
  }

  public clearTotal() {
    this._total = 0;
  }

  public get quantite() {
    return this._quantite;
  }

  public get cursor() {
    return this._cursor;
  }

  public get total(): number|undefined {
    return this._total;
  }

  public get maxPages() {
    return Math.floor(((this.total??0) + this.quantite-1)/this.quantite);
  }

  public set total(value: number | undefined) {
    this._total = value;
  }

  public set cursor(value: number) {
    if (value > this.maxPages) {
      this._cursor = this.maxPages;
    } else if (value < 0) {
      this.cursor = 0;
    } else {
      this._cursor = value
    }
  }
}
