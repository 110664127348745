import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

//***********************************************
// scroll to top
//***********************************************
//  const toTop = document.querySelector(".scroll-to-top") as HTMLElement;
//  window.addEventListener("scroll", () => {
//    if (window.pageYOffset > 100) {
//      toTop.classList.add("active");
//    } else {
//      toTop.classList.remove("active");
//    }
//  });
//
// toTop.addEventListener("click", () => {
//   window.scrollTo({
//     top: 0,
//     behavior: "smooth"
//   });
// });
