import {LoadingStatus} from "../../../models/FormLoadingStatus";

const {
  LOADING,
  LOADED,
  TIME_OUT,
  NET_ERROR,
  ERROR
} = LoadingStatus;

export class StagesLoadingStatus {
  period: LoadingStatus = LOADING;
  week:   LoadingStatus = LOADED;
  place:  LoadingStatus = LOADED;
  stage:  LoadingStatus = LOADED;
}
