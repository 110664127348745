import {LOCALE_ID, NgModule, isDevMode} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {HashLocationStrategy, LocationStrategy, registerLocaleData} from "@angular/common";
import * as fr from '@angular/common/locales/fr'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StageDetailsComponent } from './components/pages/stage-details/stage-details.component';
import { HttpClientModule } from "@angular/common/http";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { CoursComponent } from './components/pages/cours/cours.component';
import { StagesComponent } from './components/pages/stages/stages.component';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MultiSelectComponent } from './components/common/multi-select/multi-select.component';
import { CourseCardComponent } from './components/common/course-card/course-card.component';
import { StageCardComponent } from './components/common/stage-card/stage-card.component';
import { HomeComponent } from './components/pages/home/home.component';
import { NavbarComponent } from "./components/common/navbar/navbar.component";
import { ContactComponent } from './components/pages/contact/contact.component';
import { CoursDetailsComponent } from './components/pages/cours-details/cours-details.component';
import { NotificationsComponent } from "./components/pages/notifications/notifications/notifications.component";
import { WebPushService } from './service/web-push.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ConfirmationMembreComponent } from './components/pages/membre/confirmation-membre/confirmation-membre.component';
import { ReturnLinkComponent } from './components/common/return-link/return-link.component';
import { PopupComponent } from './components/common/pop-up/pop-up.component';
// import {httpInterceptorProviders} from "./interceptors"; TODO securisation
import { AgeRangeComponent } from './components/common/age-range/age-range.component';
import { CheckListComponent } from './components/common/check-list/check-list.component';
import { RecordListComponent } from './components/common/record-list/record-list.component';
import { MultiselectBlockComponent } from './components/common/multiselect-block/multiselect-block.component';
import { ScrollToIconComponent } from './components/common/scroll-to-icon/scroll-to-icon.component';
import { PaginationComponent } from './components/common/pagination/pagination.component';
import { FloatingButtonComponent } from './components/common/floating-button/floating-button.component';
import { RadioListComponent } from './components/common/radio-list/radio-list.component';
import { PreferenceComponent } from './components/pages/inscription/stages/preference/preference.component';
import { PersonalInfoCardComponent } from './components/common/personal-info-card/personal-info-card.component';
import { MedicalInfoCardComponent } from './components/common/medical-info-card/medical-info-card.component';
import { FacturationComponent } from './components/common/facturation/facturation.component';
import { FIRSTNAMESurnamePipe } from './pipes/firstnamesurname.pipe';
import { SelectionMembreStageComponent } from './components/pages/inscription/stages/selection-membre-stage/selection-membre-stage.component';
import { SelectionMembreCoursComponent } from './components/pages/inscription/cours/selection-membre-cours/selection-membre-cours.component';
import { SelectionListComponent } from './components/common/selection-list/selection-list.component';
import { RechercheMembreComponent } from './components/pages/membre/recherche-membre/recherche-membre.component';
import { ConnexionComponent } from './components/pages/connexion/connexion.component';
import { FormGroupComponent } from './components/common/form-group/form-group.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from "@angular/material/input";
import { AuthService } from './service/auth.service';
import { ResetPasswordComponent } from "./components/pages/reset-password/reset-password.component";

import { ScrollUpComponent } from './components/common/scroll-up/scroll-up.component';
import { RadioInputComponent } from './components/common/radio-input/radio-input.component';
import { RecapitulatifComponent } from './components/pages/inscription/stages/recapitulatif/recapitulatif.component';
import { InscriptionConfirmeeComponent } from './components/pages/inscription/stages/inscription-confirmee/inscription-confirmee.component';
import { MatMenuModule } from "@angular/material/menu";
import { ErrorMessageComponent } from './components/common/error-message/error-message.component';
import { GestionCompteComponent } from './components/pages/gestion-compte/gestion-compte.component';
import { MemberEditFormComponent } from './components/common/member-edit-form/member-edit-form.component';
import { ModificationCompteComponent } from './components/pages/modification-compte/modification-compte.component';
import { RecapitulatifCoursComponent } from './components/pages/inscription/cours/recapitulatif-cours/recapitulatif-cours.component';
import { InscriptionConfirmeeCoursComponent } from './components/pages/inscription/cours/inscription-confirmee-cours/inscription-confirmee-cours.component';
import { PreferenceCoursComponent } from './components/pages/inscription/cours/preference-cours/preference-cours.component';
import { ModificationMembreComponent } from './components/pages/membre/modification-membre/modification-membre.component';
import { CreeMembreComponent } from './components/pages/membre/cree-membre/cree-membre.component';
import { FieldErrorDirective } from './components/common/directives/field-error.directive';
import { FieldErrorMessage } from "./components/common/directives/message-error.directive";
import { ErrorMessageForFieldComponent } from './components/common/error-message-for-field/error-message-for-field.component';
import { DlDateTimeDateModule, DlDateTimePickerModule } from "angular-bootstrap-datetimepicker";
import { ToastrModule } from 'ngx-toastr';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatDialogModule } from "@angular/material/dialog";
import { PopupModalComponent } from './components/common/popup-modal/popup-modal.component';
import { MatRadioModule } from "@angular/material/radio";
import { CreationCompteComponent } from './components/pages/creation-compte/creation-compte.component';
import { NationalNumberPipe } from './pipes/national-number.pipe';
import { AttestationTelechargementComponent } from './components/pages/attestation-telechargement/attestation-telechargement.component';
import { GestionMembreComponent } from './components/pages/membre/gestion-membre/gestion-membre.component';
import { StageCardRecapComponent } from './components/common/stage-card-recap/stage-card-recap.component';
import { CourseCardRecapComponent } from './components/common/course-card-recap/course-card-recap.component';
import { ConfirmationPopUpComponent } from './components/common/confirmation-pop-up/confirmation-pop-up.component';
import { ActivationCompteComponent } from './components/pages/activation-compte/activation-compte.component';
import {FileSaverModule} from 'ngx-filesaver';
import {FooterComponent} from "./components/common/footer/footer.component";
import {MatAutocompleteModule} from "@angular/material/autocomplete";

@NgModule({
  declarations: [
    AppComponent,
    StageDetailsComponent,
    CoursComponent,
    StagesComponent,
    MultiSelectComponent,
    CourseCardComponent,
    StageCardComponent,
    HomeComponent,
    ContactComponent,
    NavbarComponent,
    CoursDetailsComponent,
    NotificationsComponent,
    ReturnLinkComponent,
    AgeRangeComponent,
    CheckListComponent,
    RecordListComponent,
    MultiselectBlockComponent,
    ScrollToIconComponent,
    PaginationComponent,
    FloatingButtonComponent,
    ScrollToIconComponent,
    RadioListComponent,
    PreferenceComponent,
    PersonalInfoCardComponent,
    MedicalInfoCardComponent,
    FacturationComponent,
    FIRSTNAMESurnamePipe,
    SelectionMembreStageComponent,
    SelectionListComponent,
    RadioInputComponent,
    RecapitulatifComponent,
    InscriptionConfirmeeComponent,
    RechercheMembreComponent,
    ScrollUpComponent,
    ConnexionComponent,
    FormGroupComponent,
    ErrorMessageComponent,
    ConfirmationMembreComponent,
    GestionCompteComponent,
    PopupComponent,
    MemberEditFormComponent,
    SelectionMembreCoursComponent,
    ModificationCompteComponent,
    RecapitulatifCoursComponent,
    InscriptionConfirmeeCoursComponent,
    PreferenceCoursComponent,
    ModificationMembreComponent,
    CreeMembreComponent,
    FieldErrorDirective,
    FieldErrorMessage,
    ErrorMessageForFieldComponent,
    PopupModalComponent,
    CreationCompteComponent,
    ResetPasswordComponent,
    GestionMembreComponent,
    StageCardRecapComponent,
    CourseCardRecapComponent,
    ConfirmationPopUpComponent,
    ActivationCompteComponent,
    NationalNumberPipe,
    AttestationTelechargementComponent,
    FooterComponent,
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FontAwesomeModule,
        NgMultiSelectDropDownModule,
        FormsModule,
        RouterModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        BrowserAnimationsModule,
        MatCardModule,
        ReactiveFormsModule,
        MatInputModule,
        MatMenuModule,
        DlDateTimePickerModule,
        DlDateTimeDateModule,
        ToastrModule.forRoot({}),
        MatProgressSpinnerModule,
        MatDialogModule,
        MatRadioModule,
        FileSaverModule,
        MatAutocompleteModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })
    ],
  exports: [
    RouterModule
  ],
  providers: [
    WebPushService,
    AuthService,
    { provide: LOCALE_ID, useValue: 'fr-FR'},
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    // httpInterceptorProviders
    NationalNumberPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(fr.default)
  }
}
