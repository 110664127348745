<section class="shadow-sm p-2 pb-3 mb-5 bg-white rounded mx-3 d-flex flex-column" id="facturationMenu">
  <span class="field2-section d-block mb-2">Facturation</span>
  <div class="checkboxDiv">
    <label>Si vous avez besoin d'une facture, veuillez cocher cette case :</label>
    <input type="checkbox" class="checkbox" (click)="checkBox()" [checked]="this.selectedOption != -1">
  </div>
  <div *ngIf="this.isDisplayed" class="line-height">
    <hr>
<!--    <p>Sélectionnez dans le menu déroulant l'institution concernée</p>-->
    <p [ngClass]="{'d-none': !isStage}">Pour le personnel des TEC, sélectionnez TEC et ajoutez votre matricule dans le
      champ <i>Commentaire</i> ci-dessus. D'avance merci.</p>
    <div class="custom-select2" (click)="toggleSelect()">
      <div class="select-selected">{{ values.get(selectedOption) || this.defaultMessage }}</div>
      <div class="select-items" [class.show]="isSelectOpen">
        <div *ngFor="let option of options" (click)="callBoth(option)">
          {{ option }}
        </div>
      </div>
    </div>
  </div>
</section>
