import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Paginator} from "../../../models/Paginator";

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent {
  @Input() paginator!: Paginator;
  @Input() status!: {message : string, classes : string[], isValid : boolean, isLoading : boolean};
  @Output() onClick = new EventEmitter<number>();

  protected readonly Array = Array;
}
