export class StageDetails {
  id:number;
  name:string;
  description:string;
  dateStart:Date;
  dateEnd:Date;
  numberDays:number;
  hourStart:string;
  hourEnd:string;
  ageStart:number;
  ageEnd:number;
  price:number;
  halfDayPrice:number;
  remainingSlots:number;
  nomLieu:string;
  rue:string;
  numero:string;
  codePostal:string;
  localite:string;
  google:string;

  constructor(id:number = 0, name:string = "", description:string = "", dateStart: Date= new Date(),
              dateEnd:Date = new Date(), numberDays:number = 0, hourStart:string = "", hourEnd:string = "",
              ageStart:number = 0, ageEnd:number = 0, price:number = 0.00, halfDayPrice:number = 0.00,
              remainingSlots = -1, nomLieu:string = "", rue:string = "", numero:string = "",
              codePostal:string = "", localite:string = "", google:string = "") {
    this.id = id,
    this.name = name,
    this.description = description,
    this.dateStart = new Date(dateStart),
    this.dateEnd = new Date(dateEnd),
    this.numberDays = numberDays,
    this.hourStart = hourStart,
    this.hourEnd = hourEnd,
    this.ageStart = ageStart,
    this.ageEnd = ageEnd,
    this.price = price,
    this.halfDayPrice = halfDayPrice,
    this.remainingSlots = remainingSlots,
    this.nomLieu = nomLieu,
    this.rue = rue,
    this.numero = numero,
    this.codePostal = codePostal,
    this.localite = localite,
    this.google = google
  }
}
