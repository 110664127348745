<app-return-link
  [route]="'/gestion-compte'"
  [text]="'Compte'">
</app-return-link>

<h1 class="text-center">Membre DS</h1>


<div class="personal-info-container shadow-sm p-2 pb-3 mb-3 bg-white rounded mx-3 d-flex flex-column">
  <div class="menu-toggle" (click)="toggleMenu()">
    <span class="field2-section d-block mb-2">Informations personnelles</span>
    <span class="arrow" [class.up]="isMenuOpen">&#9660;</span>
  </div>
  <ul class="aboveList" [ngClass]="{'d-none':isMenuOpen}">
    <li>
      <label><i class="fa-regular fa-user"></i></label>
      <p>{{this.member.prenom + ' ' + this.member.nom}}</p>
    </li>
    <li>
      <label><i class="fa-regular fa-calendar"></i></label>
      <p>{{this.member.date_naissance | date:'dd/MM/yyyy'}}</p>
    </li>
  </ul>
  <ul class="menu" [class.active]="isMenuOpen">
    <li>
      <label><i class="fa-regular fa-user"></i></label>
      <p>{{this.member.prenom + ' ' + this.member.nom}}</p>
    </li>
    <li>
      <label><i class="fa-regular fa-calendar"></i></label>
      <p>{{this.member.date_naissance | date:'dd/MM/yyyy'}}</p>
    </li>
    <li>
      <label><i class="fa-solid fa-at"></i></label>
      <p>{{this.member.email}}</p>
    </li>
    <li>
      <label><i class="fa-solid fa-phone"></i></label>
      <p>{{this.member.gsm}}</p>
    </li>
    <li class="d-flex justify-content-center" [class.active]="isMenuOpen">
      <button class="button button-big-size blue text-decoration-none my-2 d-flex flex-column" (click)="onMemberEdit()">Modifier</button>
    </li>
  </ul>
</div>

<hr class="section-separator">

<!-- stages en cours -->
<h2 class="field-section text-center">Stages en cours </h2>
<span class="ds-message m-4">
  <span [ngClass]="{'spinner-border spinner-border-sm ': loadingStatus.stages === LoadingStatus.LOADING}">
  </span>
  <span *ngIf="loadingStatus.stages === LoadingStatus.LOADED && this.stageRecaps.length === 0">Ce membre n'est incrit à aucun stage actif</span>
</span>

<!-- RÉSULTAT STAGES-->
<app-stage-card-recap
  class="mb-3"
  *ngFor="let stage of this.stageRecaps"
  [Stage]="stage"
  [Member]="member">
</app-stage-card-recap>


<hr class="section-separator">

  <!-- cours inscrits -->
<h2 class="field-section text-center">Cours inscrits </h2>
<span class="ds-message m-4">
  <span [ngClass]="{'spinner-border spinner-border-sm ': loadingStatus.courses === LoadingStatus.LOADING}">
  </span>
  <span *ngIf="loadingStatus.courses === LoadingStatus.LOADED && this.courseRecaps.length === 0">Ce membre n'est incrit à aucun cours actif</span>
</span>

  <app-course-card-recap
    class="mb-3"
    *ngFor="let course of this.courseRecaps"
    [Course]="course">

  </app-course-card-recap>

  <br><br>


