import { Component, OnInit } from '@angular/core';
import { FooterService } from './footer.service';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  isVisible = false;

  constructor(private footerService: FooterService) { }

  ngOnInit(): void {
    this.footerService.getFooterVisibility().subscribe((visible: boolean) => {
      this.isVisible = visible;
    });
  }

  protected readonly environment = environment;
}
