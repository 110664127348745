import {Component, HostListener, OnInit} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ExternalLinkService} from "../../../external-link.service";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  visitedHomePage: boolean = false;
  showPopup: boolean = false;

  private externalLinkService: ExternalLinkService = new ExternalLinkService();

  ngOnInit() {
    this.visitedHomePage = sessionStorage.getItem('visitedHomePage') === 'true';
    if (!this.visitedHomePage) {
      sessionStorage.setItem('visitedHomePage', 'true');
    }
  }

  constructor(private router: Router){
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.applyBackgroundImage(event.url);
      }
    });
  }

  applyBackgroundImage(url: string) {
    if (url === '/' || url === '/home') {
      document.body.classList.add('background_home');
    } else {
      document.body.classList.remove('background_home');
    }
  }
    protected readonly window = window;



  // Click on app install button
  public promptEvent : any;

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e : any) {
    e.preventDefault();
    this.promptEvent = e;
  }

  public installPWA() {
    this.promptEvent.prompt();
    this.promptEvent.userChoice
      .then((choiceResult : any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
          // Pop-up de confirmation
          this.togglePopup();
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        this.promptEvent = null;
      });
  }

  public shouldInstall(): boolean {
    // console.log('shouldInstall')
    // console.log('Running standalone: ' + this.isRunningStandalone())
    return !this.isRunningStandalone() && this.promptEvent;
  }

  public isRunningStandalone(): boolean {
    return (window.matchMedia('(display-mode: standalone)').matches);
  }


  // External link
  openExternalLink(url: string) {
    const newWindow = window.open(url, '_blank');
    if (newWindow) {
      console.log('Window opened successfully');
      // The link opened successfully in a new window or tab.
    } else {
      console.log('Window blocked');
      // A pop-up blocker prevented the link from opening.
      // You can handle this case as needed.
    }
  }

  togglePopup(): void {
    this.showPopup = !this.showPopup;
  }

}
