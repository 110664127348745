//ENVIRONEMENTS
// Old method
// export const API_BASE_URL = 'http://127.0.0.1:8000/api/'; // Pour du local
// export const API_BASE_URL = 'http://localhost:8000/api/'; // Pour du local (alternative)
// export const API_BASE_URL = 'http://192.168.132.230:10202/api/'; // Pour le docker
// export const API_BASE_URL = '/api/'; // Pour le reverse-proxy
// export const API_BASE_URL = 'https://test.dimension-sport.be/mobile/api/'; // pour la prodTEST
// export const API_BASE_URL = 'https://dimension-sport.be/mobile/api/'; // pour la PRODUCTION

// automatc method
import {environment} from "../../environments/environment";

export const API_BASE_URL = environment.apiUrl;


//ROUTES
export const API_ROUTES = {
  test: API_BASE_URL + 'test',
  getInstanceCours: API_BASE_URL + 'getInstanceCours',
  getInstanceStages: API_BASE_URL + 'getInstanceStages',
  getAllPlaces: API_BASE_URL + 'getAllPlaces',
  getStagePlacesForSelectedWeek: API_BASE_URL + 'getStagePlacesForSelectedWeek',
  getCourseTypePlaces: API_BASE_URL + 'getCourseTypePlaces',
  getStageDates: API_BASE_URL + 'getStageDates',
  getStageDetails: API_BASE_URL + 'getStageDetails',
  getCoursDetails: API_BASE_URL + 'getCoursDetails',
  getFamilyMembers: API_BASE_URL + 'getFamilyMembers',
  getFullPersonne: API_BASE_URL + 'getPersonne',
  getAllPostCode: API_BASE_URL + 'getAllPostCode',
  getMedicalInformations: API_BASE_URL + 'getMedicalInformations',
  updateMedicalInformation: API_BASE_URL + "updateMedicalInformations",


  getPeriodOfDay: API_BASE_URL + 'getPeriodOfDay',
  getPeriodOfYear: API_BASE_URL + 'getPeriodOfYear',

  getParticipationsStage: API_BASE_URL + 'getParticipationsStage',
  getParticipationsCours: API_BASE_URL + 'getParticipationsCours',
  getParticipationsOfTheseMembers: API_BASE_URL + 'getParticipationsOfTheseMembers',

  PromotionAmountStage: API_BASE_URL + "PromotionAmountStage",
  PromotionAmountCours: API_BASE_URL + "PromotionAmountCours",

  addInscriptionStage: API_BASE_URL + 'addInscriptionStage',
  addInscriptionCours: API_BASE_URL + 'addInscriptionCours',

  sendInscriptionStageConfirmationMail: API_BASE_URL + 'sendInscriptionStageConfirmationMail',
  sendInscriptionCoursConfirmationMail: API_BASE_URL + 'sendInscriptionCoursConfirmationMail',

  getMember: API_BASE_URL + 'getMember',
  getPersonneContact: API_BASE_URL + 'getPersonneContact',
  linkUserToAccount: API_BASE_URL + 'linkUserToAccount',
  updateUserData: API_BASE_URL + 'updateUserData',
  updateMemberData: API_BASE_URL + 'updateMemberData',
  addMember: API_BASE_URL + 'addMember',
  createAccount: API_BASE_URL + 'createAccount',
  sendActivationMail: API_BASE_URL + 'sendActivationMail',
  sendResetPasswordMail: API_BASE_URL + 'sendResetPasswordMail',

  getMemberStages: API_BASE_URL + 'getMemberStages',
  getMemberCourses: API_BASE_URL + 'getMemberCourses',

  getAttestationFiscale: API_BASE_URL + 'getAttestationFiscale',
  getAttestationParticipation: API_BASE_URL + 'getAttestationParticipation',
  getAttestationMutuelle: API_BASE_URL + 'getAttestationMutuelle',
  getAttestationTEST: API_BASE_URL + 'getAttestationTEST',

  activateAccount: API_BASE_URL + 'activateAccount',
};
