<main>
  <div class="back_element mx-3">
    <i class="fa-solid fa-chevron-left"></i>
    <a href="#" class="link-secondary m-2 fw-bolder">Accueil</a>
  </div>

  <h1 class="text-center">Contact</h1>

  <div class="background_contact">
    <h2>Dimension sport asbl</h2>
    <h3>0454.684.926 (numéro d'entreprise)</h3>
    <img src="../../../../assets/images/logo_croco.png" alt="Logo de Dimension-Sport">
    <p></p>
    <h2>Nous contacter</h2>
    <ul class="contact_list">
      <li class="first_row"><strong><i class="fa-solid fa-location-dot"></i> &nbsp; Adresse : </strong>
        <ul>
          <li>
            <a
              class="grey_text" href="https://maps.google.com/maps?q=26+Rue+de+Verviers%2C+4651%2C+Battice%2C+Belgique">Rue
              de
              Verviers, 26 - 4651 Battice - Belgique</a>
          </li>
        </ul>
      </li>
      <li class="first_row"><strong><i class="fa-solid fa-phone"></i> &nbsp; Téléphone : </strong>
        <ul>
          <li><a class="grey_text" href="tel:+3287743550">087/74.35.50</a></li>
          <li><a class="grey_text" href="tel:+32497909709">0497/909.709</a></li>
        </ul>
      </li>
      <li class="first_row"><strong> <i class="fa-solid fa-envelope"></i> &nbsp; Email : </strong>
        <ul>
          <li><a class="grey_text" href="mailto:info@dimension-sport.be">info@dimension-sport.be</a></li>
        </ul>
      </li>
      <li class="first_row"><i class="fa-solid fa-credit-card"></i><strong> &nbsp; Compte bancaire : </strong>
        <ul>
          <li>BE23 0012 3458 6391</li>
          <li>BIC : GEBABEBB</li>
        </ul>
      </li>
      <li class="first_row"><strong><i class="fa-regular fa-clock"></i> &nbsp; Bureau : </strong>
        <ul>
          <li>Lundi - Vendredi : <strong>8:30 - 17:30</strong></li>
          <li>Samedi - Dimanche : <strong>Fermé</strong></li>
        </ul>
      </li>
    </ul>
    <p></p>


    <h2>Nous suivre</h2>
    <div class="networkLink">
      <a href="https://www.facebook.com/dimensionsport.be/" target="_blank"><i
        class="grey_text fa-brands fa-facebook-f fa-2xl"></i></a> &nbsp;
      <a href="https://twitter.com/dimsportasbl" target="_blank"><i
        class="grey_text fa-brands fa-twitter fa-2xl"></i></a>
    </div>

    <form [formGroup]="newsletterForm" (ngSubmit)="onSubmit()">
      <h3>Inscription à la newsletter</h3>
      <label for="YMP0">Adresse email</label>
      <div class="form-group">
        <input type="email" class="form-control" id="YMP0" formControlName="YMP0" placeholder="Veuillez entrer votre e-mail">
        <button type="submit" class="btn btn-primary"><i class="fa-solid fa-envelope"></i></button>
      </div>
    </form>

    <!--<h2>Offre d'emploi</h2>
    <ul>
      <li><a href="http://www.dimension-sport.be/doc/recherche-%C3%A9tudiants-DS.pdf">Moniteur sportif</a></li>
      <li><a href="http://www.dimension-sport.be/doc/recherche-%C3%A9tudiants-DS.pdf">Aide-moniteur</a></li>
    </ul>-->
  </div>
</main>
