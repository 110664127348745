import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

declare var $: any;

@Component({
  selector: 'app-multiselect-block',
  templateUrl: './multiselect-block.component.html',
  styleUrls: ['./multiselect-block.component.css', '../../common/styles/common.css']
})
export class MultiselectBlockComponent implements OnInit {
  @Input() initData!: {id: string, options?: object};
  @Input() status!: {message: string, classes: any[], isValid : boolean, isLoading : boolean};
  @Input() update$!: Observable<Map<string, boolean>>;
  @Output() onMultiselectClick = new EventEmitter<any>();
  protected values: Map<string, boolean> = new Map();

  // observable

  ngOnInit() {
    this.update$.subscribe((map) => {
      this.values = map;
      this.updateMultiselect();
    });
    $(document).ready(() => {
          $('#'+this.initData.id)
              .multiselect(this.initData.options);
    });
  }

  private updateMultiselect() {
    let arr: Object[] = [];
    [...this.values.keys()].forEach(key => {
      arr.push({label: key, value: key, selected: !!this.values.get(key)})
    });
/*    $('#' + this.initData.id)
      .multiselect('dataprovider', arr)
      .multiselect('setOptions',
      {
        nonSelectedText: this.values.size,
      }
    );*/

    $('#' + this.initData.id)
        .multiselect('dataprovider', arr)
        .multiselect('setOptions', {nbPlacesToShow: " (" + this.values.size + ")"});
  }

  protected readonly Array = Array;
}
