import * as G from './CoursGlobalValues';
import {CourseCard} from "../../../models/CourseCard";
import {AgeRange} from "../../common/age-range/age-range";
import {Day} from "../../../models/Day";

export class CoursModel {
  courseTypes           :Map<string, boolean>  = new Map();
  days                  :Map<string, boolean>  = new Map(); // multiselect // uniquement pour piscine
  places                :Map<string, boolean>  = new Map();
  courseTypePlaces      :Map<string, string[]> = new Map();
  ageRange              :AgeRange              = new AgeRange(G.AGE_MIN, G.AGE_MAX, G.AGE_MIN, G.AGE_MAX); // observé par le setter
  filteredCourses   :CourseCard[]              = [];
  isAutoApplyFilters    :boolean               = false;
  stagesCalledByButton :boolean                = false;
// TODO GÉRER LE CAS DE PISCINE OÙ LES JOURS DOIVENT ÊTRE SÉLECTIONNÉS

  /**
   * Vérifie si les filtres sont cohérents et peuvent être appliqués
   */
  isValidFilter(): boolean {
    return this.selectedType.length > 0
      && (this.selectedDays.length > 0 || this.selectedType !== 'Piscine')
      && this.selectedPlaces.length > 0;
  }

  get selectedType() {
    // désélectionne toute la liste sauf celui sélectionné (car bouton radio)
    return [...this.courseTypes.keys()].find(k => this.courseTypes.get(k) === true)??'';
  }

  set selectedType(type: string) {
    // désélectionne toute la liste sauf celui sélectionné (car bouton radio)
    [...this.courseTypes.keys()].forEach(k => {
      this.courseTypes.set(k, type === k);
    });

    if (type === 'Piscine') {
      this.days = this.getInitDaysMap();
    } else {
      this.days = new Map;
    }
  }

  get selectedPlaces() {
    return [...this.places.keys()].filter(value => this.places.get(value))
  };

  get selectedDays() {
    return [...this.days.keys()].filter(value => this.days.get(value))
  };

  getInitDaysMap() {
    let map = new Map<Day, boolean>();
    Object.values(Day).forEach(day => {
      map.set(day, false);
    })
    return map;
  }
}
