<main>
  <div class="back_element mx-3">
    <i class="fa-solid fa-chevron-left"></i>
    <a routerLink="/inscription.cours.preference" class="link-secondary m-1 fw-bolder">Etape 2/3 : Ajouter vos préférences</a>
    <!--TODO verifier si c'est bien la bonne méthode de rooting -->
  </div>

  <h1 class="text-center fw-bold mt-3" [innerHTML]="'Etape 3/3'"></h1>
  <h2 class="text-center">{{this.coursName}}</h2>

  <hr>

  <h2 class="text-center fw-bold mt-3">Valider l'inscription</h2>
  <section class="text-center mt-3" *ngIf="!isPageLoaded()">
    <br><br><br><br><br><br> <!-- c'est pas bien, je sais... -->
    <div [ngClass]="{'spinner-border spinner-border-sm ': true}"></div>
    <div>Chargement...</div>
  </section>
  <section *ngIf="isPageLoaded()">
    <section *ngIf="!this.isReservation">
      <section class="shadow-sm p-2 pb-3 mb-3 bg-white rounded mx-3 d-flex flex-column">
        <!--    Montant-->
        <p *ngIf="this.reduction != null">Vous bénéficiez d'une réduction de <b>{{this.reduction | currency:'EUR'}}</b>
          car vous avez déjà inscrit {{this.coursOriginPersonPrenom}} {{this.coursOriginPersonNom}} dans le cours {{this.coursOrigin?.name}} à la même période.</p>
        <section class="montant">
          <h3 class="fw-bold blue">Montant du Cours :</h3>
          <section>
            <p>{{this.priceWanted | currency:'EUR'}}</p>
          </section>
        </section>

        <!--    info montant-->
        <section>
          <p>
            - Montant à faire par versement sur le compte <b>BE23 0012 3458 6391</b>
          </p>
          <p>Avec en communication:</p>
          <section>
            <p class="encadre">
              {{this.coursName}} - {{this.selectedCours.day}} - {{this.selectedCours.hour}} : {{this.selectedMember.nom | uppercase}} - {{this.selectedMember.prenom}}
            </p>
            <p>- Soit par virement avant la 2ème séance de cours (conseillé)</p>
            <p>- Soit en liquide le premier jour du cours.</p>
          </section>
        </section>
      </section>
      <!--    divers-->
      <section class="shadow-sm p-2 pb-3 mb-3 bg-white rounded mx-3 d-flex flex-column">
        <h3 class="fw-bold blue">Informations</h3>
        <p>
          Nous vous attendrons le prochain <b>{{this.selectedCours.day}}</b> à <b>{{this.selectedCours.hour}}</b>.
          (en dehors des jours fériés et des vacances scolaires)
        </p>
      </section>
    </section>
    <section *ngIf="this.isReservation">
      <section class="solde shadow-sm p-2 pb-3 mb-3 bg-white rounded mx-3 d-flex flex-column">
        <h3 class="fw-bold blue">Inscription en liste d'attente</h3>
        <p>
          Nous prendrons en compte votre inscription comme réserve pour le stage <strong>{{this.coursName}}</strong> du <strong>{{this.selectedCours.dateStart1 | date :'dd-MM-YYYY'}}</strong> au <strong>{{this.selectedCours.dateEnd | date: 'dd-MM-YYYY'}}</strong>.
          <br>
          Vous figurerez sur la liste d'attente et vous serez prévenu dès qu'une place se libèrera.
        </p>
        <p>
          <u>Cliquez sur le bouton pour finaliser votre inscription comme réserve.</u>
        </p>
      </section>
    </section>
  </section>
  <app-floating-button *ngIf="isPageLoaded()"
    [text]="getButtonMessage()"
    (onClick)="handleForm()"
  >
  </app-floating-button>

</main>
