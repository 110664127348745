import {Component, Input} from '@angular/core';
import {StageCard} from "../../../models/StageCard";
import {Router} from "@angular/router";
import {DataService} from "../../../service/data.service";



@Component({
  selector: 'app-stage-card',
  templateUrl: './stage-card.component.html',
  styleUrls: ['./stage-card.component.css']
})
export class StageCardComponent {
  @Input() public Stage:StageCard

  constructor(private router: Router) {
    this.Stage = new StageCard;
    console.log("slots : ", this.Stage.RemainingSlots)
  }

  /**
   * When stage card clicked, redirect to stage details
   * @param Id id of the stage
   */
  onStageClick(Id: number) {
    // setTimeout(() => this.router.navigate(['/stages/', Id]), 500)
    // setTimeout(() => this.router.navigateByUrl(`facesnaps/${Id}`), 500)
    setTimeout(() => this.router.navigateByUrl(`stages/${Id}`), 500)
  }

  isAYoungChildStage(){
    return this.Stage.PrixDemiJournee != null && this.Stage.PrixDemiJournee != 0;
  }

  isPartiallyForceFilled(){
    return this.isAYoungChildStage() && (this.Stage.isFillForcedAm || this.Stage.isFillForcedPm)
  }

  isForceFilled() {
    return this.Stage.isFillForced || this.isPartiallyForceFilled();
  }

  isFilled(){
    return this.Stage.RemainingSlots <= 0 || this.isForceFilled();
  }

  isNearlyFilled() {
    return this.Stage.RemainingSlots <= 3 && this.Stage.RemainingSlots > 0;
  }
}
