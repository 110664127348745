<main>
  <div class="back_element mx-3">
    <i class="fa-solid fa-chevron-left"></i>
    <a routerLink="/inscription.cours.selection-membre-cours" class="link-secondary m-1 fw-bolder">Etape 1/3 : Sélectionner un membre</a> <!--TODO changer ce lien vers la partie 1 du formulaire-->
    <!-- Vers Choix Membre : Etape 1/3: Sélectionner un membre-->
  </div>

  <h1 class="text-center fw-bold mt-3" [innerHTML]="'Inscription : Etape 2/3'"></h1>
  <h2 class="text-center">{{this.stageName}}</h2>

  <hr>

  <h2 class="text-center fw-bold mt-3">Ajouter vos préférences</h2>

  <form>

    <!-- Partie FORMULE -->
    <section *ngIf="haveDayOption" class="shadow-sm p-2 pb-3 mb-3 bg-white rounded mx-3 d-flex flex-column"> <!-- Formule -->
      <!--            <p class="error-msg" *ngIf="this.message.has('periodeJournee')">Veuillez choisir une option</p>-->
      <app-radio-input
        [values]="typeCours"
        [title]="'Type de cours*'"
        (onElemClick)="updateChosenDayPeriod($event)"
        [withBorder]="false"

      >
      </app-radio-input>
      <!-- Copain (facultatif) -->
      <label for="fCopain">Copain (facultatif)</label>
      <input type="text" id="fCopain" [(ngModel)]="fCopain" name="fCopain" placeholder="DUPONT Jacques">

      <label for="fCommentaire" class="mt-3">Commentaire</label>
      <input type="text" id="fCommentaire" [(ngModel)]="fCommentaire" name="fCommentaire">
    </section>

    <!-- Partie informations personnelles -->
    <section class="shadow-sm p-2 pb-3 mb-3 bg-white rounded mx-3 d-flex flex-column">
      <app-personal-info-card
        [member]="personnalInfos"
      >

      </app-personal-info-card>
    </section>


    <section class="shadow-sm p-2 pb-3 mb-3 bg-white rounded mx-3 d-flex flex-column"> <!-- Formule -->
      <label for="fCommentaire" class="mt-3">Commentaire</label>
      <textarea id="Commentaire" [(ngModel)]="commentaire" name="commentaire"></textarea>
    </section>

    <app-facturation
      [values]="payementTypes"
      [isStage]="false"
      [isRequired]="true"
      [selectedOption]="getSelectedPayementTypes()"
      (onElemClick)="updateFacturationOption($event)"
    >

    </app-facturation>

    <app-error-message
      [errorMsg]="this.message"
      (close)="togglePopup()"
      *ngIf="this.showPopUp"
    >
    </app-error-message>

    <app-floating-button
      [text]="'Vers étape 3/3 &#8594;'"
      [isGreen]="false"
      (onClick)="handleForm()"
    >
    </app-floating-button>
  </form>
</main>
