<!--bouton retour-->
<app-return-link
  [text]="returnName"
  [route]="selectedLink">
</app-return-link>

<h2 class="text-center font-weight-bold mt-4">Rechercher un membre</h2>

<!--FORMULAIRE-->
<form *ngIf="searchForm" novalidate class="m-3 needs-validation" [formGroup]="searchForm" (ngSubmit)="validateForm()">

  <ng-container *ngTemplateOutlet="field; context:
    {name : 'nom', placeholder: 'Ex : DUPONT'}" />

  <ng-container *ngTemplateOutlet="field; context:
    {name : 'prenom', placeholder: 'Ex : Jean'}" />

  <ng-container *ngTemplateOutlet="field; context:
    {name : 'dateNaissance', label: 'date de naissance', type: 'date'}" />

  <br/><br/>

  <!--BOUTON ENVOYER-->
  <app-floating-button text="Rechercher" [disabled]="submitted && (!searchForm.valid || buttonStatus === 'loading')"/>

  <p class="text-center" style="margin-top: -2em">
    Je n'ai pas trouvé ? <a routerLink="/creation-membre" class="text-decoration-none" >Créer un nouveau membre</a>
  </p>

  <!--|=========|-->
  <!--|TEMPLATES|-->
  <!--|=========|-->

  <!--CHAMPS-->
  <ng-template #field let-name="name" let-type="type" let-label="label" let-placeholder="placeholder"><div class="form-group">
    <label [for]="name" class="fw-normal">{{(label??name)[0].toUpperCase() + (label??name).slice(1)}}</label>

    <input [type]="type" [id]="name" [formName]="name"
           [formControlName]="name" [appFieldError]="searchForm.get(name)!"
           [placeholder]="placeholder??''" >
    <app-error-message-for-field [control]="searchForm.get(name)!" [submitted]="submitted"/>
    <!--        <pre>{{registerForm.get(name)!.errors | json}}</pre>-->
  </div></ng-template>

</form>
