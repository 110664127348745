import {AfterViewInit, Component, OnInit} from '@angular/core';
import {DataService} from "../../../service/data.service";
import {ActivatedRoute, Router} from "@angular/router";
import {StageDetails} from "../../../models/StageDetails";
import {StagesStatusProvider} from "../stages/StagesStatusProvider";
import * as he from "he";
import {formatNumber} from "@angular/common";

@Component({
  selector: 'app-stage-details',
  templateUrl: './stage-details.component.html',
  styleUrls: ['./stage-details.component.css',
  '../../common/styles/common.css']
})

export class StageDetailsComponent implements OnInit, AfterViewInit {
  id!:number
  stage: StageDetails = new StageDetails();
  readMore: boolean = false;
  initialized = false;

  constructor(private dataService:DataService, private route: ActivatedRoute, private router:Router) {

  }

  async ngOnInit() {
    // TODO gérer le cas d'un id incorrecte ou innaccessible
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    await this.getStageData();
  }

  async ngAfterViewInit() {
  }

  onReadMore() {
    this.readMore = !this.readMore
  }

  // TODO METHOD TEMP car il y a une colonne nombre_jour dans la BD
  getNumberOfDays() {
    const differenceInMilliseconds = this.stage.dateEnd.getTime() - this.stage.dateStart.getTime();
    return Math.round(differenceInMilliseconds / (1000 * 60 * 60 * 24) + 1);
  }

  async getStageData(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataService.getStageDetails(this.id).subscribe({
        next: (res:any) => {

          this.stage = new StageDetails(
            this.id,
            he.decode(res.NOM),
            res.DESCRIPTIF,
            new Date(res.DATE_DEBUT),
            new Date(res.DATE_FIN),
            res.NOMBRE_JOURS,
            res.HEURE_DEBUT,
            res.HEURE_FIN,
            res.AGE_MIN,
            res.AGE_MAX,
            res.PRIX_JOURNEE,
            res.PRIX_DEMI_JOURNEE,
            res.REMAINING_SLOTS,
            res.NOM_LIEU,
            res.RUE,
            res.NUMERO,
            res.CODE_POSTAL,
            res.LOCALITE,
            res.GOOGLE
          )
          this.initialized = true
          resolve();
        },
        error: (error) => {
          reject(error);
        }
      });
    });
  }

  OnRegister() {
    sessionStorage.setItem('selectedStage', JSON.stringify(this.stage));
    if(localStorage.getItem('selectedCompte')){
      this.router.navigateByUrl(`inscription.stages.selection-membre-stage`);
    }else{
      this.router.navigateByUrl(`connexion`);
    }
  }

  ifNullHalfDayPrice() {
    return Number(this.stage.halfDayPrice) <= 0;
  }

  protected readonly StageStatus = StagesStatusProvider;


  protected readonly encodeURIComponent = encodeURIComponent;
    protected readonly formatNumber = formatNumber;
}
