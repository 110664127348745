
<app-navbar></app-navbar>
<!--{{getUrl()}}-->
<router-outlet></router-outlet>

<app-scroll-up></app-scroll-up>

<app-footer></app-footer>


<app-popup *ngIf="showPopupOrientation" [title]="'Orientation'" [message]="'L\'application PWA a été créée pour être utilisée sur un téléphone positioné à la verticale.'" (close)="togglePopupOrientation()"></app-popup>
<app-popup *ngIf="showPopupIOS" [title]="'IOS'" [message]="iosMessage" (close)="togglePopupIOS()"></app-popup>
<app-popup *ngIf="showPopupSafari" [title]="'Safari'" [message]="safariMessage" (close)="togglePopupSafari()"></app-popup>
<app-popup *ngIf="showPopupAndroid" [title]="'Android'" [message]="androidMessage" (close)="togglePopupAndroid()"></app-popup>
<app-popup *ngIf="showPopupOthers" [title]="'Autres'" [message]="othersMessage" (close)="togglePopupOthers()"></app-popup>
