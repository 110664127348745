import { Component, Input } from '@angular/core';
import { EventEmitter, Output } from '@angular/core';
import {SelectionMember} from "../../../models/SelectionMember";
import {DataService} from "../../../service/data.service";


@Component({
  selector: 'app-selection-list',
  templateUrl: './selection-list.component.html',
  styleUrls: ['./selection-list.component.css']
})
export class SelectionListComponent {
  @Input() items: any[]; // items sera un tableau d'éléments à afficher dans la liste.
  @Input() age: any;
  @Input() alreadyBusy:  { [key: number]: boolean } = {};
  @Output() selectedMember = new EventEmitter<SelectionMember>();

  constructor(private dataService: DataService) {
    this.items = [];
  }

  agesInRange:  { [key: number]: boolean } = {};




  isAgeInRange(itemId: number, itemBirthday: Date): boolean {
    if(this.agesInRange[itemId] !== undefined) {
      return this.agesInRange[itemId];
    }
    // console.log(itemBirthday);
    const ageMin:number = parseFloat(this.age.min);
    const ageMax:number = parseFloat(this.age.max);
    // console.log('AgeMin : ' + ageMin);
    // console.log('AgeMax : ' + ageMax);

    const today = new Date();
    // const itemAge = today.getFullYear() - itemBirthday.getFullYear();
    let itemAge = today.getTime() - itemBirthday.getTime();
    const millisecondsInYear = 1000 * 60 * 60 * 24 * 365.25;
    // console.log(itemAge / millisecondsInYear);
    itemAge /= millisecondsInYear;

    const isAgeInRange = itemAge >= ageMin - 0.25 && itemAge <= ageMax + 0.25;
    // console.log('Seuil Min : ' + (ageMin - 0.25));
    // console.log('Seuil Max : ' + (ageMax + 0.25));

    this.agesInRange[itemId] = isAgeInRange;
    return isAgeInRange;

  }

  isAlreadyBusy(itemId: number): boolean {
    return this.alreadyBusy[itemId];
  }



  handleSelection(event: any) {
    // Find the selected item in the items array
    const selectedItem = this.items.find((item) => item == event.target.value);
    this.selectedMember.emit(selectedItem);
  }

}

