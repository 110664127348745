<div class="back_element mx-3">
  <i class="fa-solid fa-chevron-left"></i>
  <a href="#" class="link-secondary m-2 fw-bolder">Accueil</a>
</div>

<h1 class="text-center fw-bold">Notifications</h1>

<!--<div><button (click)="installApp()">Installer l'app</button></div>
<div>&nbsp;</div>-->
<div *ngIf="!isNotificationPermissionGranted"><button (click)="requestPermission()">Request Notification Permission</button></div>
<div *ngIf="isNotificationPermissionGranted">Notifications déjà permises</div>
<div>&nbsp;</div>
<div><button (click)="subscribe()">Subscribe to Push Notifications</button></div>
<div>&nbsp;</div>
<div><button (click)="sendNotification()">Send Web Push Notification</button></div>
<div>&nbsp;</div>
<div><button (click)="localNotification()">Local Notification</button></div>
<div>&nbsp;</div>
<form #notificationForm="ngForm">
  <div>
    <label for="notificationTitle">Titre :</label>
    <input type="text" id="notificationTitle" name="notificationTitle" [(ngModel)]="notification.title" required>
  </div>
  <div>
    <label for="notificationBody">Corps : </label>
    <textarea id="notificationBody" name="notificationBody" [(ngModel)]="notification.body" required></textarea>
  </div>
</form>
