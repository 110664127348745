export class SelectionMember {
  numero_personne: number;
  nom: string;
  prenom: string;
  date_naissance: Date;

  constructor(numero_personne = 0, nom = '', prenom = '', date_naissance = new Date()) {
    this.numero_personne = numero_personne;
    this.nom = nom;
    this.prenom = prenom;
    this.date_naissance = date_naissance;
  }

  toString() {
    return this.nom + " " + this.prenom;
  }

  getAge() {
    let ageDifMs = Date.now() - this.date_naissance.getTime();
    let ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
}
