<div class="card shadow p-3">
  <div class="d-flex justify-content-center">
    <label class="fw-bold field mb-2 mx-auto" [innerHTML]="Stage.NomStage"></label>
  </div>
  <div class="row align-items-start">

    <div class="col-12">
      <Strong>Lieu: </Strong><span>{{Stage.Lieu}}</span>
    </div>
    <div class="col-12">
      <Strong>Date: </Strong><span>du {{Stage.DateDebutInstanceStage | date:'dd/MM/yyyy'}}
                                    au {{Stage.DateFinInstanceStage | date:'dd/MM/yyyy'}}</span>
    </div>

    <div class="col-12">
      <Strong>Reste à payer : </Strong><span>{{this.prix | currency:'EUR':'symbol':'1.2-2'}}</span>
    </div>

    <div class="mt-3 d-flex justify-content-center">
      <button class="button button-medium-size blue text-decoration-none" (click)="onStageClick()">
        Détails <i class="fa-solid fa-arrow-right"></i>
      </button>
    </div>

<!--    boutons pour les attestations-->
    <div class="d-flex flex-column gap-3 mx-auto mt-3"> <!-- TODO mettre la condition de l'apparition des boutons ici -->
      <div class="d-flex justify-content-center mx-auto">
        <button class="button button-big-size shadow" (click)="onDl_attestationFiscale()">
          <label class="text-black text-decoration-none mr-auto mt-auto mb-auto p-2">Attestation fiscale</label>  <img src="assets/images/DlPngImage.png" alt="Download pdf icon" class="ml-auto icone_pdf">
        </button>
      </div>

      <div class="d-flex justify-content-center mx-auto">
        <button class="button button-big-size shadow" (click)="onDl_attestationMutuelle()">
          <label class="text-black text-decoration-none mr-auto mt-auto mb-auto p-2">Attestation mutuelle</label>  <img src="assets/images/DlPngImage.png" alt="Download pdf icon" class="ml-auto icone_pdf">
        </button>
      </div>

      <div class="d-flex justify-content-center mx-auto">
        <button class="button button-big-size shadow" (click)="onDl_attestationParticipation()">
          <label class="text-black text-decoration-none mr-auto mt-auto mb-auto p-2">Attestation de participation</label>  <img src="assets/images/DlPngImage.png" alt="Download pdf icon" class="ml-auto icone_pdf">
        </button>
      </div>
    </div>
  </div>
</div>

