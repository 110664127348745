import {Component, Input} from '@angular/core';
import {StageRecap} from "../../../models/StageRecap";
import {Router} from "@angular/router";
import {CourseRecap} from "../../../models/CourseRecap";

@Component({
  selector: 'app-course-card-recap',
  templateUrl: './course-card-recap.component.html',
  styleUrls: ['./course-card-recap.component.css']
})
export class CourseCardRecapComponent {
  @Input() public Course:CourseRecap

  prix: number = 0;
  constructor(private router: Router) {
    this.Course = new CourseRecap();
  }

  ngOnInit() {
    let Reduction = this.isNotNull(this.Course.Reduction);
    let Remboursement = this.isNotNull(this.Course.Remboursement);
    let Acompte = this.isNotNull(this.Course.Acompte);
    let Acompte2 = this.isNotNull(this.Course.Acompte2);

    if(this.Course.Periode === "Année"){
      this.prix = this.Course.PrixAnnee;
    }else {
      if (this.Course.Periode === "Année continuée") {
        this.prix = this.Course.PrixPremierSemestre + this.Course.PrixSecondSemestre;
      } else {
        this.prix = this.Course.PrixPremierSemestre;
      }
    }

    this.prix -= (Reduction - Remboursement + Acompte + Acompte2);
  }

  isNotNull(param :any){
    if (param === null) return 0;
    if (param === "null") return 0;
    if (param === "NULL") return 0;
    return param;
  }

  /**
   * When stage card clicked, redirect to stage details
   * @param Id id of the stage
   */
  onStageClick(Id: number) {
    // setTimeout(() => this.router.navigate(['/stages/', Id]), 500)
    // setTimeout(() => this.router.navigateByUrl(`facesnaps/${Id}`), 500)
    // setTimeout(() => this.router.navigateByUrl(`stages/${Id}`), 500)
  }

  protected readonly CourseRecap = CourseRecap;
}
