<div id="home"  class="home-style" [ngStyle]="{'min-height': window.innerHeight-80-100 + 'px'}">

  <!-- Liens  vers les autres pages, sous forme de menu-->
  <div id="logo-container" class="logos" [ngClass]="{'logo-animated': !visitedHomePage}">
    <img src="assets/images/logo_croco.png" alt="Logo Dimension-Sport" class="logo_image">
  </div>

  <section id="homeButtons" class="d-flex flex-column justify-content-center" [ngClass]="{'buttons-animated': !visitedHomePage}" style="margin-top: auto">
    <div class="buttons">
      <a routerLink="/stages" class="text-decoration-none shadow-lg"><button class="button blue">Stages<i class="fa-solid fa-arrow-right"></i></button></a>
      <a routerLink="/cours" class="text-decoration-none shadow-lg"><button class="button orange">Cours<i class="fa-solid fa-arrow-right"></i></button></a>
      <a routerLink="/contact" class="text-decoration-none shadow-lg"><button class="button black">Contacts<i class="fa-solid fa-arrow-right"></i></button></a>
      <button *ngIf="shouldInstall()" class="install button green" (click)="installPWA()">Installer<i class="fa-solid fa-arrow-right"></i></button>
      <a (click)="openExternalLink('https://www.dimension-sport.be')" class="text-decoration-none shadow-lg"><button class="button white">Site web<i class="fa-solid fa-arrow-right"></i></button></a>
      <a class="text-decoration-none shadow-lg"><button (click)="testNotification()" class="button black">Test Notif 2</button></a>
    </div>
<!--    <button (click)="togglePopup()">Open Popup</button>-->
    <app-popup *ngIf="showPopup" [title]="'Application en cours d\'installation'" [message]="'Lorsque l\'installation aura fini, vous pourrez fermer cette fenêtre et utiliser directement votre application PWA.'" (close)="togglePopup()"></app-popup>

<!--    <p>Lien temporaire pour les notifications : &nbsp;
      <a routerLink="/notifications">Notifications</a>
    </p>-->
  </section>
</div>


