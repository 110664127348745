import {AfterViewInit, Component, ElementRef, NgZone, OnInit, Renderer2, ViewChild} from '@angular/core';
import {DataService} from "../../../service/data.service";

import {containsDate, convertDateFormat, getSelectionableWeeks, Week} from "../../../models/Week";
import {getCurrentAndNextPeriode, PeriodeDate} from "../../../models/PeriodeDate";
import {StagesFiltersLocalStorage} from "./StagesFiltersLocalStorage";
import {StagesObserverUtil} from "./StagesObserverUtil";
import {StagesStatusProvider} from "./StagesStatusProvider";
import {StagesEvent} from "./StagesEvent";


// import variables globaux par défaut
import {StagesModel} from "./StagesModel";
import {StagesStream} from "./StagesStream";
import {Subject, take} from "rxjs";
import {StagesLoadingStatus} from "./StagesLoadingStatus";
import {Periode} from "../../../models/Periods";
import {LoadingStatus} from "../../../models/FormLoadingStatus";
import {REQUEST_DELAY} from "./StagesGlobalValues";
import {Paginator} from "../../../models/Paginator";
import {stagesBasic} from "./interfaces";

const {LOADING, LOADED, TIME_OUT, NET_ERROR, ERROR} = LoadingStatus;
declare var $: any;

@Component({
  selector: 'app-stages',
  templateUrl: './stages.component.html',
  styleUrls: ['./stages.component.css', '../../common/styles/common.css']
})

export class StagesComponent implements OnInit, AfterViewInit, stagesBasic {
  @ViewChild('periodsInput', { static: false }) periodsInput!: ElementRef;
  @ViewChild('weeksInput', { static: false }) weeksInput!: ElementRef;

// _____________________________________________________________________________________________________________________
// ATTRIBUTS -----------------------------------------------------------------------------------------------------------
  // MAIN ATRIBUTS
  Model: StagesModel = new StagesModel();
  protected Stream: StagesStream = new StagesStream();
  loadingStatus = new StagesLoadingStatus();
  stagesStatusProvider: StagesStatusProvider = new StagesStatusProvider({model: this.Model, loadingStatus: this.loadingStatus});
  // pagination : quantité : nombre de résultat par page;   cursor : index de la page;  total : nombre total de résultat
  protected paginator: Paginator = new Paginator(4, 0);

  // Autres variables
  resetFilters$: Subject<void> = new Subject<void>();
  multiselectUpdate$: Subject<Map<string, boolean>> = new Subject();
  protected multiselectInilialiser:{id: string, options?: object} = { // données d'initialisation du composant multiselect
    id: "stage-place-multiselect",
    options: {
      includeSelectAllOption: true,
      enableFiltering: true,
      enableCaseInsensitiveFiltering: true,
  }};





// _____________________________________________________________________________________________________________________
// INIT + ACCESSOR -----------------------------------------------------------------------------------------------------
  constructor(public dataService:DataService, public zone: NgZone, private renderer: Renderer2) {}

  ngOnInit() {
    this.loadPeriods(); // calcule les périodes à afficher
    StagesObserverUtil.initPeriodObserver.call(this); // observe les filtres pour charger les periods
    StagesObserverUtil.initWeekObserver.call(this); // observe les filtres pour charger les semaines
    StagesObserverUtil.initplacesObserver.call(this); // observe les filtres pour charger les places
    StagesObserverUtil.initStagesObserver.call(this); // observe les filtres pour charger les stages
  }

  ngAfterViewInit() {
    // permet de scroll vers le début de la page au démarage.
    setTimeout(() => $('body')[0].scrollIntoView({ behavior: 'auto', block: 'start' }), 500)
  }

  // Récupère les périodes à proposer à l'utilisateur
  private getPeriodes(): PeriodeDate[] {
    return getCurrentAndNextPeriode(new Date()); // [PeriodeDate, PeriodeDate] ex : [été, toussain]
  }

  // récupère toutes les semaines des 2 périodes disponibles
  private getPeriodWeeks(): Week[] {
    return getSelectionableWeeks([...this.Model.periods.keys()]);
  }

  /**
   * Il ne sert à rien d'afficher la période s'il ne contient pas de stages.
   */
  private showPeriodIfUseful(periods: Periode[]) {
    const weeksOfPeriods = getSelectionableWeeks(periods);
    let firstWeek = convertDateFormat(weeksOfPeriods[0].startDate);
    let lastWeek = convertDateFormat(weeksOfPeriods[weeksOfPeriods.length-1].endDate);

    // requête envoyé vers laravel + abonné au flux pour être à jours sur les semaines.
    this.dataService.getStagesDates(firstWeek, lastWeek)
      .pipe(take(1))
      .subscribe(
        {
          next: (res) => {
            const dates = Array.isArray(res) ? res : [res];
            if (dates.length == 0) {
              this.loadingStatus.period = LoadingStatus.LOADED;
              return;
            }
            periods.map(p => {
              let weeks = getSelectionableWeeks([p]);
              return [weeks.at(0)!.startDate, weeks.at(-1)!.endDate, p];
            })
            .forEach(([start, end, periode]) => {
              let periodHasStages = dates.some((d) => {
                const dateStart = new Date(start);
                const dateEnd = new Date(end);
                const dateR = new Date(d);

                // if date is between
                return dateR.getTime() > dateStart.getTime() && dateR.getTime() < dateEnd.getTime();
              })
/*                if (periodHasStages) {*/ // TODO : à réparer
                this.Model.periods.set(periode, false);
/*                }*/
            })

            StagesFiltersLocalStorage.loadLocalFilters(this, this.Model).then(
              () => this.Stream.filteredStages$.next('start-event')
            ); // charge les filtres enregistrés en local s'ils existent

            this.loadingStatus.period = LOADED;
          },
          error: (error) => {
            this.loadingStatus.period = ERROR;
            if (error.name === 'TimeoutError') {
              this.loadingStatus.period = TIME_OUT;
            }
            if (error instanceof TypeError) {
              this.loadingStatus.period = NET_ERROR;
            }
          }
        });
  }

  /**
   * Efface la liste des stages dans la vue, utilisé lors d'un chargement pour charger une nouvelle liste.
   */
  clearStages() {
    this.zone.run(() => {
      this.Model.filteredStages = [];
    });
  }

  /**
   * Calcule la période actuelle et la période suivante sur base d'aujourd'hui et fournit les 2 périodes correspondants.
   * Les résultats sont affectés à l'attribut {this.Periodes} => ce qui affiche dinamiquement les périodes.
   * @private
   */
  loadPeriods() {
    this.loadingStatus.period = LOADING;
    const periods = this.getPeriodes();
    const results = periods
      .filter( p => getSelectionableWeeks([p.periode]).length !== 0)
      .map(p => p.periode);
    this.showPeriodIfUseful(results);
  }

  /**
   * Initialise les semaines :
   *  1) d'abord recherche les dates de stage
   *  2) puis recherche les semaines sélectionnés qui concernent les stages
   *  3) transforme la liste pour supprimer les doublons
   *  4) trie la liste
   *  5) et après l'initialisation des lieux est lancé
   * @param periods
   */
  async loadWeeks(periods: string[]){
    this.loadingStatus.week = LOADING;

    // retourne les dates des stages de la période spécifié, le status du message est mis à jours
    await this.fetchWeeks(periods);

    //on prend les semaines des 2 périodes disponibles et on filtre ces semaines pour ne garder
    //uniquement les semaines de/des période(s) sélectionnée(s).
    //on filtre ensuite les semaines en ne gardant que celles pour lesquelles il existe au moins 1 stage
    let weeks = this.getPeriodWeeks()
      .filter(week => this.Model.periods.get(week.periodeDate.periode))
      .filter(week => {
        let check = false;
        this.Model.stagesDates.forEach(date => {
          if(containsDate(date, week)){
            check = true;
          }
        })
        return check;
      })

    //on ajoute les semaines récupérées dans la map s'ils ne sont pas dans la liste, pour éviter les doublons
    weeks.forEach(week => {
      let found = false;
      for (const [existingWeek, _] of this.Model.weeks.entries()) {
        if (existingWeek.name === week.name) {
          found = true;
          break;
        }
      }
      if (!found) {
        // recherche dans la mémoire local si telle semaine a été sélectionnée précédement pour le resélectionner
        let localWeeks = StagesFiltersLocalStorage.loadFromLocal()[2];
        this.Model.weeks.set(week, false);
        [...localWeeks?.keys()||[]].some((localWeek) => {
          if (localWeek.name === week.name) {
            this.Model.weeks.set(week, localWeeks?.get(localWeek)||false)
          }
        })
      }
    });

    //on trie la map par semaines croissante
    const sortedEntries = [...this.Model.weeks.entries()].sort((a, b) => {
      const startDateA = new Date(convertDateFormat(a[0].startDate));
      const startDateB = new Date(convertDateFormat(b[0].startDate));
      return startDateA.getTime() - startDateB.getTime();
    });
    this.Model.weeks = new Map(sortedEntries);
    await this.loadPlaces([...this.Model.weeks.keys()].filter((w) => this.Model.weeks.get(w)))
  }

  /**
   * Recherche les lieux de stages sur base des semaines fournis, ce qui permet d'initialiser la liste des lieux avec
   * de nouvelles données. L'état est mise à jour pour afficher des messages comme le chargement, ... après que la
   * requête renvoie une réponse via l'abonnement.
   * @param weeks
   * @private
   */
  async loadPlaces(weeks: Week[]) {
    if (weeks.length == 0) return;
    this.loadingStatus.place = LOADING;
    await new Promise<void>((resolve, reject) => {
      this.dataService.getStagePlaces(weeks)
        .subscribe(
          StagesObserverUtil.getSubscribePlaces.call(this, weeks, resolve, reject));
    })
      .then(() => this.loadingStatus.place = LOADED)
      .catch(error => {
        this.loadingStatus.place = ERROR;
        if (error.name === 'TimeoutError') {
          this.loadingStatus.place = TIME_OUT;
        }
        if (error instanceof TypeError) {
          this.loadingStatus.place = NET_ERROR;
        }
      });
  }

// _____________________________________________________________________________________________________________________
// MANAGE --------------------------------------------------------------------------------------------------------------
  private async fetchWeeks(periods: string[]) {
    await StagesObserverUtil.fetchWeeks(this.Model, this.dataService, periods)
      .then(() => this.loadingStatus.week = LOADED)
      .catch(error => {
        this.loadingStatus.week = ERROR;
        if (error.name === 'TimeoutError') {
          this.loadingStatus.week = TIME_OUT;
        }
        if (error instanceof TypeError) {
          this.loadingStatus.week = NET_ERROR;
        }
      });
  }
  //retire des semaines
  removeWeeksForPeriod(periods: string[]) {
    let oldMap = [...this.Model.weeks].map(obj => obj[0]);
    let updatedMap = [...this.Model.weeks].filter(([key, _]) => !periods.includes(key.periodeDate.periode));

    oldMap.forEach((week) => {
      let isRemoved = !updatedMap.map(obj => obj[0].name).includes(week.name);
      if (isRemoved) {
        this.removePlacesForWeek(week).then();
      }
    })

    this.Model.weeks = new Map<Week, boolean>(updatedMap);
    this.loadingStatus.week = LOADED;
  }

  async removePlacesForWeek(week: Week) {
    this.Model.weekPlaces.delete(week.name);

    const allPlaces = Array.from(this.Model.weekPlaces.values()).flatMap(place => place);
    const set = new Set(allPlaces);

    this.Model.places.forEach((value, place) => {
      if (!set.has(place)) {
        this.Model.places.delete(place);
      }
    });
    // this.filteredStages$.next('filterWeek')
    // StagesFiltersLocalStorage.saveToLocal({places: this.places});
    this.updatePlacesMultiselect();
    this.loadingStatus.place = LOADED;
  }

  /**
   * Met à jour le composant multiselect via un observable
   * @private
   */
  updatePlacesMultiselect() {
    this.zone.run(() => {
      this.multiselectUpdate$.next(this.Model.places)
    });
  }
  onRechercherClick() {
    console.log("onRechercherClick");
    if (!this.Model.isValidFilter()) {
      // Check which filter is not valid and set focus
      if (!this.Model.isPeriodFilterValid()) {
        console.log("isPeriodFilterValid");
        this.setFocusOnInput(this.periodsInput);
      } else if (!this.Model.isWeeksFilterValid()) {
        console.log("isWeeksFilterValid");
        this.setFocusOnInput(this.weeksInput);
      }
      // Add additional checks for other filters if needed
    } else {
      // Perform your search logic
      console.log("else");
      this.Stream.filteredStages$.next('confirm-button');
    }
  }

  private setFocusOnInput(inputElement: ElementRef) {
    // Add any styling or error handling logic here
    this.renderer.setStyle(inputElement.nativeElement, 'border-color', 'red');
    inputElement.nativeElement.focus();
  }


  // variables globaux utilisé par la vue
  protected readonly window = window;
  protected readonly Array = Array;
  protected readonly StageEvent = StagesEvent;
  protected readonly scrollToStagesClass: string = 'scroll-to-stages';
  protected readonly REQUEST_DELAY = REQUEST_DELAY;
  protected readonly console = console;
}
