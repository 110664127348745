import {getStatusMessage} from "../../common/methods/statusMessages";
import {FormLoadingStatus, LoadingStatus} from "../../../models/FormLoadingStatus";
import {StagesModel} from "./StagesModel";
import {StagesLoadingStatus} from "./StagesLoadingStatus";

const {LOADING, LOADED, TIME_OUT, NET_ERROR, ERROR} = LoadingStatus;

export class StagesStatusProvider {
  model: StagesModel;
  loadingStatus: StagesLoadingStatus;

  constructor({model, loadingStatus}: {model: StagesModel, loadingStatus: StagesLoadingStatus}) {
    this.model = model;
    this.loadingStatus = loadingStatus;
  }

  /**
   * Méthode utilisée par la vue, message s'affichant dans le block [PERIODES] pour savoir quel message afficher et
   * quels styles choisir en fonction des états [chargement, erreur, liste vide...]
   * Retourne un objet avec 4 valeurs [le message, les classes css à ajouter au message, si on peut afficher la liste, est en trai de charger]
   */
  get periods() {
    // BOOLEAN STATUS
    let isLoading = this.loadingStatus.period ===LOADING;
    let isTimeOut = this.loadingStatus.period===TIME_OUT;
    let isNetError = this.loadingStatus.period===NET_ERROR;
    let isRequestError = this.loadingStatus.period===ERROR;
    let isEmptyList = this.loadingStatus.period===LOADED && this.model.periods.size == 0;

    // RETURN VARIABLES
    let message = '';
    let classes: string[] = []; // c'est un moyen d'ajouter des styles css supplémentaires

    // CONDITIONS
    message = getStatusMessage(isLoading, message, isTimeOut, classes, isNetError, isRequestError, isEmptyList);

    return new FormLoadingStatus(message, classes, message === '', isLoading);
  }

  /**
   * Méthode utilisée par la vue, message s'affichant dans le block [SEMAINES] pour savoir quel message afficher et
   * quels styles choisir en fonction des états [chargement, erreur, liste vide...]
   * Retourne un objet avec 4 valeurs [le message, les classes css à ajouter au message, si on peut afficher la liste, est en trai de charger]
   */
  get weeks() {
    // BOOLEAN STATUS
    let noPeriodeSelection = !Array.from(this.model.periods.values()).some(value => value);
    let isLoading = this.loadingStatus.week ===LOADING;
    let isTimeOut = this.loadingStatus.week===TIME_OUT;
    let isNetError = this.loadingStatus.week===NET_ERROR;
    let isRequestError = this.loadingStatus.week===ERROR;
    let isEmptyList = this.loadingStatus.week===LOADED && this.model.weeks.size == 0;
    // RETURN VARIABLES
    let message = '';
    let classes: string[] = []; // c'est un moyen d'ajouter des styles css supplémentaires

    // CONDITIONS
    if (noPeriodeSelection && !isLoading) {
      message = 'Aucune période séléctionnée';
    } else {
      message = getStatusMessage(isLoading, message, isTimeOut, classes, isNetError, isRequestError, isEmptyList);
    }

    return new FormLoadingStatus(message, classes, message === '', isLoading);
  }

  /**
   * Méthode utilisée par la vue, message s'affichant dans le block [LIEUX] pour savoir quel message afficher et
   * quels styles choisir en fonction des états [chargement, erreur, liste vide...]
   * Retourne un objet avec 4 valeurs [le message, les classes css à ajouter au message, si on peut afficher la liste, est en trai de charger]
   */
  get places() {
    // BOOLEAN STATUS
    let noSelectedWeeks = [...this.model.weeks.values()].filter(v => v).length === 0;
    let isLoading = this.loadingStatus.place === LOADING;
    let isTimeOut = this.loadingStatus.place === TIME_OUT;
    let isNetError = this.loadingStatus.place === NET_ERROR;
    let isRequestError = this.loadingStatus.place === ERROR;
    let isEmptyList = this.loadingStatus.place === LOADED && this.model.places.size == 0;

    // RETURN VARIABLES
    let message = '';
    let classes: string[] = []; // c'est un moyen d'ajouter des styles css supplémentaires

    // CONDITIONS
    if (noSelectedWeeks && !isLoading) {
      message = 'Aucune semaine séléctionnée';
    } else {
      message = getStatusMessage(isLoading, message, isTimeOut, classes, isNetError, isRequestError, isEmptyList);
    }

    return new FormLoadingStatus(message, classes, message === '', isLoading);
  }

  /**
   * Méthode utilisée par la vue, message s'affichant dans le block [STAGES] pour savoir quel message afficher et
   * quell styles choisir en fonction des états [chargement, erreur, liste vide...]
   * Retourne un objet avec 4 valeurs [le message, les classes css à ajouter au message, si on peut afficher la liste, est en trai de charger]
   */
  get stages() {
    // BOOLEAN STATUS
    let noSelectedFilters = !this.model.isValidFilter();
    let isLoading = this.loadingStatus.stage ===LOADING;
    let isTimeOut = this.loadingStatus.stage===TIME_OUT;
    let isNetError = this.loadingStatus.stage===NET_ERROR;
    let isRequestError = this.loadingStatus.stage===ERROR;
    let hasNotSearched = !this.model.coursesCalledByButton && !this.model.isAutoApplyFilters;
    // console.log('clicked on search : '+!hasNotSearched);
    let isEmptyList = this.loadingStatus.stage===LOADED && this.model.filteredStages.length==0;
    // RETURN VARIABLES
    let message = '';
    let classes: string[] = []; // c'est un moyen d'ajouter des styles css supplémentaires

    // CONDITIONS
    if (noSelectedFilters && !isLoading) {
      message = 'Veuillez compléter les filtres';
    } else {
      message = getStatusMessage(isLoading, message, isTimeOut, classes, isNetError, isRequestError, isEmptyList, hasNotSearched);
    }

    return new FormLoadingStatus(message, classes, message === '', isLoading);
  }
}
