import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {debounceTime, filter, Observable, Subject} from "rxjs";
import {AgeRange} from "./age-range";

declare var $:any;

@Component({
  selector: 'app-age-range',
  templateUrl: './age-range.component.html',
  styleUrls: ['./age-range.component.css', '../../common/styles/common.css']
})
export class AgeRangeComponent implements OnInit, AfterViewInit{
  @Input() ageRange!: AgeRange;
  @Input() resetEvent$!: Subject<void>;
  @Input() delay: number = 100;
  @Output() ageUpdate = new EventEmitter<AgeRange>()

  ngOnInit() {
    // Check
    this.check();
    this.onEvent();
    this.initJsComponent()
  }

  ngAfterViewInit() {

  }

  onEvent() {
    this.resetEvent$.subscribe({
      next: () => {
        this.ageRange.resetRange()
        const ageSlider = $("#slider1");
        $(".age-min").text(this.ageRange.min);
        $(".age-max").text(this.ageRange.max);
        ageSlider.slider('option',{value: [this.ageRange.min, this.ageRange.max]}).slider("refresh");
      }
    })
  }

  check() {
    if (!this.ageRange) {
      throw new Error('le composant doit initialiser l\'age correctement');
    }
  }

  initJsComponent() {
    $(document).ready(() => {
      // STYLE CSS
      // TODO à vérifier la dupplication
      let obj = { id: "slider1range", min: this.ageRange.minLimit, max: this.ageRange.maxLimit, value: [this.ageRange.minLimit, this.ageRange.maxLimit], focus: true};
      let ageSlider = $("#slider1").slider(obj);
      $(".slider-handle .min-slider-handle .round").slider(obj);
      $("#slider1range .slider-selection").css('background', '#2ebbff');
      $("#slider1range .slider-handle").css('background', '#2685b2');
      $("#slider1range .slider-horizontal").removeClass("slider-horizontal");
      // $("#slider1range .slider-track-high, #slider1range .slider-track-low").css('background', '#d4d4d4');
      // console.log(ageSlider.slider('getAttribute', 'min'))

      // SLIDER INITIALISER
      ageSlider.slider('option',{value: [this.ageRange.min, this.ageRange.max]}).slider("refresh")

      // CONSTANT
      const ageMin = $(".age-min")
      const ageMax = $(".age-max")
      let isDragging = false;

      // observable de l'AGE
      new Observable((observer)=> {
        ageSlider.on('change', () => {
          let [min, max] = $("#slider1").slider('getValue');
          // this.age = {agemin: Math.max(Math.min(min, this.bound.agemax), this.bound.agemin), agemax: Math.max(Math.min(max, this.bound.agemax), this.bound.agemin)};
          this.ageRange.update(min, max);
          ageMin.text(this.ageRange.min);
          ageMax.text(this.ageRange.max);

        });
        ageSlider.on('slideStart', () => {
          isDragging = true
          observer.next();
        });
        ageSlider.on('slideStop', () => {
          isDragging = false
          observer.next();
        });
      }).pipe(
        filter(() => !isDragging),
        debounceTime(this.delay),
      ).subscribe(() => {
        this.ageUpdate.emit(this.ageRange)
      })
    });
  }
}
