import { Component } from '@angular/core';

import {DataService} from "../../../service/data.service";
import {FileSaverService} from "ngx-filesaver";

@Component({
  selector: 'app-attestation-telechargement',
  templateUrl: './attestation-telechargement.component.html',
  styleUrls: ['./attestation-telechargement.component.css']
})
export class AttestationTelechargementComponent {
    private fileSaverService: FileSaverService;

  constructor(private dataService:DataService) {
    this.fileSaverService = new FileSaverService();
  }

  downloadPdf() {
    this.dataService.downloadPdf().subscribe((response: any) => {
      const blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'document.pdf';
      a.click();
      window.URL.revokeObjectURL(url); // Clean up
    });
  }

}
